import {
    useGetPackages,
    useGetUnitPrice,
    useSetPackages,
    useSetUnitPrice
} from "../../../../core/services/react-query/setting";
import {
    Background,
    CFlex,
    CMargin,
    DText,
    Flex,
    LineBreak,
    Padding, RMargin
} from "../../../../styles/components/common/common-styles";
import Input from "../../../common/input/Input";
import {useEffect, useMemo, useState} from "react";
import Text from "../../../../core/utils/Text";
import Select from "../../../common/select/Select";
import styled from "styled-components";
import CheckBox from "../../../common/input/CheckBox";
import {packNames} from "../../../../core/constants/packages";
import ActionButton from "../../../common/buttons/ActionButton";
import ButtonLoader from "../../../common/loading/ButtonLoader";
import {useQueryContext} from "../../../../core/contexts/query";
import {stringToNumber} from "../../../../core/utils/common";


const initial = {
    c2c: { active: true, messageLimit: 0, channelLimit: 0 }, c2g: { active: false, groupLimit: 0, messageLimit: 0 },
    forwarder: { active: false, messageLimit: 0 }, sender: { active: false, messageLimit: 0 }, userLimit: 0
}

const CreatePackage = () => {

    const { setToast } = useQueryContext()
    const { mutate: createPackage, isLoading } = useSetPackages()

    const { data: packages } = useGetPackages()

    const [pack, setPack] = useState(initial)
    const [selectedPack, setSelectedPack] = useState('')

    const { data: unitPrices } = useGetUnitPrice()
    const { mutate: updateUnitPrice, isLoading: settingUnitPrice } = useSetUnitPrice()
    const [unit, setUnit] = useState({
        userLimit: 0, channel: 0, channelMessage: 0, group: 0, groupMessage: 0, forwarder: 0, sender: 0
    })

    useEffect(() => {
        if (unitPrices?.userLimit) setUnit(unitPrices)
    }, [unitPrices])

    const onUnitChange = (v, type) => setUnit(state => ({...state, [type]: v}))
    const formatUnitPrice = () => {
        let temp = {}
        Object.keys(unit).forEach(key => {
            temp[key] = parseInt(stringToNumber(unit[key])) || 0
        })
        return temp
    }
    const onSetUnitPrice = () => {
        const payload = formatUnitPrice()
        updateUnitPrice(payload)
    }

    useEffect(() => {
        if (!!packages && selectedPack) {
            const dbPack = packages.find(p => p.name === selectedPack)
            if (!!dbPack) setPack(dbPack)
        }
    }, [packages, selectedPack])

    const valid = useMemo(() => {
        let temp = false
        const pack1Valid = !!pack.c2c.messageLimit && !!pack.c2c.channelLimit
        const pack2Valid = pack1Valid && !!pack.c2g.messageLimit && !!pack.c2g.groupLimit
        const pack3Valid = pack2Valid && !!pack.forwarder.messageLimit
        const pack4Valid = pack3Valid && !!pack.sender.messageLimit
        if (selectedPack === 'pack-1') temp = pack1Valid
        if (selectedPack === 'pack-2') temp = pack2Valid
        if (selectedPack === 'pack-3') temp = pack3Valid
        if (selectedPack === 'pack-4') temp = pack4Valid
        return temp
    }, [pack, selectedPack])

    const onPackValuesChange = (v, type, key) => {
        if (key === 'userLimit') setPack(state => ({...state, [key]: v}))
        else setPack(state => ({...state, [type]: { ...state[type], [key]: v }}))
    }

    const onPackBoolChange = (type) => {
        setPack(state => ({...state, [type]: { active: !state[type].active }}))
    }

    const tN = (n) => {
        let res = parseInt(stringToNumber(n))
        if (isNaN(res)) return n
        return res
    }

    const formatPack = (pack) => {
        return {
            c2c: {...pack.c2c, channelLimit: tN(pack.c2c.channelLimit), messageLimit: tN(pack.c2c.messageLimit) },
            c2g: {...pack.c2g, groupLimit: tN(pack.c2g.groupLimit), messageLimit: tN(pack.c2g.messageLimit) },
            forwarder: {...pack.forwarder, messageLimit: tN(pack.forwarder.messageLimit) },
            sender: {...pack.sender, messageLimit: tN(pack.sender.messageLimit) },
            userLimit: tN(pack.userLimit)
        }
    }

    const onCreateWorkspace = () => {
        if (valid) {
            const payload = {
                name: selectedPack,
                ...formatPack(pack)
            }
            createPackage({payload, name: selectedPack})
        }else
            setToast({
                message: 'fill-inputs-error',
                show: true, isError: true
            })

    }

    return (
        <Flex fw fh>
            <CFlex width={'50%'} fh justify={'flex-start'}>
                <Padding padding={'0 60px'}>
                    <Background bg={'primaryBg'}>
                        <CMargin margin={'50px'} />
                        <DText primary fontSize={'sb'}>
                            <Text tid={'create-package'} />
                        </DText>
                        <CMargin margin={'10px'} />
                        <Padding padding={'0 4px'} style={{ height: 'unset' }}>
                            <Select
                                value={selectedPack}
                                onValueChange={(idx) => setSelectedPack(packNames[idx])}
                                options={packNames}
                                width={'292px'}
                                height={'42px'}
                                placeHolder={'select-pack'}
                            />
                        </Padding>
                        <CMargin margin={'20px'} />
                        {!!selectedPack &&
                            <PackageWrapper>
                                <CFlex fw justify={'flex-start'} align={'flex-start'}>
                                    <Flex fw>
                                        <DText primary fontSize={'b'}>
                                            <Text tid={selectedPack} />
                                        </DText>
                                    </Flex>
                                    <LineBreak />

                                    <Flex fw wrap justify={'flex-start'}>
                                        <Background bg={'mainBg'}>
                                            <Input
                                                value={pack.userLimit}
                                                onInputChange={(v) => onPackValuesChange(v, null, 'userLimit')}
                                                size={'small'}
                                                width={'240px'}
                                                height={'42px'}
                                                label={'userLimit'}
                                                number
                                            />
                                        </Background>
                                    </Flex>

                                    <LineBreak />

                                    {/* c2c */}
                                    <CMargin margin={'4px'} />
                                    <CFlex fw justify={'flex-start'} align={'flex-start'} style={{ opacity: !pack.c2c.active ? 0.3 : 1 }}>
                                        <Flex>
                                            <CheckBox
                                                active={pack.c2c.active}
                                                onClick={() => onPackBoolChange('c2c')}
                                            />
                                            <DText primary margin={'0 8px'}>
                                                <Text tid={'workspace-c2c'} />
                                            </DText>
                                        </Flex>
                                        <CMargin margin={'8px'} />
                                        <Flex fw wrap justify={'flex-start'}>
                                            <Background bg={'mainBg'}>
                                                <Input
                                                    value={pack.c2c.channelLimit || 0}
                                                    onInputChange={(v) => onPackValuesChange(v, 'c2c', 'channelLimit')}
                                                    size={'small'}
                                                    width={'240px'}
                                                    height={'42px'}
                                                    label={'channelLimit'}
                                                    number
                                                />
                                                <RMargin margin={'10px'} />
                                                <Input
                                                    value={pack.c2c.messageLimit || 0}
                                                    onInputChange={(v) => onPackValuesChange(v, 'c2c', 'messageLimit')}
                                                    size={'small'}
                                                    width={'240px'}
                                                    height={'42px'}
                                                    label={'messageLimit'}
                                                    number
                                                />
                                            </Background>
                                        </Flex>
                                    </CFlex>


                                    <LineBreak />

                                    {/* c2g */}
                                    <CFlex fw justify={'flex-start'} align={'flex-start'} style={{ opacity: !pack.c2g.active ? 0.3 : 1 }}>
                                        <CMargin margin={'4px'} />
                                        <Flex>
                                            <CheckBox
                                                active={pack.c2g.active}
                                                onClick={() => onPackBoolChange('c2g')}
                                            />
                                            <DText primary margin={'0 8px'}>
                                                <Text tid={'workspace-c2g'} />
                                            </DText>
                                        </Flex>
                                        <CMargin margin={'8px'} />
                                        <Flex fw wrap justify={'flex-start'}>
                                            <Background bg={'mainBg'}>
                                                <Input
                                                    value={pack.c2g.groupLimit || 0}
                                                    onInputChange={(v) => onPackValuesChange(v, 'c2g', 'groupLimit')}
                                                    size={'small'}
                                                    width={'240px'}
                                                    height={'42px'}
                                                    label={'groupLimit'}
                                                    number
                                                />
                                                <RMargin margin={'10px'} />
                                                <Input
                                                    value={pack.c2g.messageLimit || 0}
                                                    onInputChange={(v) => onPackValuesChange(v, 'c2g', 'messageLimit')}
                                                    size={'small'}
                                                    width={'240px'}
                                                    height={'42px'}
                                                    label={'messageLimit'}
                                                    number
                                                />
                                            </Background>
                                        </Flex>
                                    </CFlex>

                                    <LineBreak />

                                    {/* forwarder */}
                                    <CFlex fw justify={'flex-start'} align={'flex-start'} style={{ opacity: !pack.forwarder.active ? 0.3 : 1 }}>
                                        <CMargin margin={'4px'} />
                                        <Flex>
                                            <CheckBox
                                                active={pack.forwarder.active}
                                                onClick={() => onPackBoolChange('forwarder')}
                                            />
                                            <DText primary margin={'0 8px'}>
                                                <Text tid={'workspace-forwarder'} />
                                            </DText>
                                        </Flex>
                                        <CMargin margin={'8px'} />
                                        <Flex fw wrap justify={'flex-start'}>
                                            <Background bg={'mainBg'}>
                                                <Input
                                                    value={pack.forwarder.messageLimit || 0}
                                                    onInputChange={(v) => onPackValuesChange(v, 'forwarder', 'messageLimit')}
                                                    size={'small'}
                                                    width={'240px'}
                                                    height={'42px'}
                                                    label={'messageLimit'}
                                                    number
                                                />
                                            </Background>
                                        </Flex>
                                    </CFlex>

                                    <LineBreak />

                                    {/* sender */}
                                    <CFlex fw justify={'flex-start'} align={'flex-start'} style={{ opacity: !pack.sender.active ? 0.3 : 1 }}>
                                        <CMargin margin={'4px'} />
                                        <Flex>
                                            <CheckBox
                                                active={pack.sender.active}
                                                onClick={() => onPackBoolChange('sender')}
                                            />
                                            <DText primary margin={'0 8px'}>
                                                <Text tid={'workspace-sender'} />
                                            </DText>
                                        </Flex>
                                        <CMargin margin={'8px'} />
                                        <Flex fw wrap justify={'flex-start'}>
                                            <Background bg={'mainBg'}>
                                                <Input
                                                    value={pack.sender.messageLimit || 0}
                                                    onInputChange={(v) => onPackValuesChange(v, 'sender', 'messageLimit')}
                                                    size={'small'}
                                                    width={'240px'}
                                                    height={'42px'}
                                                    label={'messageLimit'}
                                                    number
                                                />
                                            </Background>
                                        </Flex>
                                    </CFlex>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />
                                    <Flex fw>
                                        <ActionButton width={'300px'} height={'42px'} active={valid} onClick={onCreateWorkspace}>
                                            <ButtonLoader
                                                loading={isLoading}
                                                tid='submit'
                                            />
                                        </ActionButton>
                                    </Flex>
                                </CFlex>
                            </PackageWrapper>
                        }
                    </Background>
                </Padding>
            </CFlex>
            <CFlex width={'50%'} fh justify={'flex-start'}>
                <CMargin margin={'50px'} />
                <DText primary fontSize={'sb'}>
                    <Text tid={'unit-prices'} />
                </DText>
                <CMargin margin={'10px'} />
                <PackageWrapper>
                    <CFlex fw justify={'flex-start'} align={'flex-start'}>
                        {Object.keys(unit).map(key => {
                            return (
                                <>
                                    <Input
                                        value={unit[key]}
                                        onInputChange={(v) => onUnitChange(v, key)}
                                        size={'small'}
                                        width={'240px'}
                                        height={'42px'}
                                        label={`${key}price`}
                                        number
                                    />
                                    <LineBreak />
                                    <CMargin margin={'4px'} />
                                </>
                            )
                        })}
                        <Flex fw>
                            <ActionButton width={'300px'} height={'42px'} active onClick={onSetUnitPrice}>
                                <ButtonLoader
                                    loading={settingUnitPrice}
                                    tid='submit'
                                />
                            </ActionButton>
                        </Flex>
                    </CFlex>
                </PackageWrapper>
            </CFlex>
        </Flex>
    )
}

const PackageWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 8px ${props => props.theme.shadow};
  padding: 20px;
  width: 80%;
  background-color: ${props => props.theme.mainBg};
  margin-bottom: 20px;
`

export default CreatePackage
