import {CMargin, Decoration, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";


const Note = ({
    note
}) => {


    return (
        <>
            <Flex fw justify='flex-start'>
                <Decoration />
                <DText main fontSize='s'>
                    <Text tid={note} />
                </DText>
            </Flex>
            <CMargin margin='3px'/>
        </>
    )
}

export default Note
