import useTableLayout from "../../../core/hooks/layout/useTableLayout";
import {Column, Row, TableBody} from "../../../styles/layouts/table-styles";
import Text from "../../../core/utils/Text";
import Skeleton from "../../../core/packages/skeleton";
import Pagination from "../../common/utils/Pagination";
import ListSkeleton from "../../skeleton/ListSkeleton";
import NoData from "../../common/utils/NoData";


const TableLayout = ({
    headers,
    cs,
    children,
    data,
    hasWriteAccess,
    hasPagination = true,
    minWidth = 1000
}) => {

    const { data: _data, loading } = data
    const TOTAL = _data?.meta?.total ? _data?.meta.total : 0

    const {
        pagination,
        onPageChange,
        onLimitChange,
    } = useTableLayout()

    const centerHeaders = [
        'is-active', 'is-available', 'traded-date',
        'permissions', 'file-name', 'file-type',
        'file-status', 'isAdmin'
    ]

    return (
        <TableBody minWidth={minWidth}>
            <Row cs={cs} header>
                <Column>
                    <Text tid={'index'} />
                </Column>
                {headers?.map(item => (
                    <Column key={item} center={centerHeaders.includes(item)}>
                        <Text tid={item} />
                    </Column>
                ))}
                {hasWriteAccess &&
                    <Column center>
                        <Text tid={'operation'} />
                    </Column>
                }
            </Row>
            <div style={{ minHeight: '300px' }}>
                {loading ?
                    <Skeleton
                        Content={ListSkeleton}
                        count={5}
                        params={{ cs, headers }}
                    />
                    :
                    (
                        (_data && !_data?.data?.length) ?
                            <NoData />
                            :
                            children
                    )
                }
            </div>
            {hasPagination &&
                <Pagination
                    total={TOTAL}
                    page={pagination?.page}
                    limit={pagination?.limit}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                />
            }

        </TableBody>
    )
}


export default TableLayout
