import {CloseChevron, CMargin, DText, Flex, OpenChevron} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {CategoryAdd, CategoryCard, CategoryHeader} from "../../../styles/components/category/category-styles";
import CategoryItem from "./CategoryItem";
import {useRef, useState} from "react";
import {AnimatePresence} from "framer-motion";
import {ScaleLoader} from "react-spinners";
import Tooltip from "../../common/utils/Tooltip";


const CategoryList = ({
    type,
    list,
    loading,
    addCategory,
    updateCategory,
    deleteCategory,
}) => {

    const addRef = useRef()
    const [open, setOpen] = useState(true)

    const onUpdateClicked = (category_id, name) => {
        updateCategory({category_id, name})
    }

    const onDeleteClicked = (category_id) => {
        deleteCategory(category_id)
    }

    return (
        <>
            <CMargin margin='20px' />
            <CategoryHeader active={open}>
                <DText primary fontSize='sb'>
                    <Text tid={type} />
                </DText>
                <Flex>
                    <Tooltip
                        content={'create-category'}
                    >
                        <Flex ref={addRef}>
                            <CategoryAdd
                                size={24}
                                onClick={() => addCategory(type)}
                            />
                        </Flex>
                    </Tooltip>
                    {open ?
                        <CloseChevron size={28} onClick={() => setOpen(false)}/>
                        :
                        <OpenChevron size={28} onClick={() => setOpen(true)} />
                    }
                </Flex>
            </CategoryHeader>
            <AnimatePresence initial={false}>
                {open &&
                    <CategoryCard key={type} initial='out' animate='in' exit='out' variants={variants}>
                        {loading ?
                            <Flex fw height='300px'>
                                <ScaleLoader
                                    color='#3B6978'
                                    width='2px'
                                    height={26}
                                />
                            </Flex>
                            :

                            <Flex fw wrap justify={'flex-start'}>
                                {
                                    list?.map(item => (
                                        <CategoryItem
                                            key={item._id}
                                            category={item}
                                            onUpdateClicked={onUpdateClicked}
                                            onDeleteClicked={onDeleteClicked}
                                        />
                                    ))}
                            </Flex>
                        }
                    </CategoryCard>
                }
            </AnimatePresence>
        </>
    )
}

const variants = {
    in: {
        opacity: 1, transition: { duration : 0.3}
    },
    out: {
        opacity: 0, transition: { duration : 0.3}
    }
}

export default CategoryList
