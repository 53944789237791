import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../../fetch-api/get";
import { postApiWithToken } from "../../fetch-api/post";
import { useQueryContext } from "../../../contexts/query";
import { putApi } from "../../fetch-api/put";
import { deleteApi } from "../../fetch-api/delete";
import { makeQueryFilters } from "../../../utils/query-maker";


const CHANNEL_QUERY_KEYS = {
    GET_CHANNELS: 'get-channels',
    CREATE_CHANNEL: 'create-channel',
    GET_SIM_CHANNELS: 'get-sim-channels',
    GET_ADMIN_CHANNELS: 'get-admin-channels',
    UPDATE_CHANNEL: 'update-channel',
    DELETE_CHANNEL: 'delete-channel',
    GET_A_CHANNEL: 'get-a-channel',
    REFRESH_CHANNELS: 'refresh-channels',
    REFRESH_SIM_CHANNELS: 'refresh-sim-channels',
    ADMIN_CHANNEL_UPDATE: 'update-channel-update'
}


const useChannelsQuery = (filters, pagination, extra) => {

    let params = makeQueryFilters(filters, pagination)
    if (!!extra) params = { ...params, ...extra }

    return useQuery(
        CHANNEL_QUERY_KEYS.GET_CHANNELS, () => filterFetch(params, '/channel'),
        {
            select: res => res?.data
        }
    )
}


const useGetAChannel = (id) => {

    return useQuery(
        CHANNEL_QUERY_KEYS.GET_A_CHANNEL, () => normalFetch(`/channel/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}

const useSimChannelsQuery = (id) => {

    return useQuery(
        [CHANNEL_QUERY_KEYS.GET_SIM_CHANNELS, id], () => normalFetch(`/channel/simChannels/${id}`),
        {
            enabled: !!id,
            select: res => res.data?.data
        }
    )
}

const useAdminChannels = (id) => {

    return useQuery(
        [CHANNEL_QUERY_KEYS.GET_ADMIN_CHANNELS, id], () => normalFetch(`/channel/admin/${id}`),
        {
            enabled: !!id,
            select: res => res.data?.data
        }
    )
}


const useCreateChannelMutation = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        CHANNEL_QUERY_KEYS.CREATE_CHANNEL, (data) => postApiWithToken(data, '/channel'),
        {
            onSuccess: (res) => {
                setToast({
                    show: true,
                    message: 'op-success'
                })
                queryClient.invalidateQueries(CHANNEL_QUERY_KEYS.GET_CHANNELS)
                onSuccess && onSuccess(res)
            }
        }
    )
}


const useUpdateChannelMutation = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        CHANNEL_QUERY_KEYS.UPDATE_CHANNEL, (data) => putApi(data, '/channel/update'),
        {
            onSuccess: (res) => {
                setToast({
                    show: true,
                    message: 'op-success'
                })
                queryClient.invalidateQueries(CHANNEL_QUERY_KEYS.GET_GROUPS)
                onSuccess && onSuccess()
            }
        }
    )
}


const useDeleteChannelMutation = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        CHANNEL_QUERY_KEYS.DELETE_CHANNEL, (id) => deleteApi(`/channel/${id}`),
        {
            onSuccess: (res) => {
                setToast({
                    show: true,
                    message: res.data?.message
                })
                queryClient.invalidateQueries(CHANNEL_QUERY_KEYS.GET_GROUPS)
                onSuccess && onSuccess(res)
            }
        }
    )
}

const useRefreshChannels = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        CHANNEL_QUERY_KEYS.REFRESH_CHANNELS, () => postApiWithToken({}, '/channel/refresh'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success',
                    show: true
                })
                queryClient.invalidateQueries(CHANNEL_QUERY_KEYS.GET_CHANNELS)
            }
        }
    )
}


const useRefreshSimChannels = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        CHANNEL_QUERY_KEYS.REFRESH_SIM_CHANNELS, () => postApiWithToken({}, '/channel/refresh-sim'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success',
                    show: true
                })
                queryClient.invalidateQueries(CHANNEL_QUERY_KEYS.GET_CHANNELS)
            }
        }
    )
}

const useUpdateAdminChannel = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        CHANNEL_QUERY_KEYS.ADMIN_CHANNEL_UPDATE, (id) => postApiWithToken({}, `/channel/admin-channel-status/${id}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success',
                    show: true
                })
                queryClient.invalidateQueries(CHANNEL_QUERY_KEYS.GET_CHANNELS)
            }
        }
    )
}


export {
    useChannelsQuery,
    useGetAChannel,
    useCreateChannelMutation,
    useSimChannelsQuery,
    useAdminChannels,
    useUpdateChannelMutation,
    useDeleteChannelMutation,
    useRefreshChannels,
    useRefreshSimChannels,
    useUpdateAdminChannel
}
