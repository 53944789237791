import TableLayout from "../../layouts/main/TableLayout";
import { Column, DateBadge, DetailsLtr, DetailsRtl, Row } from "../../../styles/layouts/table-styles";
import {
    CFlex,
    DeleteIcon,
    DText,
    IconWrapper,
    UpdateIcon
} from "../../../styles/components/common/common-styles";
import DetailMiniView from "../details/DetailMiniView";
import { formatDate } from "../../../core/utils/common";
import { useMainContext } from "../../../core/contexts/main";
import ModalLayout from "../../layouts/main/ModalLayout";
import DeleteModal from "../../modals/DeleteModal";
import { useState } from "react";
import { useChangeC2cStatus, useDeleteC2c } from "../../../core/services/react-query/c2c";
import { Link, useNavigate } from "react-router-dom";
import { BooleanIcon } from "../../common/utils/BoleanIcon";
import { ActionWrapper } from "../../../styles/components/sim/sim-styles";
import Text from "../../../core/utils/Text";
import { ClipLoader } from "react-spinners";
import { getMainTheme } from "../../../core/utils/theme";


const C2cComp = ({ data }) => {

    const navigate = useNavigate()
    const { data: c2cs } = data
    const { main: { lang } } = useMainContext()

    const [deleteModal, setDeleteModal] = useState({ open: false, item: null })

    const { mutate: deleteC2c } = useDeleteC2c()

    const onUpdateClicked = (c2c) => navigate(`/c2c/create-c2c?id=${c2c._id}`)

    const onDeleteClicked = (c2c) => setDeleteModal({ open: true, item: c2c })

    const onDeleteSubmitted = () => {
        deleteC2c(deleteModal.item?._id)
        setDeleteModal({ open: false, item: null })
    }

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {c2cs?.data?.map((c2c, idx) => (
                <Row
                    key={c2c._id}
                    index={idx}
                    cs={cs}
                >
                    <Column style={{ alignItems: 'center' }}>
                        <div>
                            {idx + 1}
                        </div>
                    </Column>
                    <Column>
                        <DText>
                            {c2c.name}
                        </DText>
                    </Column>
                    <Column>
                        <DetailMiniView
                            margin={'0'}
                            chat={{
                                title: c2c.sim_id?.username
                            }}
                            link={'sims'}
                            idx={idx}
                            _id={c2c.sim_id?._id}
                        />
                    </Column>
                    <Column>
                        <BooleanIcon bool={c2c.isDirect} />
                    </Column>
                    <Column>
                        <BooleanIcon bool={c2c.hasDelay} />
                    </Column>
                    <Column>
                        <BooleanIcon bool={c2c.hasEitaa} />
                    </Column>
                    <Column>
                        <BooleanIcon bool={!!c2c.destination} />
                    </Column>
                    <Column>
                        <DateBadge>
                            <CFlex>
                                <DText primary>
                                    {formatDate(c2c.updatedAt, 'date', lang)}
                                </DText>
                                <DText primary>
                                    {formatDate(c2c.updatedAt, 'time', lang)}
                                </DText>
                            </CFlex>
                        </DateBadge>
                    </Column>
                    <Column more>
                        <C2cAction c2c={c2c} />
                        <IconWrapper
                            onClick={() => onUpdateClicked(c2c)}
                        >
                            <UpdateIcon size={22} />
                        </IconWrapper>
                        <IconWrapper
                            onClick={() => onDeleteClicked(c2c)}
                        >
                            <DeleteIcon size={22} />
                        </IconWrapper>
                        <Link to={`/c2c/${c2c._id}`}>
                            <IconWrapper>
                                {lang === 'en' ?
                                    <DetailsLtr size={24} />
                                    :
                                    <DetailsRtl size={24} />
                                }
                            </IconWrapper>
                        </Link>
                    </Column>
                </Row>
            ))}
            <ModalLayout
                open={deleteModal.open}
                onClose={() => setDeleteModal({ open: false, item: null })}
                width='600px'
            >
                <DeleteModal
                    type={'c2c'}
                    onSubmit={onDeleteSubmitted}
                    onCancel={() => setDeleteModal({ open: false, item: null })}
                />
            </ModalLayout>
        </TableLayout>
    )
}

const C2cAction = (props) => {
    const { c2c } = props
    const active = c2c.active

    const { mutate: changeStatus, isLoading } = useChangeC2cStatus()

    const onActionClicked = () => {

        const payload = {
            id: c2c._id
        }
        changeStatus(payload)
    }

    return (
        <ActionWrapper active={active} {...props} onClick={onActionClicked}>
            {isLoading ?
                <ClipLoader size={20} color={getMainTheme().active} />
                :
                <Text tid={active ? 'stop' : 'start'} />
            }
        </ActionWrapper>
    )
}

const cs = '5% 15% 15% 10% 10% 10% 10% 15% 10%'
const headers = [
    'name', 'sim', 'is-direct', 'has-delay', 'has-eitaa', 'has-telegram', 'date'
]


export default C2cComp
