import styled from "styled-components";
import {motion} from "framer-motion";
import { ReactComponent as SimBackgroundSvg } from "../../../assets/illustrations/create-sim.svg";
import {Flex} from "../common/common-styles";


const CSWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: space-around;
  margin-top: 80px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    height: 100%;
    margin-top: 40px;
    justify-content: unset;
  };
`


const CSBody = styled(motion.div)`
  width: 600px;
  //box-shadow: 0 0 4px #000022;
  border-radius: 12px;
  //border: 1px solid ${props => props.theme.color}10;
  background-color: ${props => props.theme.primaryBg};
  padding: 15px 20px;
  position: relative;
  z-index: 10;
  
  @media screen and (max-width: 768px) {
    width: 90%;
  };
`


const SimBackground = styled(SimBackgroundSvg)`
  opacity: 0.5;
  width: 600px;

  @media screen and (max-width: 768px) {
    width: 220px;
  };
`


const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
  border: 1px solid ${props => props.theme.color}20;
  border-radius: 4px;
  border: 2px solid ${({active, theme}) => active ? theme.mainRed : theme.mainGreen}90;
  cursor: pointer;
  min-width: max-content;
  margin: 0 10px;
  transition: all 0.3s;
  font-size: 0.8rem;
  padding: 4px 8px;
  color: ${props => props.theme.color};
  
  &:hover{
    background-color: ${({active, theme}) => active ? theme.mainRed : theme.mainGreen};
    transform: scale(1.1);
    color: #1e2026;
  }
  
  &:active{
    transform: scale(0.9);
  }
`



export {
    CSWrapper,
    SimBackground,
    CSBody,
    ActionWrapper,
}
