import DetailBox from "../../common/utils/DetailBox";
import { DText, Flex } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import DetailContent from "../details/DetailContent";
import MessageContent from "../normalizer/details/MessageContent";
import { statusMap } from "../normalizer/NormalizerList";
import GroupsSentList from "../../common/utils/GroupsSentList";


const SenderDetailShow = ({
    sim,
    sender
}) => {

    const date = sender?.messages?.length ? sender?.messages[0].static[0] : ''

    return (
        <>
            <DetailBox>
                <Flex fw justify={'space-between'}>
                    <Flex>
                        <DText main>
                            <Text tid={'sender-name'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {sender?.name}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'sim'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {sim?.username}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'status'} /> :
                        </DText>
                        <DText color={statusMap[sender.status]?.color}>
                            {sender.status}
                        </DText>
                    </Flex>
                </Flex>
            </DetailBox>
            <Flex fw justify={'space-between'} align={'stretch'}>
                <DetailBox
                    title={'groups'}
                >
                    <DetailContent
                        data={sender?.groups}
                        photo={'address'}
                        title={'title'}
                        type={'groups'}
                    />
                </DetailBox>
            </Flex>
            <DetailBox
                title={'messages'}
            >
                <MessageContent
                    messages={sender?.messages}
                />
            </DetailBox>

            <DetailBox>
                <GroupsSentList
                    groups={sender?.groups}
                    date={date}
                    sent_list={sender?.sent_list || []}
                    sender={sender}
                />
            </DetailBox>
        </>
    )
}


export default SenderDetailShow
