import TableLayout from "../../../layouts/main/TableLayout"
import ExtractMembersList from "./ExtractMembersList"



const ExtractMembersComp = ({ data }) => {

    const { data: operations } = data

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={CS}
        >
            <ExtractMembersList
                operations={operations}
                cs={CS}
            />
        </TableLayout>
    )
}

const CS = '5% 10% 15% 50% 10% 10%'
const headers = [
    'name', 'sim', 'groups', 'status', 'date'
]

export default ExtractMembersComp