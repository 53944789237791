import axios from "axios"
import { BASE_URL } from "../../constants/constants"


/**
 * Post api
 * @param {Object} data
 * @param {string} path
 * @returns {Promise}
 */
const postApi = (data, path) => {
    return axios.post(
        BASE_URL + path, {
        "x-platform": "web",
        ...data
    })
}

/**
 * Post api with token
 * @param {Object} data
 * @param {string} path
 * @returns {Promise}
 */
const postApiWithToken = (data, path) => {

    return axios.post(
        BASE_URL + path, data
    )
}


export {
    postApi,
    postApiWithToken
}

