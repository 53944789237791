import {useEffect, useState} from "react";
import {initialAcl, superAdmin} from "../../constants/acl";
import {useGetARole} from "../react-query/setting";
import {useProfileQuery} from "../react-query/auth";
import {useMainContext} from "../../contexts/main";



const useAccessLevels = () => {

    const [loading, setLoading] = useState(true)
    const [perms, setPerms] = useState(initialAcl)
    const { profile: {token, setIsSuperAdmin} } = useMainContext()

    /**
     * user role
     */
    const [ role, setRole ] = useState(null)

    /**
     * getting current user role details - (permissions)
     */
    const { data: permissions, refetch: getRole } = useGetARole(role)

    const permit = ()=>{
        if (permissions) {
            setLoading(false)
            setPerms(permissions.permissions)
        }
    }

    useEffect(permit, [permissions])

    /**
     * profile
     */
    const onProfileSuccess = (res) => {
        setRole(res.role)
    }
    const { refetch: getProfile } = useProfileQuery(onProfileSuccess)
    useEffect(() => {
        if (!!token) getProfile()
    }, [token])

    useEffect(() => {
        if (role) {
            setIsSuperAdmin(role === 'superAdmin')
            if (role !== 'superAdmin') {
                getRole()
            }
            else {
                setLoading(false)
                setPerms(superAdmin)
            }
        }
    }, [role])

    const reset = () => {
        setRole(null)
    }


    return {
        permissions: perms,
        loading,
        reset,
        setRole
    }
}

export {
    useAccessLevels
}
