import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSetAxiosHeaders } from "../core/hooks/common/useSettings";
import { AnimatePresence } from "framer-motion";

/**
 * pages
 */
import RegisterSignin from "./auth/RegisterSignin";
import Sims from "./main/Sims";
import Groups from "./main/Groups";
import Channels from "./main/Channels";
import CreateSim from "../components/main/sim/CreateSim";
import SimDetails from "../components/main/sim/SimDetails";
import Dashboard from "./main/Dashboard";
import Categories from "./main/Categories";
import Operations from "./actions/Operations";
import CreateOperation from "../components/main/operation/CreateOperation";
import OperationDetails from "../components/main/operation/details/OperationDetails";
import MainLayout from "../components/layouts/main/MainLayout";
import ChatDetails from "../components/main/details/ChatDetails";
import Normalizers from "./actions/Normalizers";
import CreateNormalizer from "../components/main/normalizer/CreateNormalizer";
import NormalizerDetails from "../components/main/normalizer/details/NormalizerDetails";
import Posts from "./actions/Posts";
import CreatePost from "../components/main/post/CreatePost";
import PostDetails from "../components/main/post/details/PostDetails";
import Logs from "./setting/Logs";
import { SocketProvider } from "../core/contexts/socket";
import { SocketContentProvider } from "../core/contexts/socket-content";
import C2c from "./actions/C2c";
import C2cModerator from "../components/main/c2c/moderator/C2cModerator";
import CreateC2c from "../components/main/c2c/create/CreateC2c";
import C2cDetails from "../components/main/c2c/C2cDetails";
import Setting from "./setting/Setting";
import AclContextProvider from "../core/contexts/acl";
import Profile from "./auth/Profile";
import Sender from "./actions/Sender";
import C2cDashboard from "./main/C2cDashboard";
import SenderDetails from "../components/main/sender/SenderDetails";
import Manager from "./setting/Manager";
import CreateWorkspace from "../components/main/setting/workspaces/CreateWorkspace";
import CreatePackage from "../components/main/setting/workspaces/CreatePackage";
import Jobs from "./setting/Jobs";
import { routes } from "../core/constants/routes";
import Home from "./landing/Home";
import { useMainContext } from "../core/contexts/main";
import Landing from "./landing/Landing";
import Register from "./landing/Register";
import Messages from "./setting/Messages";
import Members from './main/Members';


const Index = () => {

    const location = useLocation()
    useSetAxiosHeaders()
    const { profile: { token, lotp } } = useMainContext()

    return (
        <AclContextProvider>
            <AnimatePresence exitBeforeEnter>
                <MainLayout>
                    <SocketProvider>
                        <SocketContentProvider>
                            <Routes location={location} key={location.pathname}>
                                {!!token ?
                                    privateRoutes.map(({ path, Component }, key) => (
                                        <Route
                                            exact
                                            path={path}
                                            key={key}
                                            element={<Component />}
                                        />
                                    ))
                                    : null}
                                {!!lotp &&
                                    lotpRoutes.map(({ path, Component }) => (
                                        <Route path={path} element={<Component />} />
                                    ))
                                }
                                <Route path={routes.home} element={<Home />} />
                                <Route path='*' element={<Navigate to={routes.home} replace />} />
                            </Routes>
                        </SocketContentProvider>
                    </SocketProvider>
                </MainLayout>
            </AnimatePresence>
        </AclContextProvider>
    )
}


const privateRoutes = [
    { path: routes.profile, Component: Profile },

    { path: routes.dashboard, Component: Dashboard },
    { path: routes.c2cDashboard, Component: C2cDashboard },

    { path: routes.categories, Component: Categories },

    { path: routes.sims, Component: Sims },
    { path: routes.createSim, Component: CreateSim },
    { path: routes.simDetails, Component: SimDetails },

    { path: routes.groups, Component: Groups },
    { path: routes.groupDetails, Component: ChatDetails },

    { path: routes.channel, Component: Channels },
    { path: routes.channelDetails, Component: ChatDetails },

    { path: routes.operations, Component: Operations },
    { path: routes.operationDetails, Component: OperationDetails },
    { path: routes.createOperation, Component: CreateOperation },

    { path: routes.normalizer, Component: Normalizers },
    { path: routes.normalizerDetails, Component: NormalizerDetails },
    { path: routes.createNormalizer, Component: CreateNormalizer },

    { path: routes.sender, Component: Sender },
    { path: routes.createSender, Component: CreateNormalizer },
    { path: routes.senderDetails, Component: SenderDetails },

    { path: routes.post, Component: Posts },
    { path: routes.createPost, Component: CreatePost },
    { path: routes.postDetails, Component: PostDetails },

    { path: routes.c2c, Component: C2c },
    { path: routes.createC2c, Component: CreateC2c },
    { path: routes.moderator, Component: C2cModerator },
    { path: routes.c2cDetails, Component: C2cDetails },

    { path: routes.logs, Component: Logs },
    { path: routes.jobs, Component: Jobs },
    { path: routes.messages, Component: Messages },

    { path: routes.manager, Component: Manager },

    { path: routes.setting, Component: Setting },
    { path: routes.createWorkspace, Component: CreateWorkspace },
    { path: routes.createPackage, Component: CreatePackage },

    { path: routes.members, Component: Members }
]

const lotpRoutes = [
    { path: routes.landing, Component: Landing },
    { path: routes.register, Component: Register }
]

export default Index;
