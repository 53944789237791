import {useMutation, useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {makeQueryFilters} from "../../../utils/query-maker";
import {useQueryContext} from "../../../contexts/query";
import {deleteApi} from "../../fetch-api/delete";

const LOG_KEYS = {
    FETCH_LOGS: 'fetch-logs',
    DELETE_LOGS: 'delete-logs',
    FETCH_JOBS: 'fetch-jobs',
    DELETE_JOB: 'delete-job',
    DELETE_ALL_JOBS: 'delete-all-jobs',
    FETCH_MESSAGES: 'fetch-messages'
}

const useFetchLogs = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        LOG_KEYS.FETCH_LOGS, () => filterFetch(params,'/logs'),
        {
            select: res => res?.data
        }
    )
}

const useFetchJobs = () => {

    return useQuery(
        LOG_KEYS.FETCH_JOBS, () => normalFetch('/logs/jobs'),
        {
            select: res => res?.data
        }
    )
}

const useDeleteOutDatedLogs = () => {

    const { queryClient } = useQueryContext()
    return useMutation(
        LOG_KEYS.DELETE_LOGS, () => deleteApi('/logs'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(LOG_KEYS.FETCH_LOGS)
            }
        }
    )
}


const useDeleteJob = () => {

    const { queryClient } = useQueryContext()
    return useMutation(
        LOG_KEYS.DELETE_JOB, (name) => deleteApi(`/logs/jobs/${name}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(LOG_KEYS.FETCH_JOBS)
            }
        }
    )
}

const useDeleteAllJobs = () => {

    const { queryClient } = useQueryContext()
    return useMutation(
        LOG_KEYS.DELETE_ALL_JOBS, () => deleteApi(`/logs/jobs/delete/all`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(LOG_KEYS.FETCH_JOBS)
            }
        }
    )
}

const useFetchMessages = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        LOG_KEYS.FETCH_MESSAGES, () => filterFetch(params,'/logs/messages'),
        {
            select: res => res?.data
        }
    )
}


export {
    useFetchLogs,
    useFetchJobs,
    useDeleteJob,
    useDeleteAllJobs,
    useDeleteOutDatedLogs,
    useFetchMessages
}
