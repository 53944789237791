// const BASE_URL = 'https://api.raynaa.ir/v1'
// const STATIC_URL = 'https://api.raynaa.ir/'
const BASE_URL = process.env.REACT_APP_BASE_URL
const STATIC_URL = process.env.REACT_APP_STATIC_URL


const TABLET_SIZE = 1050
const MOBILE_SIZE = 768


const INPUT_TYPES = {
    EMAIL: "email",
    PASSWORD: "password",
    PASSWORD_CONFIRMATION: "passwordConfirmation",
    OTP: 'otp'
}

const CREATE_SIM_TYPES = {
    USERNAME: 'username',
    PHONE: 'phone',
    API_ID: 'api_id',
    API_HASH: 'api_hash',
    PASSWORD: 'password'
}

const CREATE_OPERATION_TYPE = {
    NAME: 'name',
    SIM_ID: 'sim_id',
    GROUPS: 'groups',
    CHANNELS: 'channels'
}

const CREATE_NORMALIZER_TYPE = {
    NAME: 'name',
    SIM_ID: 'sim_id',
    GROUPS: 'groups',
    MESSAGES: 'messages',
    DELAY: 'delay'
}

const CREATE_POST_TYPE = {
    SIM_ID: 'sim_id',
    GROUPS: 'groups',
    MESSAGE_ID: 'message_id',
    DATES: 'dates',
    VIEW_LIMIT: 'view_limit',
    SOURCE_ADDRESS: 'source_address',
    DELAY: 'delay'
}


const CHAT_OPTIONS = [
    'public', 'private'
]

export {
    BASE_URL,
    STATIC_URL,
    TABLET_SIZE,
    MOBILE_SIZE,
    INPUT_TYPES,
    CREATE_SIM_TYPES,
    CHAT_OPTIONS,
    CREATE_OPERATION_TYPE,
    CREATE_NORMALIZER_TYPE,
    CREATE_POST_TYPE
}
