import {fadeVariants} from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import {useEffect, useRef, useState} from "react";
import {useFilterContext} from "../../../core/contexts/filter";
import {DropFilterBody, FilterBtn} from "../../../styles/layouts/filter";
import {DeleteIcon, Flex} from "../../../styles/components/common/common-styles";
import InputFilter from "./elements/InputFilter";
import {useSimsQuery} from "../../../core/services/react-query/sim";
import SelectFilter from "./elements/SelectFilter";
import {nameFilters, selectFilters} from "../../../core/constants/filter";
import DatePick from "./elements/DatePick";


const FilterBox = (props) => {
    const {
        type,
        filter,
        onClose
    } = props

    const ref = useRef()
    const { clearOneFilter } = useFilterContext()

    const [sims, setSims] = useState([])
    const {data: allSims} = useSimsQuery()
    useEffect(() => {
        if (allSims) setSims(allSims.data.map(item => item.username))
    }, [allSims])

    const onSubmitClicked = () => {
        ref.current.submitFilters()
        // onClose()
    }

    const onDeleteFilterClicked = () => {
        clearOneFilter(filter)
        onClose()
    }


    return (
        <DropFilterBody
            variants={fadeVariants}
            animate='in'
            initial='out'
            exit='out'
            type={type}
        >
            {nameFilters.includes(filter.name) &&
                <InputFilter
                    {...props}
                    ref={ref}
                />
            }

            {selectFilters.includes(filter.name) &&
                <SelectFilter
                    {...props}
                    options={filter.options}
                    ref={ref}
                />
            }

            {filter.name === 'date' &&
                <DatePick
                    {...props}
                    options={filter.options}
                    ref={ref}
                />
            }

            {filter.name === 'sim' &&
                <SelectFilter
                    {...props}
                    options={sims}
                    ref={ref}
                />
            }

            <Flex style={{ marginTop: '16px' }}>
                <FilterBtn
                    onClick={onClose}
                >
                    <Text tid={'cancel'} />
                </FilterBtn>
                <FilterBtn
                    onClick={onSubmitClicked}
                    active
                >
                    <Text tid={'submit'} />
                </FilterBtn>
                {type === 'update' &&
                    <Flex
                        style={{ margin: '0 5px' }}
                        onClick={onDeleteFilterClicked}
                    >
                        <DeleteIcon size={20} />
                    </Flex>
                }
            </Flex>
        </DropFilterBody>
    )
}

export default FilterBox
