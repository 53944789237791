import Text from "../../core/utils/Text";


const Footer = () => {

    return (
        <div className={'w-full py-12 bg-black text-white'}>
            <div className={'max-w-[1368px] mx-auto'}>
                <div className={'flex items-center gap-2'}>
                    <img
                        src={require('../../assets/images/logo.png')}
                        alt=' '
                        width="52px"
                    />
                    <Text tid={'raynet'} className={'text-3xl'} />
                </div>
                <div className={'w-full h-[1px] bg-white opacity-50 mt-2'} />

            </div>
        </div>
    )
}

export default Footer
