import {ChatOption, MultiSelectOptionsWrapper} from "../../../styles/components/common/select-styles";
import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";


const RoleSelectItems = ({
     options,
     selected,
     onOptionClicked
 }) => {

    return (
        <MultiSelectOptionsWrapper>
            {options.map((role, idx) => (
                <ChatOption
                    active={selected.includes(role.name)}
                    onClick={() => onOptionClicked(role)}
                    type={'role'}
                >
                    <CFlex fh align={'flex-start'} style={{ backgroundColor: 'transparent' }}>
                        <Flex>
                            <DText main>
                                <Text tid={'name'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {role.name}
                            </DText>
                        </Flex>
                    </CFlex>
                </ChatOption>
            ))}
        </MultiSelectOptionsWrapper>
    )
}

export default RoleSelectItems
