import Text from "../../core/utils/Text";
import {useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import styled from "styled-components";
import {useLoginDemo} from "../../core/hooks/landing/useLoginDemo";


const Section1 = () => {

    const [active, setActive] = useState(1)

    useEffect(() => {
        let interval
        interval = setInterval(() => {
          setActive(state => (state + 1) % 3 + 1)
        }, 5000)
        return () => interval && clearInterval(interval)
    }, [])

    const {
        demoLogin
    } = useLoginDemo()

    return (
        <div className={'w-full bg-primeBg p-20 text-slate-500'}>
            <div className={'grid grid-cols-2 max-w-[1144px] mx-auto'}>
                <div className={'flex flex-col gap-4 max-w-[320px]'}>
                    <Text tid={'s1-header-1'} className={'text-4xl text-white'}/>
                    <Text tid={'s1-header-2'} className={'text-2xl text-slate-300'}/>
                    <Text tid={'s1-header-3'} className={'text-justify'}/>
                    <div className={'rounded-md px-5 py-2 bg-mainBg flex items-center justify-center w-max text-gray-900 cursor-pointer mt-5'} onClick={demoLogin}>
                        <Text tid={'start-now'} />
                    </div>
                </div>

                <MocupWrapper className={'relative w-[640px] h-[380px] overflow-hidden'} style={{ backgroundImage: `url(${require('../../assets/landing/mocup.png')})` }}>
                    <AnimatePresence exitBeforeEnter>
                        {active === 1 &&
                            <MocupImage idx={1} />
                        }
                        {active === 2 &&
                            <MocupImage idx={2} />
                        }
                        {active === 3 &&
                            <MocupImage idx={3} />
                        }
                    </AnimatePresence>
                </MocupWrapper>


            </div>
        </div>
    )
}

const MocupImage = ({idx}) => {
    return (
        <div className={''}>
            <motion.img
                key={`../../assets/landing/rayna-dashboard-${idx}.png`}
                src={require(`../../assets/landing/rayna-dashboard-${idx}.png`)}
                initial={{ opacity: 0  }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'ease-in-out', duration: 1 }}
                className={'absolute left-[97px] top-[24px] w-[448px] h-[242px]'}
            />
        </div>
    )
}

const MocupWrapper = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`


export default Section1
