import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { useGetNormalizers } from "../../core/services/react-query/normalizer";
import { CacheKeys, normalizerFilterOptions } from "../../core/constants/filter";
import { useMemo, useState } from "react";
import NormalizerComp from "../../components/main/normalizer/NormalizerComp";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreateButton from "../../components/common/buttons/CreateButton";
import { routes } from "../../core/constants/routes";



const Normalizers = () => {

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const navigateToCreateNormalizer = () => {
        navigate(routes.createNormalizer)
    }

    const [normalizers, setNormalizers] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setNormalizers(res)

    const extra = useMemo(() => {
        const category = searchParams.get('category')
        let ex = {}
        if (category) ex.categories = category
        return ex
    }, [searchParams])


    return (
        <>
            <CardLayout>
                <FilterLayout
                    query={useGetNormalizers}
                    cache={CacheKeys.NORMALIZER}
                    options={normalizerFilterOptions}
                    onQuerySuccess={onQuerySuccess}
                    extra={extra}
                >
                    <NormalizerComp data={normalizers} />
                </FilterLayout>
            </CardLayout>
            <CreateButton
                onClick={navigateToCreateNormalizer}
                tooltip='create-normalizer'
            />
        </>
    )
}


export default Normalizers
