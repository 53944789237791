import Text from "../../../../core/utils/Text";
import {forwardRef, useState, useImperativeHandle} from "react";
import {useFilterContext} from "../../../../core/contexts/filter";
import {useMainContext} from "../../../../core/contexts/main";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import transition from "react-element-popper/animations/transition"
import "react-multi-date-picker/styles/colors/teal.css"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import "react-multi-date-picker/styles/layouts/mobile.css"
import {Background, CFlex, CMargin, DText} from "../../../../styles/components/common/common-styles";
import {MOBILE_SIZE} from "../../../../core/constants/constants";
import Input from "../../../common/input/Input";


const DateFilter = forwardRef((props, ref) => {

    const { filter, onClose } = props
    const { name } = filter
    const {width} = useWindowSize()
    const { main: { lang, theme } } = useMainContext()
    const { filters: _filters, setFilters, cache } = useFilterContext()
    const filters = _filters[cache]

    const [ dateFrom, setDateFrom ] = useState(filters.dateFrom ? filters.dateFrom : null)
    const [ dateTo, setDateTo ] = useState(filters.dateTo ? filters.dateTo : null)


    useImperativeHandle(ref, () => ({
        submitFilters: () => {
            const newState = {...filters}
            if (dateFrom) newState.dateFrom = (new Date(dateFrom)).getTime()
            if (dateTo) newState.dateTo = (new Date(dateTo)).getTime()
            setFilters(newState)
            onClose()
        }
    }))


    return (
        <CFlex style={{ padding: '8px 0' }}>
            <Background bg={'primaryBg'}>
                <DText main>
                    <Text tid={`filter-${name}`} />
                </DText>
                <CMargin margin={'5px'} />
                <DatePicker
                    calendar={lang === 'fa' && persian}
                    locale={lang === 'fa' && persian_fa}
                    value={dateFrom}
                    onChange={setDateFrom}
                    animations={[transition()]}
                    render={<DateInput label={'dateFrom'}/>}
                    className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${width < MOBILE_SIZE && 'rmdp-mobile'}`}
                    calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                />
                <DatePicker
                    calendar={lang === 'fa' && persian}
                    locale={lang === 'fa' && persian_fa}
                    value={dateTo}
                    onChange={setDateTo}
                    animations={[transition()]}
                    render={<DateInput label={'dateTo'}/>}
                    className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${width < MOBILE_SIZE && 'rmdp-mobile'}`}
                    calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                />
            </Background>
        </CFlex>
    )
})

const DateInput = ({value, openCalendar, handleValueChange, label, isProfile = false}) => {

    return (
        <div onClick={openCalendar}>
            <Background bg={isProfile ? 'mainBg' : 'primaryBg'}>
                <Input
                    value={value}
                    onInputChange={handleValueChange}
                    size={isProfile ? 'big' : 'small'}
                    label={label}
                />
            </Background>
        </div>
    )
}



export default DateFilter
