import styled from "styled-components";
import {DText, Flex} from "../../../styles/components/common/common-styles";
import {Tag} from "../../../styles/components/normalizer";
import Text from "../../../core/utils/Text";


const DateInput = ({value, openCalendar, handleValueChange, label, dark= true}) => {
    return (
        <div onClick={openCalendar}>
            <Wrapper>
                { value?.length ?
                    value?.map(date => (
                        <Tag style={{ fontFamily: '0.8rem' }} dark={dark}>
                            {date}
                        </Tag>
                )) :
                    <DText main>
                        <Text tid={'select-dates'} />
                    </DText>
                }
            </Wrapper>
        </div>
    )
}


const Wrapper = styled(Flex)`
  width: 100%;
  border: 1px solid ${props => props.theme.color}40;
  padding: 10px;
  border-radius: 4px;
  min-height: 48px;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export default DateInput
