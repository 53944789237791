import {useEffect, useState} from "react";
import {deepCopy} from "../../utils/common";



const useMultiSelect = (options, values, param) => {

    const [ selected, setSelected ] = useState([])
    const [search, setSearch] = useState('')
    const [onBoard, setOnBoard] = useState([])

    useEffect(() => {
        setOnBoard(options)
    }, [options])

    useEffect(() => {
        setSelected(values.map(item => item[param]))
    }, [values, options])

    const onOptionClicked = (item) => {
        let newSelected = deepCopy(selected)
        const isSelected = selected.includes(item[param])
        if (isSelected) newSelected = selected.filter(x => x !== item[param])
        else newSelected.push(item[param])

        setSelected(newSelected)
    }

    const selectAll = () => {
        if (selected.length === options.length)
            setSelected([])
        else
            setSelected(options.map(item => item[param]))
    }

    const onSearch = (v) => {
        setSearch(v)
        const newBoard = options.filter(item => item[param]?.toLowerCase().includes(v))
        setOnBoard(newBoard)
    }

    return {
        selected,
        onOptionClicked,
        selectAll,
        search,
        onSearch,
        onBoard
    }
}


export default useMultiSelect
