import useMultiSelect from "../../../core/hooks/components/useMultiSelect";
import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import { SelectBtn} from "../../../styles/components/common/select-styles";
import CategorySelectItems from "./CategorySelectItems";
import SimSelectItems from "./SimSelectModal";
import ChatSelectItems from "./ChatSelectItems";
import Input from "../input/Input";
import UserSelectItems from "./UserSelectItems";
import {useTranslate} from "../../../core/utils/translator";


const MultiSelectModalLayout = ({
    header,
    options,
    values,
    onOperationClicked,
    param,
    type
}) => {

    const {
        selected,
        onOptionClicked,
        selectAll,
        search,
        onSearch,
        onBoard
    } = useMultiSelect(options, values, param)

    const ItemOptions = {
        category: CategorySelectItems,
        sim: SimSelectItems,
        chat: ChatSelectItems,
        user: UserSelectItems
    }

    const ITEMS = ItemOptions[type]

    return (
        <CFlex fw height='480px' justify={'flex-start'} transparent>
            <Flex fw justify={'space-between'}>
                <DText primary fontSize='b'>
                    <Text tid={header} />
                </DText>
                <Flex>
                    <DText main>
                        <Text tid={'selected'} /> :
                    </DText>
                    <DText primary>
                        {selected.length}
                    </DText>
                </Flex>
            </Flex>

            <Flex fw>
                <Input
                    placeholder={useTranslate('search')}
                    height={'32px'}
                    size={'small'}
                    value={search}
                    onInputChange={onSearch}
                />
            </Flex>

            <ITEMS
                options={onBoard}
                selected={selected}
                onOptionClicked={onOptionClicked}
            />

            <Flex fw justify={'flex-end'}>
                <SelectBtn
                    active={selected.length !== options?.length}
                    onClick={selectAll}
                >
                    <Flex fw fh>
                        <Text tid={selected.length === options.length ? 'deselect-all' : 'select-all'} />
                    </Flex>
                </SelectBtn>

                <SelectBtn
                    active={selected.length}
                    onClick={() => onOperationClicked('submit', selected)}
                >
                    <Flex fw fh>
                        <Text tid={'submit'} />
                    </Flex>
                </SelectBtn>

                <SelectBtn
                    active={false}
                    onClick={() => {onOperationClicked('cancel')}}
                >
                    <Flex fw fh>
                        <Text tid={'cancel'} />
                    </Flex>
                </SelectBtn>
            </Flex>
        </CFlex>
    )
}

export default MultiSelectModalLayout
