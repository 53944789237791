
export const nameFilters = [
    'name', 'group', 'channel', 'phone',
    'title', 'address', 'category', 'log-error',
    'log-cause', 'telegram', 'eitaa', 'messages-sim',
    'messages-type', 'eitaaDestination', 'telegramDestinationAddress',
    'telegramDestinationTitle'
]

export const selectFilters = [
    'delay', 'status', 'type', 'hasTelegram',
    'hasEitaa', 'isDirect'
]

export const CacheKeys = {
    OPERATION: '_operation',
    SIM: '_sim',
    CHANNEL: '_channel',
    GROUP: '_group',
    NORMALIZER: '_normalizer',
    SENDER: 'sender',
    POST: 'post',
    LOGS: 'logs',
    C2C: 'c2c',
    WORKSPACE: 'workspace',
    USER: 'user',
    ROLES: 'roles',
    MESSAGES: 'messages',
    MEMBERS: 'members',
    EXTRACT_MEMBER_OPERATIONS: 'extract-member-operations'
}

export const filtersIntitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = {}
    })
    return initial
}


export const paginationInitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = { page: 1, limit: 10 }
    })
    return initial
}

export const operationFilterOptions = [
    { name: 'name', search: 'name' },
    { name: 'status', search: 'active', options: ['active', 'disabled'] },
    { name: 'delay', search: 'delay.mode', options: ['period', 'keep-send', 'delete-send'] },
    { name: 'sim', search: 'sim_id' },
    { name: 'group', search: 'groups' },
    { name: 'channel', search: 'channels' },
]

export const c2cFilterOptions = [
    { name: 'name', search: 'name' },
    { name: 'eitaaDestination', search: 'eitaaDestination' },
    { name: 'telegramDestinationAddress', search: 'telegramDestinationAddress' },
    { name: 'telegramDestinationTitle', search: 'telegramDestinationTitle' },
    { name: 'hasTelegram', search: 'hasTelegram', options: ['hasTelegram', 'not-hasTelegram'] },
    { name: 'hasEitaa', search: 'hasEitaa', options: ['hasEitaa', 'not-hasEitaa'] },
    { name: 'isDirect', search: 'isDirect', options: ['isDirect', 'not-isDirect'] },
]


export const simFilterOptions = [
    { name: 'name', search: 'username' },
    { name: 'status', search: 'active', options: ['active', 'disabled'] },
    { name: 'phone', search: 'phone' }
]

export const chatFilterOptions = [
    { name: 'title', search: 'title' },
    { name: 'address', search: 'address' },
    { name: 'type', search: 'type', options: ['public', 'private'] },
    { name: 'category', search: 'categories' }
]

export const memberFilterOptions = [

]

export const extractMemberOperationFilterOptions = [

]

export const normalizerFilterOptions = [
    { name: 'name', search: 'name' },
    { name: 'status', search: 'active', options: ['active', 'disabled'] },
    { name: 'sim', search: 'sim_id' },
    { name: 'group', search: 'groups' },
    { name: 'category', search: 'categories' }
]


export const postFilterOptions = [
    { name: 'sim', search: 'sim_id' },
    { name: 'status', search: 'active', options: ['active', 'disabled'] },
    { name: 'message-id', search: 'message_id' }
]

export const logFilterOptions = [
    { name: 'log-cause', search: 'cause' },
    { name: 'log-error', search: 'error' }
]

export const messageFilterOptions = [
    { name: 'telegram', search: 'telegram' },
    { name: 'eitaa', search: 'eitaa' },
    { name: 'messages-sim', search: 'sim' },
    { name: 'messages-type', search: 'type' },
    { name: 'date', search: 'date' },
]

export const workspaceFilterOptions = [
    { name: 'name', search: 'name' },
    { date: 'date', search: 'date' }
]

export const userFilterOptions = [
    { name: 'username', search: 'username' },
    { name: 'email', search: 'email' },
    { date: 'name', search: 'date' }
]

export const roleFilterOptions = [
    { name: 'name', search: 'name' },
    { date: 'date', search: 'date' }
]
