import CardLayout from "../../components/layouts/main/CardLayout";
import {CacheKeys, messageFilterOptions} from "../../core/constants/filter";
import {useFetchMessages} from "../../core/services/react-query/log";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import {useMainContext} from "../../core/contexts/main";
import {useState} from "react";
import TableLayout from "../../components/layouts/main/TableLayout";
import {Column, DateBadge, Row} from "../../styles/layouts/table-styles";
import Tooltip from "../../components/common/utils/Tooltip";
import {BooleanIcon} from "../../components/common/utils/BoleanIcon";
import {formatDate} from "../../core/utils/common";


const Messages = () => {

    const { main: { lang } } = useMainContext()
    const [messages, setMessages] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setMessages(res)

    return (
        <CardLayout>
            <FilterLayout
                cache={CacheKeys.MESSAGES}
                query={useFetchMessages}
                onQuerySuccess={onQuerySuccess}
                options={messageFilterOptions}
            >
                <TableLayout
                    headers={headers}
                    cs={CS}
                    data={messages}
                >
                    {messages?.data?.data?.map((item, idx) => {
                        const m = item.message?.length ? item.message[0] : ''
                        return (
                            <Row cs={CS} index={idx} noHover>
                                <Column>{idx + 1}</Column>
                                <Column>{item.name}</Column>
                                <Column>
                                    <Tooltip content={m}>
                                        <span style={{ fontSize: '0.6rem' }}>{m?.substring(0, 40)}</span>
                                    </Tooltip>
                                </Column>
                                <Column>
                                    {item.sim}
                                </Column>
                                <Column>
                                    {item.type}
                                </Column>
                                <Column>
                                    {item.destination?.address}
                                </Column>
                                <Column>
                                    {item.eitaaDestination}
                                </Column>
                                <Column>
                                    <BooleanIcon bool={item.sendToTelegram} />
                                </Column>
                                <Column>
                                    <BooleanIcon bool={item.sendToEitaa} />
                                </Column>
                                <Column>
                                    <BooleanIcon bool={item.isDelete} />
                                </Column>
                                <Column>
                                    <DateBadge>
                                        {`${formatDate(item.createdAt, 'date', lang)}-${formatDate(item.createdAt, 'time', lang)}`}
                                    </DateBadge>
                                </Column>
                            </Row>
                        )
                    })}
                </TableLayout>
            </FilterLayout>
        </CardLayout>
    )
}


const CS = '4% 10% 16% 8% 8% 10% 10% 8% 8% 8% 10%'
const headers = [
    'name', 'message', 'sim', 'type', 'telegram', 'eitaa', 'sendToTelegram', 'sendToEitaa', 'isDelete', 'date'
]


export default Messages
