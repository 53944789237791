import styled, {css} from "styled-components";
import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io'
import {motion} from "framer-motion";
import { MdOutlineExitToApp } from 'react-icons/md'


const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: ${props => props.open ? '220px' : '80px'};
  width: ${props => props.open ? '220px' : '80px'};
  background-color: ${props => props.theme.mainBg};
  height: calc(100vh - 80px);
  transition: all 0.4s;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 12px;
  position: fixed;
  border-right: 1px solid ${props => props.theme.active}15;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
  z-index: 90;
  padding: 25px 15px;
`



const OpenCloseBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color}15;
  background-color: ${props => props.theme.mainBg};
  position: fixed;
  top: 100px;
  transition: all 0.3s;
  
  ${props => props.theme.english && css`
    left: ${props.open ? 205 : 70}px;
  `};

  ${props => !props.theme.english && css`
    right: ${props.open ? 205 : 70}px;
  `};
  
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0 0 4px ${props => props.theme.mainBg};
`

const OpenIcon = styled(IoIosArrowForward)`
  color: ${props => props.theme.primary};
`

const CloseIcon = styled(IoIosArrowBack)`
  color: ${props => props.theme.primary};
`


const NavItem = styled.div`
  margin: 8px 0;
  border-radius: 8px;
  transition: all 0.5s;
  cursor: pointer;
  background-color: ${props => props.active ? props.theme.active : 'transparent'};
  
  ${props => props.open && css`
    width: 100%;
    padding: 8px 15px;
  `};
  
  ${props => !props.open && css`
    padding: 8px;
  `};

  &:hover{
    background-color: ${props => props.theme.active};
    box-shadow: 0 0 2px ${props => props.theme.shadow};
  };
  
  ${props => props.exit && css`
    width: max-content;
    
    &:hover {
      background-color: ${props => props.theme.primaryBg};
      box-shadow: 0 0 2px rgb(0, 0, 0, 0.1);
    };
  `};
  

`


const NavText = styled(motion.div)`
  padding: 0 10px;
`

const ExitIcon = styled(MdOutlineExitToApp)`
  color: ${props => props.theme.mainRed};
  
  ${props => props.theme.english && css`
    transform: rotate(180deg);
  `};
`

export {
    SidebarWrapper,
    OpenCloseBtn,
    OpenIcon,
    CloseIcon,
    NavItem,
    NavText,
    ExitIcon
}
