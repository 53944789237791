import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";


const DASHBOARD_KEYS = {
    ONLINES: 'onlines',
    FCOUNTER: 'f-counter',
    CCOUNTER: 'c-counter',
    SUBSCRIBER: 'subscriber'
}

const useGetOnlines = () => {

    return useQuery(
        DASHBOARD_KEYS.ONLINES, () => normalFetch('/onlines'),
        {
            refetchInterval: 5 * 60 * 1000
        }
    )
}


const useGetFCounter = (params = {}) => {

    return useQuery(
        [DASHBOARD_KEYS.FCOUNTER, params], () => filterFetch( params,'/counter/operation'),
        {
            select: res => res?.data?.data,
            refetchInterval: 5 * 60 * 1000
        }
    )
}

const useGetCCounter = (params = {}) => {
    return useQuery(
        [DASHBOARD_KEYS.CCOUNTER, params], () => filterFetch( params,'/counter/c2c'),
        {
            select: res => res?.data?.data,
            refetchInterval: 5 * 60 * 1000
        }
    )
}

const useGetSubscriber = (params = {}) => {

    return useQuery(
        [DASHBOARD_KEYS.SUBSCRIBER, params], () => filterFetch( params,'/counter/subscriber'),
        {
            select: res => res?.data?.data,
            refetchInterval: 5 * 60 * 1000
        }
    )
}


export {
    useGetOnlines,
    useGetFCounter,
    useGetCCounter,
    useGetSubscriber
}
