import {DText} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {Link} from "react-router-dom";
import {useCrumbs} from "../../../core/hooks/layout/useCrumbs";
import {routes} from "../../../core/constants/routes";


const NavigatorItem = ({
    crumb,
    idx,
    onCrumbClicked
}) => {


    const { getCrumbStatus } = useCrumbs()
    const { valid, path } = getCrumbStatus(idx)

    if (path === routes.home){
        return (<></>)
    }

    const Child = () => {
        return (
            <DText
                onClick={() => onCrumbClicked(idx)}
                style={{cursor: valid && 'pointer', opacity: !valid && '0.5', whiteSpace: 'nowrap'}}
                primary
            >
                <Text tid={crumb.toLowerCase()}/>
            </DText>
        )
    }

    return (
        <>
            {valid ?
                <Link to={path}>
                    <Child />
                </Link>
                :
                <Child />
            }
        </>
    )
}

export default NavigatorItem
