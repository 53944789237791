import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../../fetch-api/get";
import { postApiWithToken } from "../../fetch-api/post";
import { useQueryContext } from "../../../contexts/query";
import { putApi } from "../../fetch-api/put";
import { makeQueryFilters } from "../../../utils/query-maker";
import { useMainContext } from "../../../contexts/main";
import { useNavigate } from "react-router-dom";
import { deleteApi } from "../../fetch-api/delete";
import { routes } from "../../../constants/routes";


const SIM_QUERY_KEYS = {
    GET_SIMS: 'get-sims',
    CREATE_SIM: 'create-sim',
    ACTIVATE_SIM: 'activate-sim',
    UPDATE_SIM: 'update-sim',
    GET_A_SIM: 'get-a-sim',
    SET_EXCHANGE: 'set-exchange',
    GET_ALL_SIMS: 'get-all-sims',
    DELETE_EXCHANGE: 'delete-exchange',
    RESEND_CODE: 'resend-code',
    REACTIVATE: 'reactivate',
    DELETE_SIM: 'delete-sim',
    UPDATE_SIM_NAME: 'update-sim-name'
}


const useSimsQuery = (filters, pagination) => {

    const { profile: { token } } = useMainContext()
    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        SIM_QUERY_KEYS.GET_SIMS, () => filterFetch(params, '/sim'),
        {
            enabled: !!token,
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 60 * 1000,
            select: res => res?.data
        }
    )
}

const useGetAllSims = () => {

    return useQuery(
        SIM_QUERY_KEYS.GET_ALL_SIMS, () => normalFetch('/sim/all'),
        {
            select: res => res.data?.data
        }
    )
}


const useGetASim = (id) => {

    const { profile: { token } } = useMainContext()

    return useQuery(
        SIM_QUERY_KEYS.GET_A_SIM, () => normalFetch(`/sim/${id}`),
        {
            select: res => res?.data?.data,
            enabled: !!token,
            retry: 0
        }
    )
}


const useCreateSim = (onSuccess) => {

    return useMutation(
        SIM_QUERY_KEYS.CREATE_SIM, (data) => postApiWithToken(data, '/sim/create'),
        {
            onSuccess
        }
    )
}

const useChangeSim = (onSuccess) => {

    return useMutation(
        SIM_QUERY_KEYS.CREATE_SIM, ({ data, id }) => postApiWithToken(data, `/sim/change/${id}`),
        {
            onSuccess
        }
    )
}

const useActivateSim = (onClose) => {

    const navigate = useNavigate()

    return useMutation(
        SIM_QUERY_KEYS.ACTIVATE_SIM, (data) => postApiWithToken(data, '/sim/activate'),
        {
            onSuccess: () => {
                onClose && onClose()
                navigate(routes.sims)
            }
        }
    )
}


const useUpdateSim = (id) => {

    const { queryClient } = useQueryContext()

    return useMutation(
        [SIM_QUERY_KEYS.UPDATE_SIM, id], (data) => putApi(data, '/sim/update'),
        {
            onSuccess: (_) => {
                queryClient.invalidateQueries(SIM_QUERY_KEYS.GET_SIMS)
            }
        }
    )
}

const useSetExchange = (onSuccess) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SIM_QUERY_KEYS.SET_EXCHANGE, (data) => putApi(data, '/sim/exchange'),
        {
            onSuccess: (_) => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SIM_QUERY_KEYS.GET_A_SIM)
                onSuccess && onSuccess()
            }
        }
    )
}

const useDeleteExchange = (onSuccess) => {
    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SIM_QUERY_KEYS.DELETE_EXCHANGE, (username) => deleteApi(`/sim/exchange/delete/${username}`),
        {
            onSuccess: (_) => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SIM_QUERY_KEYS.GET_A_SIM)
                onSuccess && onSuccess()
            }
        }
    )
}

const useResendSimCode = (onSuccess) => {

    return useMutation(
        SIM_QUERY_KEYS.RESEND_CODE, (id) => postApiWithToken({}, `/sim/resendCode/${id}`),
        {
            onSuccess
        }
    )
}

const useDeleteASim = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        SIM_QUERY_KEYS.DELETE_SIM, (id) => deleteApi(`/sim/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SIM_QUERY_KEYS.GET_SIMS])
                setToast({ show: true, message: 'op-success' })
            }
        }
    )
}

const useUpdateSimName = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        SIM_QUERY_KEYS.UPDATE_SIM_NAME, (payload) => postApiWithToken(payload, '/sim/update/name'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SIM_QUERY_KEYS.GET_SIMS])
                setToast({ show: true, message: 'op-success' })
                onSuccess()
            }
        }
    )
}

export {
    SIM_QUERY_KEYS,
    useGetASim,
    useSimsQuery,
    useCreateSim,
    useActivateSim,
    useUpdateSim,
    useSetExchange,
    useGetAllSims,
    useChangeSim,
    useDeleteExchange,
    useResendSimCode,
    useDeleteASim,
    useUpdateSimName
}
