import { useMainContext } from "../../../core/contexts/main";
import { Column, DateBadge, DetailsLtr, DetailsRtl, Row } from "../../../styles/layouts/table-styles";
import { CFlex, DeleteIcon, DText, Flex, IconWrapper, UpdateIcon } from "../../../styles/components/common/common-styles";
import { formatDate } from "../../../core/utils/common";
import Text from "../../../core/utils/Text";
import { ActionWrapper } from "../../../styles/components/sim/sim-styles";
import { useDeleteOperation, useRunOperation, useStopOperation } from "../../../core/services/react-query/operation";
import ModalLayout from "../../layouts/main/ModalLayout";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DetailMiniView from "../details/DetailMiniView";
import DeleteModal from "../../modals/DeleteModal";


const OperationList = ({
    operations,
    cs,
}) => {

    const { main: { lang } } = useMainContext()
    const navigate = useNavigate()

    const { mutate: deleteOperation } = useDeleteOperation()

    const [selectedOperation, setSelectedOperation] = useState(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const { mutate: runOperation } = useRunOperation()
    const { mutate: stopOperation } = useStopOperation()

    const OperationAction = (props) => {
        const { operation } = props
        const active = operation.active

        const onActionClicked = () => {

            const payload = {
                operation_id: operation._id
            }
            active ? stopOperation(payload) : runOperation(payload)
        }

        return (
            <ActionWrapper active={active} {...props} onClick={onActionClicked}>
                <Text tid={active ? 'stop' : 'start'} />
            </ActionWrapper>
        )
    }

    const onDeleteClicked = (operation) => {
        setSelectedOperation(operation)
        setDeleteModalOpen(true)
    }

    const onUpdateClicked = (operation) => {
        navigate(`/operations/${operation._id}?type=edit`)
    }

    const closeDeleteModal = () => setDeleteModalOpen(false)
    const onDeleteSubmitted = () => {
        setSelectedOperation(null)
        closeDeleteModal()
        deleteOperation(selectedOperation._id)
    }

    return (
        <>
            {operations?.data?.map((operation, idx) => (
                <Row
                    cs={cs}
                    key={operation.createdAt}
                    action={operation.active ? 'active' : 'disabled'}
                >
                    <Column style={{ alignItems: 'center' }}>
                        <div>
                            {idx + 1}
                        </div>
                    </Column>
                    <Column>
                        <DText>
                            {operation.name}
                        </DText>
                    </Column>
                    <Column>
                        <DetailMiniView
                            margin={'0'}
                            chat={{
                                title: operation.sim_id?.username
                            }}
                            link={'sims'}
                            idx={idx}
                            _id={operation.sim_id?._id}
                        />
                    </Column>
                    <Column>
                        <DateBadge>
                            <CFlex>
                                <DText primary>
                                    {formatDate(operation.updatedAt, 'date', lang)}
                                </DText>
                                <DText primary>
                                    {formatDate(operation.updatedAt, 'time', lang)}
                                </DText>
                            </CFlex>
                        </DateBadge>
                    </Column>
                    <Column>
                        <DText color={operation.active ? '#1ce087' : '#e9106c'}>
                            <Text tid={operation.active ? 'active' : 'disabled'} />
                        </DText>
                    </Column>

                    <Column more>
                        <OperationAction operation={operation} />
                        <IconWrapper
                            onClick={() => onUpdateClicked(operation)}
                        >
                            <UpdateIcon size={22} />
                        </IconWrapper>
                        <IconWrapper
                            onClick={() => onDeleteClicked(operation)}
                        >
                            <DeleteIcon size={22} />
                        </IconWrapper>

                        <Link to={`/operations/${operation._id}`}>
                            <IconWrapper>
                                {lang === 'en' ?
                                    <DetailsLtr size={24} />
                                    :
                                    <DetailsRtl size={24} />
                                }
                            </IconWrapper>
                        </Link>
                    </Column>
                </Row>
            ))}

            <ModalLayout
                open={deleteModalOpen}
                onClose={closeDeleteModal}
                width='600px'
            >
                <DeleteModal
                    type={'operation'}
                    onSubmit={onDeleteSubmitted}
                    onCancel={closeDeleteModal}
                    extra={[
                        { title: 'groups', count: selectedOperation?.groups.length },
                        { title: 'channels', count: selectedOperation?.channels.length }
                    ]}
                />
            </ModalLayout>
        </>
    )

}


export default OperationList
