import {useContext, createContext, useState} from "react";
import {filtersIntitialState, paginationInitialState} from "../constants/filter";


const Context = createContext()

export const useFilterContext = () => {
    return useContext(Context)
}


const FilterContextProvider = ({ children }) => {

    const [cache, setCache] = useState(null)

    const [filtersInAction, setFiltersInAction] = useState(false)
    const [filters, _setFilters] = useState(filtersIntitialState)
    const [pagination, _setPagination] = useState(paginationInitialState)

    const setFilters = (newState) => {
        _setFilters(state => ({...state, [cache]: newState}))
    }

    const setPagination = (newState) => {
        _setPagination(state => ({...state, [cache]: newState}))
    }

    const clearOneFilter = (filter) => {

        let temp = {}
        const keys = Object.keys(filters[cache])

        keys.forEach(key => {

            switch (filter.search) {
                case 'name':
                    if (key !== 'firstName' && key !== 'lastName') {
                        temp[key] = filters[cache][key]
                    }
                    break
                case 'date':
                    if (key !== 'dateFrom' && key !== 'dateTo') {
                        temp[key] = filters[cache][key]
                    }
                    break
                default:
                    if (key !== filter.search) {
                        temp[key] = filters[cache][key]
                    }
            }
        })

        setFilters(temp)
    }

    const clearAllFilters = () => {
        setFilters({})
    }


    return (
        <Context.Provider value={{
            filters,
            setFilters,
            clearOneFilter,
            clearAllFilters,
            pagination,
            setPagination,
            filtersInAction,
            setFiltersInAction,
            cache,
            setCache
        }}>
            {children}
        </Context.Provider>
    )
}

export default FilterContextProvider;
