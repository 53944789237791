import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { BooleanIcon } from "./BoleanIcon"
import { deepCopy, formatDate } from "../../../core/utils/common"
import { useMainContext } from "../../../core/contexts/main"
import { FaDownload } from "react-icons/fa";
import { DText } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import ModalLayout from "../../layouts/main/ModalLayout";
import { useGetCategoryType } from "../../../core/services/react-query/category";
import { CheckIcon } from "../../../styles/components/category/category-styles";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ButtonLoader from "../loading/ButtonLoader";
import { ClipLoader } from "react-spinners";


const groupStatusHeaders = [
    'group-name', 'date', 'is-sent'
]

const GroupsSentList = ({ groups, date, sent_list, sender, onClose }) => {

    const { data: categories } = useGetCategoryType('group')
    const { main: { lang } } = useMainContext()
    const [pdfModal, setPdfModal] = useState(false)

    const subscribers = useMemo(() => {
        let res = 0
        sender?.groups?.forEach(g => res += g.subscriber)
        return res
    }, [sender])

    const finishDate = useMemo(() => {
        let date = (new Date(sender?.messages[0]?.static[0])).getTime()
        const delay = sender?.delay * sender?.groups.length
        date += delay * 1000
        return (new Date(date)).toLocaleString('fa-IR')
    }, [sender])

    const onBoard = useMemo(() => {
        let res = []
        if (!!sender && !!categories) {
            sender?.groups.forEach(g => {
                const newG = deepCopy(g)
                newG.categories = []
                g.categories.forEach(cat => {
                    const fullCat = categories?.find(c => c._id === cat)
                    if (!!fullCat) newG.categories.push(fullCat.name)
                })
                res.push(newG)
            })
        }
        return res
    }, [sender, categories])

    const [makingPngs, setMakingPngs] = useState(false)
    const [step, setStep] = useState(0)

    // pdf part
    const pdfRef = useRef()
    const generatePdf = async () => {
        const element = pdfRef.current
        const link = document.createElement('a');
        const canvas = await html2canvas(element, { useCORS: true });
        const canvasData = canvas.toDataURL('image/png');

        if (typeof link.download === 'string') {
            link.href = canvasData;
            link.download = `${sender.name}-${step}.png`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(canvasData);
        }
    }

    useEffect(() => {
        let fn = async () => {
            let total = Math.ceil(sender?.groups?.length / 10)
            if (makingPngs) {
                if (step >= total) {
                    setStep(0)
                    setMakingPngs(false)
                    setPdfModal(false)
                    return
                }
                await generatePdf()
                setStep(state => state += 1)
            }
        }
        fn()
    }, [makingPngs, step])

    const onDownloadClick = () => {
        if (makingPngs) return
        setMakingPngs(true)
    }

    const onCancel = () => {
        if (makingPngs) return
        setPdfModal(false)
    }

    return (
        <>
            <div className={"flex items-center gap-5"}>
                <DText primary>
                    <Text tid={'group-sent-status'} />
                </DText>
                <div className={'cursor-pointer'} onClick={() => setPdfModal(true)}>
                    <DText main>
                        <FaDownload size={20} />
                    </DText>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-2 w-[50%] mt-3">
                {groupStatusHeaders.map(title => {
                    return (
                        <DText main key={title}>
                            <Text tid={title} />
                        </DText>
                    )
                })}

                {groups?.map((item, idx) => {
                    const isSent = sent_list?.includes(item._id)
                    return (
                        <Fragment key={item._id}>
                            <span className={'flex items-center gap-1'}>
                                <DText main>{idx + 1}-</DText>
                                <DText main >
                                    <Text tid={item.title} />
                                </DText>
                            </span>
                            <DText main>
                                {`${formatDate(date, 'date', lang)}-${formatDate(date, 'time', lang)}`}
                            </DText>
                            <BooleanIcon bool={isSent} />
                        </Fragment>
                    )
                })}
            </div>

            <ModalLayout
                isStatic
                open={pdfModal}
                width={'800px'}
            >

                <div
                    className={`bg-white w-full p-4 flex flex-col gap-4`}
                    ref={pdfRef}
                >
                    <div className={"flex justify-between items-center"}>
                        <span>به نام خدا</span>
                        <span className={'text-lg font-semibold'}>راینتــــ</span>
                        <div className={'p-2 bg-red-800 text-white text-[0.8rem]'}>
                            <span>خیلی محرمانه</span>
                        </div>
                    </div>
                    <div className={'border-[1px] border-black p-4 grid grid-cols-12'}>
                        <div className={'col-span-9 flex flex-wrap gap-2 text-[0.85rem] font-semibold'}>
                            <span className={'min-w-[240px]'}>
                                <span>گزارش عملیات: </span>
                                <span>{sender?.name}</span>
                            </span>
                            <span className={'min-w-[240px]'}>
                                <span>نوع پست: </span>
                                <span>{sender?.messages[0]?.message_id ? 'چند رسانه ای' : 'متن'}</span>
                            </span>
                            <span className={'min-w-[240px]'}>
                                <span>تعداد گروه ها: </span>
                                <span>{sender?.groups?.length}</span>
                            </span>
                            <span className={'min-w-[240px]'}>
                                <span>تعداد اعضاء: </span>
                                <span>{subscribers}</span>
                            </span>
                            <span className={'min-w-[240px]'}>
                                <span>نام سیمکارت: </span>
                                <span>{sender?.sim_id?.username}</span>
                            </span>
                            <span className={'min-w-[240px]'}>
                                <span>شماره سیمکارت: </span>
                                <span>{sender?.sim_id?.phone}+</span>
                            </span>
                            <span className={'min-w-[240px]'}>
                                <span>زمان شروع عملیات: </span>
                                <span className={'text-xs'}>{new Date(sender?.messages[0]?.static[0]).toLocaleString('fa-IR')}</span>
                            </span>
                            <span className={'min-w-[240px]'}>
                                <span>زمان پایان عملیات: </span>
                                <span className={'text-xs'}>{finishDate}</span>
                            </span>
                        </div>
                        <div className={'grid col-span-3'}>
                            {/* TODO: qr code */}
                        </div>
                    </div>

                    <table className={'border-collapse border border-gray-900'}>
                        <thead>
                            <tr>
                                {['ردیف', 'نام گروه', 'نوع گروه', 'تعداد اعضاء', 'دسته بندی ها', 'تاریخ و ساعت', 'وضعیت ارسال'].map(h => {
                                    return (
                                        <th className={'text-[0.8rem] border border-slate-600 p-1'}>{h}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {onBoard.slice(step * 10, step * 10 + 10).map((item, idx) => {
                                const date = (new Date(sender?.messages[0].static[0])).getTime()
                                const delay = sender?.delay
                                const dateWithDelay = ((idx + 1) * delay * 1000) + date
                                return (
                                    <tr className={'text-[0.8rem] font-semibold'}>
                                        <td className={"border border-slate-600 p-1"}>{idx + 1 + (step * 10)}</td>
                                        <td className={"border border-slate-600 p-1"}>{item.title}</td>
                                        <td className={"border border-slate-600 p-1"}>{groupTypes[item.type]}</td>
                                        <td className={"border border-slate-600 p-1"}>{item.subscriber}</td>
                                        <td className={"border border-slate-600 py-2 px-1 break-words text-right"}>
                                            {item.categories.join(' ,')}
                                        </td>
                                        <td className={"border border-slate-600 p-1"}>
                                            {(new Date(dateWithDelay)).toLocaleString('fa-IR')}
                                        </td>
                                        <td className={"border border-slate-600 p-1"}>
                                            <CheckIcon size={20} className={'text-green-700'} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <div className={'bg-blue-800 py-2 px-5 text-center text-xs text-white'}>
                        <span>
                            تمامی اطلاعات این سند خیلی محرمانه بوده و هرگونه نشر آن موجب پیگرد و اعاده خسارت خواهد بود. سیاست های حفظ اسرار قبلا ذیل قرارداد تشریح و توافق شده است.
                            .تمامی حقوق متعلق به راینا است
                        </span>
                    </div>
                </div>
                <div className={'w-min mx-auto flex justify-center items-center gap-4'}>
                    <div
                        className={'rounded-md bg-active py-2 px-5 mt-5 w-min mx-auto text-white cursor-pointer '}
                        onClick={onDownloadClick}
                    >
                        {makingPngs ?
                            <ClipLoader size={24} />
                            :
                            <span>دانلود</span>
                        }
                    </div>
                    <div
                        className={'rounded-md border-[1px] py-2 px-5 mt-5 w-min mx-auto text-white cursor-pointer '}
                        onClick={onCancel}
                    >
                        لغو
                    </div>
                </div>
            </ModalLayout>
        </>
    )
}

const groupTypes = {
    public: 'عمومی',
    private: 'خصوصی'
}

export default GroupsSentList