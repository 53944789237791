import {
    useDeleteGroupMutation, useRefreshGroups,
} from "../../../core/services/react-query/group";
import ModalLayout from "../../layouts/main/ModalLayout";
import CreateGroup from "./CreateGroup";
import GroupList from "./GroupList";
import TableLayout from "../../layouts/main/TableLayout";
import ChatRefresher from "../chat/ChatRefresher";
import GroupAccessRefresher from "./GroupAccessRefresher";
import GroupStats from "./GroupStats";
import { useMainContext } from "../../../core/contexts/main";


const GroupComp = ({
    modal,
    data
}) => {

    const { main: { lang } } = useMainContext()

    const { createModal, setCreateModal } = modal
    const { data: groups } = data

    const { mutate: deleteGroup } = useDeleteGroupMutation()

    const onCreateGroupSuccess = () => {
        setCreateModal(false)
    }

    return (
        <>
            <GroupStats stats={data?.data?.stats} />
            <TableLayout
                data={data}
                headers={headers}
                cs={CS}
            >
                <GroupList
                    groups={groups}
                    cs={CS}
                    deleteGroup={deleteGroup}
                />
                <div className={`absolute top-[40px] ${lang === 'en' ? 'right-[40px]' : 'left-[40px]'} flex items-center gap-2`}>
                    <GroupAccessRefresher
                        countUpdateType={'Groups'}
                    />
                    <ChatRefresher
                        query={useRefreshGroups}
                        type={'group'}
                        countUpdateType={'Groups'}
                    />
                </div>


            </TableLayout>

            <ModalLayout
                open={createModal}
                onClose={() => setCreateModal(false)}
                width='500px'
            >
                <CreateGroup
                    onCreateGroupSuccess={onCreateGroupSuccess}
                    onClose={() => setCreateModal(false)}
                />
            </ModalLayout>
        </>
    )
}

const CS = '10% 18% 18% 10% 10% 8% 15% 10%'
const headers = [
    'title', 'address', 'subscribers', 'online', 'type', 'modified'
]

export default GroupComp
