import {useProfileQuery} from "../../core/services/react-query/auth";
import {useEffect, useMemo, useState} from "react";
import {useGetAWorkspace} from "../../core/services/react-query/setting";
import ModalLayout from "../layouts/main/ModalLayout";
import {CFlex, CMargin, DText, Flex, LineBreak} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import {FiAlertTriangle} from "react-icons/fi";
import ActionButton from "../common/buttons/ActionButton";
import {useNavigate} from "react-router-dom";
import CheckBox from "../common/input/CheckBox";
import cookieService from "../../core/services/cookie";


const LimiterModal = () => {

    const { data: profile } = useProfileQuery()
    const { data: workspace } = useGetAWorkspace(profile?.workspace)

    const [error, setError] = useState({show: false, messages: []})
    const closeError = () => setError({show: false, messages: []})

    useEffect(() => {
        const noLimit = workspace?.noLimit
        if (!noLimit && workspace) {
            const c2cError = ((workspace.c2c?.messageCount / workspace.c2c?.messageLimit) * 100) || 0
            const c2gError = ((workspace.c2g?.messageCount / workspace.c2g?.messageLimit) * 100) || 0
            const forwarderError = ((workspace.forwarder?.messageCount / workspace.forwarder?.messageLimit) * 100) || 0
            const senderError = ((workspace.sender?.messageCount / workspace.sender?.messageLimit) * 100) || 0
            const messages = []
            if (c2cError >= 90) messages.push('c2c-error')
            if (c2gError >= 90) messages.push('c2g-error')
            if (forwarderError >= 90) messages.push('forwarder-error')
            if (senderError >= 90) messages.push('sender-error')
            if (messages.length) {
                setError({show: true, messages})
            }
        }
    }, [workspace])

    const navigate = useNavigate()
    const onIncreaseCredit = () => {
        navigate('#')
        closeError()
    }

    const [dontShow, setDontShow] = useState(cookieService.get('dont-show-limit'))
    const onDontShow = () => {
        setDontShow(state => !state)
        cookieService.set('dont-show-limit', !dontShow, { url: '/' })
    }

    return (
        <ModalLayout
            width={'620px'}
            open={error.show && !dontShow}
            onClose={closeError}
        >
            <CFlex fw align={'flex-start'}>
                <Flex fw justify='space-between'>
                    <DText primary fontSize='b'>
                        <Text tid={`credit-warning`} />
                    </DText>
                    <FiAlertTriangle color='#ffc800' size={32} />
                </Flex>
                <LineBreak />
                {error?.messages?.map((mes, idx) => (
                    <Flex align={'flex-start'} style={{ margin: '8px 0' }}>
                        <DText main fontSize={'s'} style={{ marginTop: '2px' }}>{idx+1}- </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            <Text tid={mes} />
                        </DText>
                    </Flex>
                ))}
                <CMargin margin={'10px'} />
                <Flex>
                    <CheckBox active={dontShow} onClick={onDontShow}/>
                    <DText main margin={'0 5px'}>
                        <Text tid={'dont-show-limit'} />
                    </DText>
                </Flex>
                <CMargin margin={'10px'} />
                <Flex fw>
                    <ActionButton width={'180px'} height={'38px'} active onClick={onIncreaseCredit}>
                        <Text tid={'increase-credit'} />
                    </ActionButton>
                </Flex>
            </CFlex>
        </ModalLayout>
    )
}

export default LimiterModal
