import EChart from "./EChart";
import Text from "../../core/utils/Text";
import {CFlex, DText, Flex} from "../../styles/components/common/common-styles";
import {GlobalPieChartHeader, Legend} from "../../styles/components/dashboard";
import {useMainContext} from "../../core/contexts/main";
import {useTranslate} from "../../core/utils/translator";




const GlobalStatsPieChart = ( { data, label } ) => {

    const { main: {lang} } = useMainContext()

    const option = {
        color: [
            '#819F82',
            '#9F819E'
        ],
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontWeight: 'bold'
            }
        },
        series: [
            {
                name: useTranslate(label),
                type: 'pie',
                radius: ['35%', '80%'],
                center: '50%',
                data: [
                    { value: data?.active, name: useTranslate('active', lang) },
                    { value: data?.total - data?.active, name: useTranslate('inactive', lang) },
                ],
                label: {
                    show: false
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    label: {
                        show: false,
                    }
                }
            }
        ]
    };

    const activePc = ((data?.active / data?.total) * 100).toFixed(1)
    const inactivePc = (((data?.total - data?.active) / data?.total) * 100).toFixed(1)

    return (
        <CFlex width='220px'>
            <GlobalPieChartHeader>
                <DText primary cFontSize={'14px'} style={{ fontWeight: 600 }}>
                    <Text tid={label.toLowerCase()} />
                </DText>
            </GlobalPieChartHeader>
            <CFlex fw align={'flex-start'} style={{ marginTop: '5px' }}>
                <Flex>
                    <Legend color={'#819F82'} />
                    <Flex>
                        <DText main cFontSize={'11px'} style={{ margin: '0 2px' }}>
                            <Text tid={'active'} />:
                        </DText>
                        <DText primary cFontSize={'11px'}>
                            {`${data?.active} (${!isNaN(activePc) ? activePc : 0} %)`}
                        </DText>
                    </Flex>
                </Flex>
                <Flex style={{ margin: '2px 0' }}>
                    <Legend color={'#9F819E'} />
                    <Flex>
                        <DText main cFontSize={'11px'} style={{ margin: '0 2px' }}>
                            <Text tid={'inactive'} />:
                        </DText>
                        <DText primary cFontSize={'11px'}>
                            {`${data?.total - data?.active} (${!isNaN(inactivePc) ? inactivePc : 0} %)`}
                        </DText>
                    </Flex>
                </Flex>
            </CFlex>
            <EChart style={{ width: '100%', height: '120px', direction: 'ltr' }} option={option} />
        </CFlex>
    )
}

export default GlobalStatsPieChart
