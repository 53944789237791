import {useGetOnlines} from "../../core/services/react-query/dashboard";
import EChart from "./EChart";
import {useEffect, useState} from "react";
import {CFlex, DText, Flex} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import {useMainContext} from "../../core/contexts/main";


const OnlineChart = () => {

    const { main: { theme } } = useMainContext()
    const { data: onlines } = useGetOnlines()

    const [dates, setDates] = useState([])
    const [candles, setCandles] = useState([])

    useEffect(() => {
        let _dates = []
        let _candles = []
        if (onlines) {
            onlines.data.forEach(item => {
                const d = new Date(item.date)
                const dd = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
                _dates.push(dd)

                _candles.push([
                    item.open,
                    item.close,
                    item.min,
                    item.max
                ])
            })
            setDates(_dates)
            setCandles(_candles)
        }
    }, [onlines])

    const option = {
        xAxis: {
            data: dates
        },
        yAxis: {
            scale: true,
            boundaryGap: [0, '20%'],
            splitLine: {
                lineStyle: {
                    color: theme === 'dark' ? '#c3c5b720' : '#00000020',
                    type: 'dashed'
                }
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100
            },
            {
                show: true,
                type: 'slider',
                top: '90%',
                start: 0,
                end: 100
            }
        ],
        series: [
            {
                name: 'Onlines',
                type: 'candlestick',
                data: candles,
                itemStyle: {
                    color: '#1ce087',
                    color0: '#f6465d',
                    borderColor: '#1ce087',
                    borderColor0: '#f6465d',
                }
            }
        ]
    };

    return (
        <CFlex fw fh justify={'space-between'}>
            <Flex fw>
                <DText primary cFontSize={'15px'} style={{ fontWeight: 600 }}>
                    <Text tid={'onlines'} />
                </DText>
            </Flex>
            <Flex fw height={'calc(100% - 80px)'}>
                <EChart option={option} style={{ width: '100%', height: '100%', direction: 'ltr' }} />
            </Flex>
        </CFlex>
    )
}


export default OnlineChart
