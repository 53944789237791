import {useMutation} from "react-query";
import {postApi} from "../../fetch-api/post";


export const useLoginWithDemo = () => {

    return useMutation(
        'login-via-demo', (payload) => postApi(payload, '/auth/login/demo')
    )
}
