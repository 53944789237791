import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import {useGetC2cList} from "../../core/services/react-query/c2c";
import {useState} from "react";
import {c2cFilterOptions, CacheKeys} from "../../core/constants/filter";
import CreateButton from "../../components/common/buttons/CreateButton";
import {useNavigate} from "react-router-dom";
import C2cComp from "../../components/main/c2c/C2cComp";
import {routes} from "../../core/constants/routes";


const C2c = () => {

    const navigate = useNavigate()
    const [c2cs, setC2cs] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setC2cs(res)

    const navigateToCreateC2c = () => navigate(routes.createC2c)

    return (
        <>
            <CardLayout>
                <FilterLayout
                    query={useGetC2cList}
                    onQuerySuccess={onQuerySuccess}
                    cache={CacheKeys.C2C}
                    options={c2cFilterOptions}
                >
                    <C2cComp data={c2cs} />
                </FilterLayout>
            </CardLayout>
            <CreateButton
                onClick={navigateToCreateC2c}
                tooltip='create-c2c'
            />
        </>

    )
}

export default C2c
