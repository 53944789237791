import Text from "../../core/utils/Text";
import {useEffect, useRef} from "react";


const Section5 = () => {

    const ref = useRef()
    useEffect(() => {
        ref.current?.play()
    }, [])

    return (
        <div className={'w-full bg-black p-20 text-slate-500'}>
            <div className={'max-w-[1144px] mx-auto gap-6 flex flex-col'}>
                <Text tid={'grow-head-1'} className={'text-4xl font-semibold mx-auto text-white'} />
                <Text tid={'grow-desc-1'} className={'text-xl mx-auto'} />
                <video ref={ref} loop>
                    <source src={require('../../assets/landing/grow-vid.mp4')} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default Section5
