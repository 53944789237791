import { useState} from "react";
import TSelect from "./TSelect";
import ModalLayout from "../../layouts/main/ModalLayout";
import MultiSelectModalLayout from "./MultiSelectModalLayout";



const MultiSelect = (props) => {

    const {
        values,
        onValuesChange,
        placeHolder,
        options,
        width,
        height,
        type,
        param,
        ...rest
    } = props

    const [open, setOpen] = useState(false)

    const onSelectClicked = () => {
        setOpen(state => !state)
    }

    /**
     * @param type - cancel | submit
     */
    const onOperationClicked = (type, selected) => {
        if (type === 'cancel') setOpen(false)
        if (type === 'submit') {
            const selectedOptions = options.filter(item => selected.includes(item[param]))
            setOpen(false)
            onValuesChange(selectedOptions)
        }
    }

    const onDeleteItem = (idx) => {
        const newValues = [...values]
        newValues.splice(idx, 1)
        onValuesChange(newValues)
    }

    return (
        <>
            <TSelect
                open={open}
                values={values}
                param={param}
                width={width}
                height={height}
                placeHolder={placeHolder}
                onClick={onSelectClicked}
                onDeleteItem={onDeleteItem}
                {...rest}
            />
            {open &&
                <ModalLayout
                    open={open}
                    onClose={() => setOpen(false)}
                    width='700px'
                    portal={false}
                >
                    <MultiSelectModalLayout
                        type={type}
                        header={placeHolder}
                        options={options}
                        values={values}
                        onOperationClicked={onOperationClicked}
                        param={param}
                    />
                </ModalLayout>
            }
        </>
    )
}





export default MultiSelect
