import {CMargin, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Input from "../../common/input/Input";
import {useState} from "react";
import ActionButton from "../../common/buttons/ActionButton";
import ButtonLoader from "../../common/loading/ButtonLoader";
import {useQueryContext} from "../../../core/contexts/query";


const CreateCategory = ({
    createCategory,
    type,
    loading
}) => {

    const { setToast } = useQueryContext()
    const [category, setCategory] = useState('')


    const onCreateCategoryClicked = () => {
        if (category) {
            const payload = {  name: category, type}
            createCategory(payload)
        }else {
            setToast({
                isError: true, message: 'fill-inputs-error',
                show: true
            })
        }
    }

    return (
        <>
            <Flex justify='space-between'>
                <DText fontSize='b' primary>
                    <Text tid='create-category' />
                </DText>
                <Flex justify='flex-start'>
                    <DText primary>
                        <Text tid='type' />:
                    </DText>
                    <DText primary style={{ margin: '0 5px'}}>
                        <Text tid={type} />
                    </DText>
                </Flex>
            </Flex>
            <CMargin margin='30px' />
            <Input
                value={category}
                onInputChange={(value) => setCategory(value)}
                label='category-name'
            />

            <CMargin margin='30px' />
            <ActionButton
                active={!!category}
                onClick={onCreateCategoryClicked}
            >
                <ButtonLoader
                    tid='submit'
                    loading={loading}
                />
            </ActionButton>
        </>
    )
}

export default CreateCategory
