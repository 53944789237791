import { useParams } from "react-router-dom";
import { useGetAPost } from "../../../../core/services/react-query/post";
import { DetailLayoutWrapper } from "../../../../styles/layouts/main-styles";
import DetailBox from "../../../common/utils/DetailBox";
import { DText, Flex } from "../../../../styles/components/common/common-styles";
import Text from "../../../../core/utils/Text";
import DetailContent from "../../details/DetailContent";
import { Tag } from "../../../../styles/components/normalizer";
import { formatDate } from "../../../../core/utils/common";
import { useMainContext } from "../../../../core/contexts/main";
import GroupsSentList from "../../../common/utils/GroupsSentList";


const PostDetails = () => {

    const { main: { lang } } = useMainContext()
    const params = useParams()
    const { data: post } = useGetAPost(params.id)

    return (
        <DetailLayoutWrapper>
            <DetailBox>
                <Flex fw justify={'space-between'} wrap>
                    <Flex>
                        <DText main>
                            <Text tid={'sim'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {post?.sim_id.username}
                        </DText>
                    </Flex>

                    <Flex>
                        <DText main>
                            <Text tid={'message_id'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {post?.message_id}
                        </DText>
                    </Flex>

                    <Flex justify={'space-between'}>
                        <DText main fontSize={'s'}>
                            <Text tid={'views'} /> :
                        </DText>
                        <DText primary margin={'0 5px'} fontSize={'s'}>
                            {post?.views}
                        </DText>
                    </Flex>

                    <Flex>
                        <DText main>
                            <Text tid={'status'} /> :
                        </DText>
                        <DText color={post?.active ? '#1ce087' : '#e9106c'} style={{ margin: '0 5px' }}>
                            <Text tid={post?.active ? 'active' : 'disabled'} />
                        </DText>
                    </Flex>
                </Flex>
            </DetailBox>

            <Flex fw justify={'space-between'} wrap align={'stretch'}>
                <DetailBox
                    width={'49%'}
                    title={'groups'}
                >
                    <DetailContent
                        data={post?.groups}
                        photo={'address'}
                        title={'title'}
                        type={'groups'}
                    />
                </DetailBox>
                <DetailBox
                    width={'49%'}
                    title={'dates'}
                >
                    <Flex fw justify={'flex-start'} wrap margin={'8px 0 0 0'}>
                        {post?.dates?.map(date => (
                            <Tag style={{ fontSize: '0.9rem' }}>
                                {`${formatDate(date, 'date', lang)}-${formatDate(date, 'time', lang)}`}
                            </Tag>
                        ))}
                    </Flex>
                </DetailBox>
            </Flex>

            <DetailBox>
                <GroupsSentList
                    groups={post?.groups}
                    sent_list={post?.sent_list || []}
                    date={post ? post.dates[0] : ''}
                />
            </DetailBox>
        </DetailLayoutWrapper>
    )
}


export default PostDetails
