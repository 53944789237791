import {useMutation, useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {makeQueryFilters} from "../../../utils/query-maker";
import {postApi} from "../../fetch-api/post";
import {useQueryContext} from "../../../contexts/query";
import {putApi} from "../../fetch-api/put";
import {deleteApi} from "../../fetch-api/delete";
import {useMainContext} from "../../../contexts/main";


export const SETTING_KEYS = {
    GET_WORKSPACES: 'get-workspaces',
    CREATE_WORKSPACE: 'create-workspace',
    CHANGE_WORKSPACE: 'change-workspace',
    UPDATE_WORKSPACE: 'update-workspace',
    GET_USERS: 'get-users',
    GET_ALL_USERS: 'get-all-users',
    CREATE_USER: 'create-user',
    UPDATE_USER: 'update-user',
    INDEX_ROLE: 'index-role',
    GET_ROLE: 'get-role',
    GET_ROLE_CONFIG: 'get-role-config',
    CREATE_ROLE: 'create-role',
    UPDATE_ROLE: 'update-role',
    DELETE_ROLE: 'delete-role',
    ALL_ROLES: 'all-roles',
    DELETE_USER :'delete-user',
    DELETE_WORKSPACE: 'delete-workspace',
    GET_A_WORKSPACE: 'get-a-workspace',
    GET_PACKAGES: 'get-packages',
    SET_PACKAGES: 'set-packages',
    GET_UNIT_PRICE: 'get-unit-price',
    SET_UNIT_PRICE: 'set-unit-price'
}



const useGetWorkspaces = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)
    const { profile: {isSuperAdmin} } = useMainContext()

    return useQuery(
        SETTING_KEYS.GET_WORKSPACES, () => filterFetch(params, '/workspace'),
        {
            enabled: isSuperAdmin
        }
    )
}

const useChangeWorkspace = (onSuccess) => {

    return useMutation(
        SETTING_KEYS.CHANGE_WORKSPACE, (data) => postApi(data, '/workspace/change'),
        {
            onSuccess
        }
    )
}


const useGetUsers = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        SETTING_KEYS.GET_USERS, () => filterFetch(params, '/auth/users')
    )
}

const useGetAllUsers = () => {

    return useQuery(
        SETTING_KEYS.GET_ALL_USERS, () => normalFetch('/auth/users/all'),
        {
            select: res => res?.data?.data
        }
    )
}

const useDeleteUser = (id) => {

    const { queryClient, setToast } = useQueryContext()
    return useMutation(
        [SETTING_KEYS.DELETE_USER, id], (id) => deleteApi(`/auth/delete/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SETTING_KEYS.GET_USERS)
                setToast({
                    message: 'op-success',
                    show: true
                })
            }
        }
    )
}


const useCreateWorkspace = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.CREATE_WORKSPACE, (data) => postApi(data, '/workspace'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.GET_WORKSPACES)
            }
        }
    )
}

const useUpdateWorkspace = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.UPDATE_WORKSPACE, (data) => putApi(data, `/workspace/update/${data?.name}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.GET_WORKSPACES)
            }
        }
    )
}

const useGetAWorkspace = (id) => {

    return useQuery(
        [id, SETTING_KEYS.GET_A_WORKSPACE], () => normalFetch(`/workspace/${id}`),
        {
            enabled: !!id,
            select: res => res?.data?.data
        }
    )
}


const useDeleteWorkspace = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.DELETE_WORKSPACE, (id) => deleteApi(`/workspace/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SETTING_KEYS.GET_WORKSPACES)
                setToast({
                    show: true,
                    message: 'op-success'
                })
            }
        }
    )
}


const useIndexRoles = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        SETTING_KEYS.INDEX_ROLE, () => filterFetch(params, '/setting/roles')
    )
}

const useGetWorkspaceRoles = (workspace) => {

    return useQuery(
        [SETTING_KEYS.ALL_ROLES, workspace], () => normalFetch(`/setting/roles/all/${workspace}`),
        {
            enabled: false,
            select: res => res?.data?.data
        }
    )
}


const useGetARole = (id) => {

    return useQuery(
        [SETTING_KEYS.GET_ROLE, id], () => normalFetch(`/setting/role/${id}`),
        {
            enabled: false,
            select: res => res?.data?.data
        }
    )
}

const useCreateRole = (onClose) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.CREATE_ROLE, (data) => postApi(data, '/setting/roles'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.INDEX_ROLE)
                onClose()
            }
        }
    )
}


const useUpdateRole = (onClose) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.UPDATE_ROLE, (data) => putApi(data, `/setting/roles/${data._id}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.INDEX_ROLE)
                onClose()
            }
        }
    )
}

const useGetRoleConfig = () => {

    return useQuery(
        SETTING_KEYS.GET_ROLE_CONFIG, () => normalFetch('/setting/roles/config')
    )
}

const useDeleteRole = () => {
    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.DELETE_ROLE, (id) => deleteApi(`/setting/role/${id}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.INDEX_ROLE)
            }
        }
    )
}

const useGetPackages = () => {

    return useQuery(
        SETTING_KEYS.GET_PACKAGES, () => normalFetch('/workspace/packages/all'),
        {
            select: res => res?.data?.data
        }
    )
}

const useSetPackages = () => {
    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.SET_PACKAGES, ({ payload, name }) => postApi(payload, `/workspace/packages/${name}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.GET_PACKAGES)
            }
        }
    )
}

const useGetUnitPrice = () => {

    return useQuery(
        SETTING_KEYS.GET_UNIT_PRICE, () => normalFetch('/workspace/unit/price'),
        {
            select: res => res?.data?.data
        }
    )
}

const useSetUnitPrice = () => {
    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.SET_UNIT_PRICE, (payload) => putApi(payload, `/workspace/unit/price`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.GET_UNIT_PRICE)
            }
        }
    )
}


export {
    useGetWorkspaces,
    useCreateWorkspace,
    useChangeWorkspace,
    useDeleteWorkspace,
    useGetUsers,
    useIndexRoles,
    useGetRoleConfig,
    useCreateRole,
    useUpdateRole,
    useDeleteRole,
    useGetWorkspaceRoles,
    useGetARole,
    useGetAllUsers,
    useDeleteUser,
    useGetAWorkspace,
    useGetPackages,
    useSetPackages,
    useGetUnitPrice,
    useSetUnitPrice,
    useUpdateWorkspace
}
