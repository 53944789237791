import { useMutation, useQuery } from "react-query"
import { filterFetch } from "../../fetch-api/get"
import { makeQueryFilters } from "../../../utils/query-maker"
import { postApi } from "../../fetch-api/post"


const MEMBER_QUERY_KEYS = {
    INDEX_MEMBERS: 'index-members',
    INDEX_EXTRACT_MEMBER_OPERATIONS: 'index-extract-member-operations'
}

export const useGetAllMembers = ({ filters, pagination }) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        [MEMBER_QUERY_KEYS.INDEX_MEMBERS], () => filterFetch(params, '/member'),
        {
            select: res => res?.data
        }
    )
}

export const useGetExtractOperations = ({ filters, pagination }) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        [MEMBER_QUERY_KEYS.INDEX_EXTRACT_MEMBER_OPERATIONS], () => filterFetch(params, '/member/extract'),
        {
            select: res => res?.data
        }
    )
}


export const useCreateExtractOperation = (onSuccess) => {

    return useMutation(
        [MEMBER_QUERY_KEYS.CREATE_EXTRACT_OPERATION], (payload) => postApi(payload, '/member/extract'),
        {
            onSuccess
        }
    )
}