import Tooltip from "../../common/utils/Tooltip";
import { IconWrapper } from "../../../styles/components/common/common-styles";
import { RiLoader3Line } from "react-icons/ri";
import { useRefreshGroupAccess } from "../../../core/services/react-query/group";
import { useSocketContent } from "../../../core/contexts/socket-content";
import PcViewer from "../../common/utils/PcViewer";
import Text from "../../../core/utils/Text";

const GroupAccessRefresher = ({ countUpdateType }) => {

    const { mutate: refreshChat } = useRefreshGroupAccess()
    const { countUpdates } = useSocketContent()

    return (
        <div>
            {(countUpdates && countUpdates[`${countUpdateType}AccessUpdateCount`] > 0) ?
                <div className={'w-[200px] flex flex-col gap-2 dark:text-white text-xs'}>
                    <Text tid={`${countUpdateType}-access-count`} />
                    <PcViewer
                        total={countUpdates[`${countUpdateType}AccessTotal`]}
                        filled={countUpdates[`${countUpdateType}AccessUpdateCount`]}
                    />
                </div>
                :
                <Tooltip
                    content={'group-access-refresher'}
                >


                    <IconWrapper onClick={refreshChat}>
                        <RiLoader3Line color={'#ffc800'} size={20} />
                    </IconWrapper>

                </Tooltip>
            }
        </div>

    )
}

export default GroupAccessRefresher
