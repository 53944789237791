import styled, {css} from "styled-components";


export const SettingWrapper = styled.div`
  margin-top: 80px;
  padding: 0 40px;
  width: 100%;
`


export const SettingBody = styled.div`
  width: 100%;
  min-height: 80vh;
  background-color: ${props => props.theme.mainBg};
  border: 1px solid ${props => props.theme.primary}20;
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
`

export const AddButtonWrapper = styled.div`
  outline: 1px solid #4a9fbc;
  border-radius: 4px;
  height: 30px;
  padding: 0 8px;
  color: ${props => props.theme.primary};
  font-size: 0.8rem;
  background-color: ${props => props.theme.active};
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.1);

  &:hover {
    background-color: ${props => props.theme.active};
    color: whitesmoke;
  }
`

export const PermTagWrapper = styled.div`
  min-width: 120px;
  height: 32px;
  border-radius: 4px;
  margin: 4px;
  padding: 0 6px;
  transition: all 0.2s;
  
  ${props => props.active && css`
    background-color: ${props => props.color}90;
  `};
  
  ${props => !props.active && css`
    background-color: ${props => props.theme.tInputBg}80;
    filter: brightness(0.8);
  `};
  
  border: 1px solid ${props => props.theme.color}10;
`

export const SettingContainer = styled.div`
  display: grid;
  grid-template-columns: 180px calc(100% - 180px);
  align-items: stretch;
`
