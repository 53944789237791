import { useSimsQuery } from "../../core/services/react-query/sim";
import SimComp from "../../components/main/sim/SimComp";
import CreateButton from "../../components/common/buttons/CreateButton";
import { useNavigate } from 'react-router-dom'
import { useState } from "react";
import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { CacheKeys, simFilterOptions } from "../../core/constants/filter";
import { routes } from "../../core/constants/routes";


const Sims = () => {

    const navigate = useNavigate()
    const navigateToCreateSim = () => {
        navigate(routes.createSim)
    }

    const [data, setData] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setData(res)

    return (
        <>
            <CardLayout>
                <FilterLayout
                    query={useSimsQuery}
                    cache={CacheKeys.SIM}
                    options={simFilterOptions}
                    onQuerySuccess={onQuerySuccess}
                >
                    <SimComp data={data} />
                </FilterLayout>
            </CardLayout>
            <CreateButton
                onClick={navigateToCreateSim}
                tooltip='create-sim'
            />
        </>
    )
}


export default Sims
