import {useMainContext} from "../../core/contexts/main";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {routes} from "../../core/constants/routes";
import {Background, CFlex, DText} from "../../styles/components/common/common-styles";
import Input from "../../components/common/input/Input";
import ActionButton from "../../components/common/buttons/ActionButton";
import Text from "../../core/utils/Text";
import {useLotp} from "../../core/services/react-query/auth";
import { ReactComponent as Network } from "../../assets/landing/network.svg"
import { ReactComponent as FingerPrint } from "../../assets/landing/finger-print.svg"
import RegisterSignin from "../auth/RegisterSignin";

const Home = () => {

    const navigate = useNavigate()
    const { profile: { lotp, token } } = useMainContext()
    useEffect(() => {
        if (!!lotp) navigate(routes.landing)
    }, [lotp])

    useEffect(() => {
        if (!!token) navigate(routes.profile)
    }, [token])

    const { mutate: lotpReq } = useLotp()
    const [value, setValue] = useState('')
    const onLOTP = () => {
        if (value.length === 8) {
            lotpReq({ otp: value })
        }
    }

    const [stage, setStage] = useState(1)


    return (
        <CFlex fw height={'100vh'}>
            <Background bg={'mainBg'}>
                {stage === 1 ?
                    <Network width={440} />
                : null}
                {stage === 2 ?
                    <FingerPrint width={300} />
                : null}
                <div className={'flex flex-col justify-start items-start px-20 mt-8'}>
                    <div className={'w-[320px] flex flex-col gap-5'}>
                        <DText primary>
                            <Text tid={'raynet'} className={'font-semibold text-3xl tracking-wider'} />
                        </DText>
                        {stage === 1 ?
                            <>
                                <Background bg={'mainBg'}>
                                    <Input
                                        value={value}
                                        onInputChange={(v) => setValue(v)}
                                        width={'100%'}
                                        height={'42px'}
                                        label={'lotp-placeholder'}
                                        code
                                    />
                                </Background>
                                <ActionButton width={'100%'} height={'42px'} active onClick={onLOTP}>
                                    <Text tid={'submit'} />
                                </ActionButton>
                            </>
                        : null}
                        {stage === 2 ?
                            <RegisterSignin />
                        : null}
                        <div className={'w-full flex items-center justify-between gap-3 mx-2'}>
                            <div onClick={() => setStage(state => state === 1 ? 2 : 1)}>
                                <Text tid={stage === 1 ? 'go-to-login' : 'enter-invite-code'} className={'text-active text-sm cursor-pointer'} />
                            </div>
                            <a href={'#'} target={'_blank'} className={'text-active text-sm cursor-pointer mx-2'}>
                                <Text tid={'support'} />
                            </a>
                        </div>
                    </div>
                </div>
            </Background>
        </CFlex>

    )
}

export default Home
