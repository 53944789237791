import CreateButton from "../../components/common/buttons/CreateButton";
import ChannelComp from "../../components/main/channel/ChannelComp";
import { useMemo, useState } from "react";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { useChannelsQuery } from "../../core/services/react-query/channel";
import { CacheKeys, chatFilterOptions } from "../../core/constants/filter";
import CardLayout from "../../components/layouts/main/CardLayout";
import ModalLayout from "../../components/layouts/main/ModalLayout";
import CreateChannel from "../../components/main/channel/CreateChannel";
import { useSearchParams } from "react-router-dom";


const Channels = () => {

    const [searchParams] = useSearchParams()
    const [createModal, setCreateModal] = useState(false)

    const [channels, setChannels] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setChannels(res)

    const onCreateChannelSuccess = () => setCreateModal(false)

    const extra = useMemo(() => {
        const category = searchParams.get('category')
        let ex = {}
        if (category) ex.categories = category
        return ex
    }, [searchParams])

    return (
        <>
            <CardLayout>
                <FilterLayout
                    query={useChannelsQuery}
                    cache={CacheKeys.CHANNEL}
                    onQuerySuccess={onQuerySuccess}
                    options={chatFilterOptions}
                    extra={extra}
                >
                    <ChannelComp
                        data={channels}
                    />
                </FilterLayout>
            </CardLayout>
            <CreateButton
                onClick={() => setCreateModal(true)}
                tooltip='create-channel'
            />
            <ModalLayout
                open={createModal}
                onClose={() => setCreateModal(false)}
                width='500px'
            >
                <CreateChannel
                    onCreateChannelSuccess={onCreateChannelSuccess}
                    onClose={() => setCreateModal(false)}
                />
            </ModalLayout>
        </>
    )
}


export default Channels
