import {
    Background,
    CFlex,
    CMargin,
    Decoration,
    DText,
    Flex,
    Padding
} from "../../../../styles/components/common/common-styles";
import CheckBox from "../../../common/input/CheckBox";
import {CREATE_C2C} from "./CreateC2c";
import Text from "../../../../core/utils/Text";
import Input from "../../../common/input/Input";


const Step2 = (props) => {

    const {
        inputData,
        onInputValueChange
    } = props

    return (
        <CFlex align={'flex-start'}>
            <Flex style={{ marginBottom: '16px' }}>
                <Decoration />
                <DText main>
                    <Text tid={'c2c-step2-note'} />
                </DText>
            </Flex>
            <Flex>
                <CheckBox active={inputData.hasEitaa} onClick={() => onInputValueChange(null, CREATE_C2C.HAS_EITAA)} />
                <DText primary style={{ margin: '0 5px' }}>
                    <Text tid={'has-eitaa'} />
                </DText>
            </Flex>
            <CMargin margin={'8px'} />
            <CFlex width={'530px'} align={'flex-start'}>
                {inputData.hasEitaa &&
                    <>
                        <Background bg={'primaryBg'}>
                            <Input
                                value={inputData.eitaaBridge}
                                onInputChange={(v) => onInputValueChange(v, CREATE_C2C.EITAA_BRIDGE)}
                                label={'eitaa-bridge'}
                                size={'small'}
                                height={'32px'}
                                width={'100%'}
                            />
                        </Background>
                        <Background bg={'primaryBg'}>
                            <Input
                                value={inputData.eitaaYar}
                                onInputChange={(v) => onInputValueChange(v, CREATE_C2C.EITAAYAR)}
                                label={'eitaa-yar'}
                                size={'small'}
                                height={'32px'}
                                width={'100%'}
                            />
                        </Background>
                        <CMargin margin={'4px'} />
                        <Padding padding={'4px'}>
                            <Background bg={'primaryBg'}>
                                <Input
                                    value={inputData.eitaaDestination}
                                    onInputChange={(v) => onInputValueChange(v, CREATE_C2C.EITAA_DESTINATION)}
                                    label={'eitaa-destination'}
                                    size={'small'}
                                    height={'32px'}
                                    width={'100%'}
                                />
                            </Background>
                        </Padding>
                    </>
                }
            </CFlex>

        </CFlex>
    )
}

export default Step2
