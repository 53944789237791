import {useMutation, useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {postApiWithToken} from "../../fetch-api/post";
import {useQueryContext} from "../../../contexts/query";
import {deleteApi} from "../../fetch-api/delete";
import {useNavigate} from "react-router-dom";
import {putApi} from "../../fetch-api/put";
import {makeQueryFilters} from "../../../utils/query-maker";
import {routes} from "../../../constants/routes";


const OPERATION_QUERY_KEYS = {
    GET_OPERATIONS: 'get-operations',
    CREATE_OPERATION: 'create-operation',
    RUN_OPERATION: 'run-operation',
    STOP_OPERATION: 'stop-operation',
    DELETE_OPERATION: 'delete-operation',
    GET_ONE_OPERATION: 'get-one-operation',
    UPDATE_OPERATION: 'update-operation'
}

const useOperationQuery = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        OPERATION_QUERY_KEYS.GET_OPERATIONS, () => filterFetch(params, '/operation')
    )
}


const useGetOperation = (id) => {

    return useQuery(
        OPERATION_QUERY_KEYS.GET_ONE_OPERATION, () => normalFetch(`/operation/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}


const useCreateOperation = () => {

    const navigate = useNavigate()
    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        OPERATION_QUERY_KEYS.CREATE_OPERATION, (data) => postApiWithToken(data, '/operation'),
        {
            onSuccess: (_) => {
                queryClient.invalidateQueries(OPERATION_QUERY_KEYS.GET_OPERATIONS)
                setToast({
                    message: 'op-success',
                    show: true
                })
                navigate(routes.operations)
            }
        }
    )
}

const useRunOperation = () => {

    const { queryClient } = useQueryContext()

    return useMutation(
        OPERATION_QUERY_KEYS.RUN_OPERATION, (data) => postApiWithToken(data, '/operation/run'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(OPERATION_QUERY_KEYS.GET_OPERATIONS)
            }
        }
    )
}


const useStopOperation = () => {

    const { queryClient } = useQueryContext()

    return useMutation(
        OPERATION_QUERY_KEYS.STOP_OPERATION, (data) => postApiWithToken(data, '/operation/stop'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(OPERATION_QUERY_KEYS.GET_OPERATIONS)
            }
        }
    )
}


const useUpdateOperation = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        OPERATION_QUERY_KEYS.UPDATE_OPERATION, (data) => putApi(data, '/operation/update'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(OPERATION_QUERY_KEYS.GET_OPERATIONS)
                setToast({
                    show: true, message: 'op-success'
                })
            }
        }
    )
}


const useDeleteOperation = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        OPERATION_QUERY_KEYS.DELETE_OPERATION, (id) => deleteApi(`/operation/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(OPERATION_QUERY_KEYS.GET_OPERATIONS)
                setToast({
                    message: 'op-success',
                    show: true
                })
            }
        }
    )
}


export {
    useOperationQuery,
    useCreateOperation,
    useRunOperation,
    useStopOperation,
    useDeleteOperation,
    useGetOperation,
    useUpdateOperation
}
