import { ReactComponent as ProfileImage } from "../../assets/illustrations/profile.svg";
import {CFlex, DText, Flex, Grid, LineBreak} from "../../styles/components/common/common-styles";
import {useProfileQuery} from "../../core/services/react-query/auth";
import {useEffect, useMemo, useState} from "react";
import {useAccessLevels} from "../../core/services/acl/useAccessLevels";
import Text from "../../core/utils/Text";
import {Tag} from "../../styles/components/normalizer";
import {useGetARole, useGetAWorkspace} from "../../core/services/react-query/setting";
import {getRandomColor} from "../../core/utils/theme";
import {PermTagWrapper} from "../../styles/components/setting";
import CreditBar from "../../components/common/utils/CreditBar";
import { IoInfiniteSharp } from "react-icons/io5"

const Profile = () => {

    const { data: profile, refetch: getProfile } = useProfileQuery()
    const { permissions } = useAccessLevels()
    const [data, setData] = useState({})

    const { data: role, refetch: getRole } = useGetARole(profile?.role)
    const { data: workspace } = useGetAWorkspace(profile?.workspace)

    useEffect(() => {
        if (!profile) getProfile()
        else {
            if (profile.role !== 'superAdmin') getRole()
            const newData = {}
            newData.username = profile.username
            newData.email = profile.email
            newData.workspace = profile.workspace
            setData(newData)
        }
    }, [profile])

    const credit = useMemo(() => {
        let temp = []
        const noLimit = workspace?.noLimit
        temp.push({
            name: 'user',
            items: [
                { name: "user-count", count: workspace?.users?.length || 0, limit: noLimit ? 'noLimit' : workspace?.userLimit},
            ]
        })
        temp.push({
            name: 'c2c',
            items: [
                { name: "channel-count", count: workspace?.c2c?.channelCount || 0, limit: noLimit ? 'noLimit' : (workspace?.c2c?.channelLimit || 0)},
                { name: "message-count", count: workspace?.c2c?.messageCount || 0, limit: noLimit ? 'noLimit' : (workspace?.c2c?.messageLimit || 0)}
            ]
        })
        temp.push({
            name: 'c2g',
            items: [
                { name: "group-count", count: workspace?.c2g?.groupCount || 0, limit: noLimit ? 'noLimit' : (workspace?.c2g?.groupLimit || 0)},
                { name: "message-count", count: workspace?.c2g?.messageCount || 0, limit: noLimit ? 'noLimit' : (workspace?.c2g?.messageLimit || 0)}
            ]
        })
        temp.push({
            name: 'forwarder',
            items: [
                { name: "message-count", count: workspace?.forwarder?.messageCount || 0, limit: noLimit ? 'noLimit' : (workspace?.forwarder?.messageLimit || 0)}
            ]
        })
        temp.push({
            name: 'sender',
            items: [
                { name: "message-count", count: workspace?.sender?.messageCount || 0, limit: noLimit ? 'noLimit' : (workspace?.sender?.messageLimit || 0)}
            ]
        })
        return temp
    }, [workspace])

    return (
        <CFlex fw>
            <ProfileImage width={'320px'} height={'320px'} />
            <Flex fw fh justify={'space-around'} >
                <div style={{ width: '520px', padding: '10px 25px' }}>

                    {Object.keys(data).map(key => {
                        const value = data[key]
                        return (
                            <Flex fw key={key} style={{ margin: '10px 0' }}>
                                <Flex width={'50%'} justify={'flex-start'}>
                                    <DText main>
                                        <Text tid={key} /> :
                                    </DText>
                                </Flex>
                                <Flex width={'50%'} justify={'flex-start'}>
                                    <Tag dark style={{ fontSize: '0.8rem' }}>
                                        {value}
                                    </Tag>
                                </Flex>
                            </Flex>
                        )
                    })}

                    <Flex fw style={{ margin: '10px 0' }}>
                        <Flex width={'50%'} justify={'flex-start'}>
                            <DText main>
                                <Text tid={'role'} /> :
                            </DText>
                        </Flex>
                        <Flex width={'50%'} justify={'flex-start'} wrap>
                            <Tag dark style={{ fontSize: '0.8rem' }}>
                                {profile?.role === 'superAdmin' ? profile?.role : role?.name}
                            </Tag>
                        </Flex>
                    </Flex>
                    <Flex fw style={{ margin: '10px 0' }} justify={'flex-start'}>
                        <DText main>
                            <Text tid={'permissions'} /> :
                        </DText>
                    </Flex>
                    <Flex fw justify={'flex-start'} wrap>
                        {Object.keys(permissions).map((key, index) => {
                            if (!permissions[key]) return <></>
                            return (
                                <PermTagWrapper active color={getRandomColor(index)}>
                                    <Flex fw fh style={{ fontSize: '0.8rem' }}>
                                        <Text tid={key} />
                                    </Flex>
                                </PermTagWrapper>
                            )
                        })}
                    </Flex>
                </div>

                <div style={{ width: '520px', padding: '10px 25px' }}>
                    <DText primary fontSize={'b'}>
                        <Text tid={'credit'} />
                    </DText>
                    <LineBreak />
                    {credit?.map(item => {

                        return (
                            <>
                                <DText primary><Text tid={item.name} /></DText>
                                {item.items.map(sub => (
                                    <Grid fw gc={3} style={{ margin: '12px 0' }}>
                                        <DText main><Text tid={sub.name} /></DText>
                                        <CreditBar count={sub.count} limit={sub.limit} />
                                        {sub.limit === 'noLimit' ?
                                            <Flex style={{ direction: 'ltr' }}>
                                                <DText primary>
                                                    {`${sub.count} / `}
                                                </DText>
                                                <IoInfiniteSharp size={18} color={'#ffc800'} style={{ margin: '0 5px' }} />
                                            </Flex>
                                            :
                                            <DText primary style={{ direction: 'ltr' }}>
                                                {`${sub.count} / ${sub.limit}`}
                                            </DText>
                                        }

                                    </Grid>
                                ))}
                                <LineBreak />
                            </>
                        )
                    })}
                </div>
            </Flex>
        </CFlex>
    )
}

export default Profile
