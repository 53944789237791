import Text from "../../../core/utils/Text";
import FilterBox from "./FilterBox";
import {useEffect, useRef, useState} from "react";
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import {AnimatePresence} from "framer-motion";
import FilterDropdown from "./FilterDropdown";
import {CloseIcon, OpenIcon} from "../../../styles/components/common/select-styles";
import {useMainContext} from "../../../core/contexts/main";
import {useFilterContext} from "../../../core/contexts/filter";
import {AddFilterBtn, FilterIcon} from "../../../styles/layouts/filter";
import {DText} from "../../../styles/components/common/common-styles";


const AddFilter = ({
    options = []
}) => {

    const { main : {lang} } = useMainContext()
    const { cache, filters } = useFilterContext()

    const [ selectedFilters, setSelectedFilters ] = useState([])

    const [filterBoxOpen, setFilterBoxOpen] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [filter, setFilter] = useState(null)

    const onFilterClose = () => {
        setFilterBoxOpen(false)
        setDropdownOpen(false)
    }

    const boxRef = useRef()
    useClickOutside(boxRef, onFilterClose)

    const onOptionClicked = (idx) => {
        setFilterBoxOpen(true)
        setFilter(options[idx])
    }

    useEffect(() => {
        if (cache && cache in filters) {
            const temp = Object.keys(filters[cache])
            if (filters.firstName || filters.lastName ) temp.push('name')
            setSelectedFilters(temp)
        }
    }, [filters])

    return (
        <>
            <div style={{ position: 'relative'}}>
                <div
                    ref={boxRef}
                >
                    <Controller
                        onClick={() => setDropdownOpen(true)}
                        open={dropdownOpen}
                    />
                    {dropdownOpen &&
                        <>
                            <FilterDropdown
                                open={dropdownOpen}
                                options={options.map(item => item.search)}
                                onOptionClicked={onOptionClicked}
                                Controller={Controller}
                                minWidth='160px'
                                right={lang === 'fa' && '0'}
                                selected={selectedFilters}
                            />
                            <AnimatePresence exitBeforeEnter>
                                {filterBoxOpen &&
                                    <FilterBox
                                        type={'create'}
                                        filter={filter}
                                        onClose={onFilterClose}
                                    />
                                }
                            </AnimatePresence>
                        </>
                    }
                </div>
            </div>

        </>
    )
}


const Controller = (props) => {

    const { open } = props

    return (
        <AddFilterBtn {...props}>
            <FilterIcon size={18}/>
            <DText primary fontSize={'s'}>
                <Text tid='add-filter' />
            </DText>
            {open ?
                <CloseIcon size={20} />
                :
                <OpenIcon size={20} />
            }
        </AddFilterBtn>
    )
}

export default AddFilter
