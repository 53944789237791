import {useFilterContext} from "../../contexts/filter";


const useTableLayout = () => {


    const { pagination: _pagination, setPagination, cache } = useFilterContext()
    const pagination = _pagination[cache]

    const onPageChange = (page) => {
        const newState = {...pagination}
        newState.page = page
        setPagination(newState)
    }

    const onLimitChange = (limit) => {
        const newState = {...pagination}
        newState.limit = limit
        setPagination(newState)
    }

    return {
        pagination,
        onPageChange,
        onLimitChange
    }
}

export default useTableLayout
