import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import PageNotFound from "./PageNotFound";
import NoAccess from "./NoAccess";
import useSidebar from "../../../core/hooks/layout/useSidebar";


const Restricted = ({children}) => {

    const location = useLocation()
    const [path, setPath] = useState(location.pathname)

    useEffect(() => {
        let _path = location.pathname

        _path = _path.substring(1, _path.length).split('/')
        setPath(`/${_path[0]}`)
    }, [location])

    const [ hasAccess, setHasAccess ] = useState(null)

    const { urlDirectAccess } = useSidebar()
    useEffect(() => {
        if (urlDirectAccess) {
            if (typeof urlDirectAccess[path] !== 'boolean') setHasAccess('redirect')
            else setHasAccess(true)
        }
    }, [urlDirectAccess, path])

    const LOGIN = path === '/login'
    const LANDING = path === '/rayna'
    const REGISTER = path === '/register'
    const FIRST_PAGE = path === '/'


    return (
        <>
            {((hasAccess && hasAccess !== 'redirect') || LOGIN || FIRST_PAGE || LANDING || REGISTER) && children}
            {(hasAccess === 'redirect' && !FIRST_PAGE) &&
                <PageNotFound />
            }
            {hasAccess === false &&
                <NoAccess />
            }
        </>
    )
}


export default Restricted
