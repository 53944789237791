import { useEffect, useMemo, useState } from "react"
import { Background, CMargin, CloseIcon, IconWrapper, Padding } from "../../../../styles/components/common/common-styles"
import Input from "../../../common/input/Input"
import Select from "../../../common/select/Select"
import useOperationalSims from "../../../../core/hooks/main/sim/useOperationalSims"
import { useSimGroupsQuery } from "../../../../core/services/react-query/group"
import MultiSelect from "../../../common/select/MultiSelect"
import ActionButton from "../../../common/buttons/ActionButton"
import ButtonLoader from "../../../common/loading/ButtonLoader"
import { useCreateExtractOperation } from "../../../../core/services/react-query/member"



const ExtractMembersModal = ({ onClose }) => {

    const [name, setName] = useState('')
    const [sim, setSim] = useState(null)
    const [groups, setGroups] = useState([])
    const [delay, setDelay] = useState('')

    const [simOptions, setSimOptions] = useState([])
    const { simsList } = useOperationalSims()
    useEffect(() => {
        if (simsList) setSimOptions(simsList.map(item => item.username))
    }, [simsList])

    const { data: simGroupList, refetch: getSimGroups } = useSimGroupsQuery(sim?._id)
    useEffect(() => {
        if (sim) getSimGroups()
    }, [sim])

    const onSimChange = (idx) => {
        setSim(simsList?.find(sim => sim.username === simOptions[idx]))
    }

    const onGroupsChange = (selectedOptions) => {
        setGroups(selectedOptions)
    }

    const valid = useMemo(() => {
        return !!name && !!sim && groups.length && !!delay
    }, [name, sim, groups, delay])

    const { mutate: createExtractOperation, isLoading: creatingOperation } = useCreateExtractOperation(onClose)
    const onSubmitClicked = () => {
        if (!valid) return
        createExtractOperation({
            name, sim: sim._id, groups: groups.map(x => x._id), delay
        })
    }


    return (
        <div className={'relative'}>
            <div className={'absolute left-[-10px] top-[-10px]'}>
                <IconWrapper onClick={onClose}>
                    <CloseIcon />
                </IconWrapper>
            </div>
            <div className={"flex flex-col pt-2"}>
                <Background bg={'mainBg'}>
                    <Input
                        value={name}
                        onInputChange={(value) => setName(value)}
                        label='name'
                    />

                    <Padding padding='4px'>
                        <Select
                            width={'100%'}
                            value={sim?.username}
                            placeHolder={'choose-sim'}
                            options={simOptions}
                            onValueChange={(idx) => onSimChange(idx)}
                        />
                    </Padding>

                    <CMargin margin={'10px'} />
                    <Padding padding='4px'>
                        <MultiSelect
                            values={groups}
                            onValuesChange={onGroupsChange}
                            placeHolder='select-groups'
                            options={simGroupList || []}
                            type='chat'
                            param='address'
                        />
                    </Padding>

                    <CMargin margin={'10px'} />
                    <Input
                        value={delay}
                        onInputChange={(value) => setDelay(value)}
                        label='delay-seconds'
                        code
                    />

                    <CMargin margin={'8px'} />
                    <ActionButton
                        active={valid}
                        onClick={onSubmitClicked}
                    >
                        <ButtonLoader
                            tid='submit'
                            loading={creatingOperation}
                        />
                    </ActionButton>
                </Background>
            </div>
        </div>
    )
}

export default ExtractMembersModal