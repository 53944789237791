import {
    CFlex,
    CMargin,
    Decoration,
    DText,
    Flex,
    LineBreak,
    NormalBtn
} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import {FiAlertTriangle} from "react-icons/fi";


const DeleteModal = ({
    type,
    extra,
    onSubmit,
    onCancel
}) => {

    return (
        <CFlex fw align="flex-start" transparent>
            <Flex fw justify='space-between'>
                <DText primary fontSize='sb'>
                    <Text tid={`${type}-delete-title`} />
                </DText>
                <FiAlertTriangle color='#ffc800' size={32} />
            </Flex>
            <CMargin margin='4px' />
            <LineBreak />
            <CMargin margin='8px' />
            <DText main>
                <Text tid={`${type}-delete-question`} />
            </DText>

            {extra?.map(item => (
                <>
                    <CMargin margin='4px' />
                    <Flex>
                        <Decoration />
                        <DText main>
                            <Text tid={item?.title} /> :
                        </DText>
                        <DText primary>
                            {item?.count}
                        </DText>
                    </Flex>
                </>
            ))}

            <CMargin margin='20px' />
            <Flex fw justify='flex-end'>
                <NormalBtn active onClick={onSubmit}>
                    <Flex fw fh>
                        <Text tid={'submit'} />
                    </Flex>
                </NormalBtn>

                <NormalBtn onClick={onCancel}>
                    <Flex fw fh>
                        <Text tid={'cancel'} />
                    </Flex>
                </NormalBtn>
            </Flex>
        </CFlex>
    )
}


export default DeleteModal
