import styled, {css} from "styled-components";
import {motion} from "framer-motion";


const CardWrapper = styled(motion.div)`
  width: 99%;
  padding: 20px 30px;
  min-height: 78vh;
  margin-top: 120px;
  background-color: ${props => props.theme.mainBg};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.active}70;
  
  &::after{
    content: '';
    position: absolute;
    top: -40px;
    
    ${props => props.theme.english && css`
      right: -40px;
    `};
    
    ${props => !props.theme.english && css`
      left: -40px;
    `};

    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    background-color: ${props => props.theme.primaryBg};
    outline: 1px solid ${props => props.theme.active};
    box-shadow: 0 0 8px ${props => props.theme.active};
    z-index: 10;
  };
  
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% - 40px);
    box-shadow: 0 0 8px ${props => props.theme.active};
    top: 40px;
    right: -1px;
  }
  
  
  @media screen and (max-width: 768px) {
    width: calc(99% - 60px);
  };
`


export {
    CardWrapper
}
