import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../../fetch-api/get";
import { postApiWithToken } from "../../fetch-api/post";
import { useQueryContext } from "../../../contexts/query";
import { deleteApi } from "../../fetch-api/delete";
import { putApi } from "../../fetch-api/put";
import { makeQueryFilters } from "../../../utils/query-maker";


const GROUP_QUERY_KEYS = {
    GET_GROUPS: 'get-groups',
    GET_SIM_GROUPS: 'get-sim-groups',
    CREATE_GROUP: 'create-group',
    UPDATE_GROUP: 'update-group',
    DELETE_GROUP: 'delete-group',
    GET_A_GROUP: 'get-a-group',
    REFRESH_GROUPS: 'refresh-groups',
    REFRESH_GROUPS_ACCESS: 'refresh_group_access',
    EXTRACT_MEMBERS: 'extract-members'
}


const useGroupsQuery = (filters, pagination, extra) => {

    let params = makeQueryFilters(filters, pagination)
    if (!!extra) params = { ...params, ...extra }

    return useQuery(
        GROUP_QUERY_KEYS.GET_GROUPS, () => filterFetch(params, '/group'),
        {
            select: res => res?.data,
            refetchInterval: 5 * 60 * 1000
        }
    )
}


const useGetAGroup = (id) => {

    const { queryClient } = useQueryContext()

    return useQuery(
        [GROUP_QUERY_KEYS.GET_A_GROUP, id], () => normalFetch(`/group/${id}`),
        {
            select: res => res?.data?.data,
            enabled: false,
            onError: () => {
                queryClient.removeQueries(GROUP_QUERY_KEYS.GET_A_GROUP)
            }
        }
    )
}

const useSimGroupsQuery = (id) => {
    return useQuery(
        GROUP_QUERY_KEYS.GET_SIM_GROUPS, () => filterFetch({}, `/group/simGroups/${id}`),
        {
            enabled: false,
            select: res => res.data?.data
        }
    )
}


const useCreateGroupMutation = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        GROUP_QUERY_KEYS.CREATE_GROUP, (data) => postApiWithToken(data, '/group'),
        {
            onSuccess: (res) => {
                setToast({
                    show: true,
                    message: res.data?.message
                })
                queryClient.invalidateQueries(GROUP_QUERY_KEYS.GET_GROUPS)
                onSuccess && onSuccess(res)
            }
        }
    )
}


const useUpdateGroupMutation = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        GROUP_QUERY_KEYS.UPDATE_GROUP, (data) => putApi(data, '/group/update'),
        {
            onSuccess: (res) => {
                setToast({
                    show: true,
                    message: res.data?.message
                })
                queryClient.invalidateQueries(GROUP_QUERY_KEYS.GET_GROUPS)
                onSuccess && onSuccess()
            }
        }
    )
}


const useDeleteGroupMutation = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        GROUP_QUERY_KEYS.DELETE_GROUP, (id) => deleteApi(`/group/${id}`),
        {
            onSuccess: (res) => {
                setToast({
                    show: true,
                    message: res.data?.message
                })
                queryClient.invalidateQueries(GROUP_QUERY_KEYS.GET_GROUPS)
                onSuccess && onSuccess(res)
            }
        }
    )

}

const useRefreshGroups = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        GROUP_QUERY_KEYS.REFRESH_GROUPS, () => postApiWithToken({}, '/group/refresh'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success',
                    show: true
                })
                queryClient.invalidateQueries(GROUP_QUERY_KEYS.GET_GROUPS)
            }
        }
    )
}


const useRefreshGroupAccess = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        GROUP_QUERY_KEYS.REFRESH_GROUPS_ACCESS, () => postApiWithToken({}, '/group/updateAccess'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success',
                    show: true
                })
                queryClient.invalidateQueries(GROUP_QUERY_KEYS.GET_GROUPS)
            }
        }
    )
}

const useExtractGroupMembers = () => {

    return useMutation(
        GROUP_QUERY_KEYS.EXTRACT_MEMBERS, () => postApiWithToken({}, '/group/extract/members')
    )
}

export {
    useGroupsQuery,
    useSimGroupsQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useGetAGroup,
    useRefreshGroups,
    useRefreshGroupAccess,
    useExtractGroupMembers
}
