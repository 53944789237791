import OperationComp from "../../components/main/operation/OperationComp";
import CreateButton from "../../components/common/buttons/CreateButton";
import {useNavigate} from "react-router-dom";
import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import {useOperationQuery} from "../../core/services/react-query/operation";
import {CacheKeys, operationFilterOptions} from "../../core/constants/filter";
import {useState} from "react";
import {routes} from "../../core/constants/routes";


const Operations = () => {

    const navigate = useNavigate()
    const navigateToCreateOperation = () => {
        navigate(routes.createOperation)
    }

    const [operations, setOperations] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setOperations(res)

    return (
        <>
            <CardLayout>
                <FilterLayout
                    query={useOperationQuery}
                    cache={CacheKeys.OPERATION}
                    onQuerySuccess={onQuerySuccess}
                    options={operationFilterOptions}
                >
                    <OperationComp data={operations}/>
                </FilterLayout>
            </CardLayout>
            <CreateButton
                onClick={navigateToCreateOperation}
                tooltip='create-operation'
            />
        </>
    )
}

export default Operations
