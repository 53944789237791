import { DText, Flex } from "../../styles/components/common/common-styles";
import ActionButton from "../../components/common/buttons/ActionButton"
import Text from "../../core/utils/Text";
import { useExtractGroupMembers } from "../../core/services/react-query/group";

const Manager = () => {

    const { mutate: extractMembers } = useExtractGroupMembers()

    return (
        <Flex fw fh>
            {/*<ActionButton active width={'300px'} onClick={() => extractMembers()}>*/}
            {/*    <Text tid={'extract-members'} />*/}
            {/*</ActionButton>*/}
        </Flex>
    )
}

export default Manager
