import { Column, DateBadge, DetailsLtr, DetailsRtl, Row } from "../../../styles/layouts/table-styles";
import { CFlex, DeleteIcon, DText, Flex, IconWrapper, UpdateIcon, ResendIcon } from "../../../styles/components/common/common-styles";
import { formatDate } from "../../../core/utils/common";
import Text from "../../../core/utils/Text";
import { useMainContext } from "../../../core/contexts/main";
import { ActionWrapper } from "../../../styles/components/sim/sim-styles";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteASim, useResendSimCode, useUpdateSim } from "../../../core/services/react-query/sim";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { BooleanIcon } from "../../common/utils/BoleanIcon";
import Tooltip from "../../common/utils/Tooltip";
import ModalLayout from "../../layouts/main/ModalLayout";
import ActivateSimModal from "../../modals/ActivateSimModal";
import UpdateSimNameModal from "../../modals/UpdateSimNameModal";



const SimList = ({
    sims,
    cs
}) => {

    const { main: { lang } } = useMainContext()
    const navigate = useNavigate()

    const [selectedId, setSelectedId] = useState({ id: '', sim: null })
    const { mutate: updateSim, isLoading } = useUpdateSim(selectedId.id)

    const onChangeClicked = (id) => {
        navigate(`/sims/create-sim`, { state: { id } })
    }

    const SimAction = (props) => {
        const { sim } = props
        if (sim.type === 'alpha') return <></>
        const active = sim.active

        const onActionClicked = () => {
            setSelectedId({ id: sim._id, sim })
        }

        return (
            <ActionWrapper active={active} {...props} onClick={onActionClicked}>
                {(isLoading && selectedId.id === sim._id) ?
                    <ClipLoader color={'#ffc800'} size={20} />
                    :
                    <Text tid={active ? 'stop' : 'start'} />
                }
            </ActionWrapper>
        )
    }

    useEffect(() => {
        if (selectedId.sim) {
            const payload = {
                active: !selectedId.sim.active,
                sim_id: selectedId.id
            }
            updateSim(payload)
        }
    }, [selectedId, updateSim])


    const [activateData, setActivateData] = useState({
        code: '', code_hash: '', sim_id: ''
    })
    const [codeModalOpen, setCodeModalOpen] = useState(false)
    const onResendCodeSuccess = (res) => {
        setCodeModalOpen(true)
        const data = res?.data?.data
        setActivateData(state => ({
            ...state,
            code_hash: data?.code_response?.phoneCodeHash,
            sim_id: data?.sim?._id
        }))
    }
    const { mutate: resendCode } = useResendSimCode(onResendCodeSuccess)
    const reactivate = (sim) => {
        resendCode(sim._id)
    }

    const { mutate: deleteSim } = useDeleteASim()
    const onDeleteClicked = (item) => {
        deleteSim(item._id)
    }

    // update sim name
    const initialUpdateModal = { show: false, data: null }
    const [updateNameModal, setUpdateNameModal] = useState(initialUpdateModal)
    const closeUpdateNameModal = () => setUpdateNameModal(initialUpdateModal)
    const openUpdateNameModal = (data) => setUpdateNameModal({ show: true, data })


    return (
        <>
            {sims.data?.map((item, idx) => (
                <Row key={item.date} cs={cs} action={item.active ? 'active' : 'disabled'}>

                    <Column style={{ alignItems: 'center' }}>
                        <div>
                            {idx + 1}
                        </div>
                    </Column>

                    <Column>
                        <Flex justify={'flex-start'} style={{ gap: 8 }}>
                            <DText style={{ marginTop: 4 }}>
                                {item.username}
                            </DText>
                            <UpdateIcon size={22} onClick={() => openUpdateNameModal(item)} />
                        </Flex>
                    </Column>

                    <Column>
                        <DText>
                            0{item.phone}
                        </DText>
                    </Column>

                    <Column>
                        <DateBadge>
                            <CFlex>
                                <DText primary>
                                    {formatDate(item.createdAt, 'date', lang)}
                                </DText>
                                <DText primary>
                                    {formatDate(item.createdAt, 'time', lang)}
                                </DText>
                            </CFlex>
                        </DateBadge>
                    </Column>

                    <Column>
                        {item.type === 'alpha' ?
                            <span dir={'ltr'}>
                                <span>{item.publicCount}</span>
                                <span> / </span>
                                <span>100</span>
                            </span>
                            :
                            <span>--</span>
                        }
                    </Column>

                    <Column>
                        {item.type === 'alpha' ?
                            <span dir={'ltr'}>
                                <span>{item.privateCount}</span>
                                <span> / </span>
                                <span>100</span>
                            </span>
                            :
                            <span>--</span>
                        }
                    </Column>

                    <Column>
                        <DText color={item.active ? '#1ce087' : '#e9106c'}>
                            <Text tid={item.active ? 'active' : 'disabled'} />
                        </DText>
                    </Column>
                    <Column>
                        <BooleanIcon bool={item.ready} />
                    </Column>

                    <Column more>
                        {!item.ready &&
                            <Tooltip content={'reactivate-sim'}>
                                <IconWrapper onClick={() => reactivate(item)}>
                                    <ResendIcon size={22} />
                                </IconWrapper>
                            </Tooltip>
                        }
                        {item.ready &&
                            <SimAction sim={item} />
                        }
                        <IconWrapper>
                            <UpdateIcon
                                size={22}
                                onClick={() => onChangeClicked(item._id)}
                            />
                        </IconWrapper>
                        <IconWrapper onClick={() => onDeleteClicked(item)}>
                            <DeleteIcon
                                size={22}
                                onClick={() => { }}
                            />
                        </IconWrapper>

                        <Link to={`/sims/${item._id}`}>
                            <Flex width='32px' style={{ cursor: 'pointer' }}>
                                {lang === 'en' ?
                                    <DetailsLtr size={24} />
                                    :
                                    <DetailsRtl size={24} />
                                }
                            </Flex>
                        </Link>
                    </Column>
                </Row>
            ))}

            <ModalLayout
                open={codeModalOpen}
                onClose={() => { }}
                width='500px'
            >
                <ActivateSimModal
                    activateData={activateData}
                    onClose={() => setCodeModalOpen(false)}
                />
            </ModalLayout>

            <ModalLayout
                open={updateNameModal.show}
                onClose={closeUpdateNameModal}
                width={'500px'}
            >
                <UpdateSimNameModal
                    sim={updateNameModal.data}
                    onClick={closeUpdateNameModal}
                />
            </ModalLayout>
        </>
    )
}


export default SimList
