import Section1 from "../../components/landing/Section1";
import Section2 from "../../components/landing/Section2";
import Section3 from "../../components/landing/Section3";
import Section4 from "../../components/landing/Section4";
import Section5 from "../../components/landing/Section5";
import Section6 from "../../components/landing/Section6";
import Footer from "../../components/landing/Footer";


const Landing = () => {

    return (
        <div className={'w-screen h-screen bg-mainBg'}>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section5 />
            <Section4 />
            <Section6 />
            <Footer />
        </div>
    )
}

export default Landing
