import QueryContextProvider from "../../../core/contexts/query";
import MainContextProvider from "../../../core/contexts/main";


const Providers = (props) => {


    return (
        <MainContextProvider>
            <QueryContextProvider>
                {props.children}
            </QueryContextProvider>
        </MainContextProvider>
    )
}

export default Providers
