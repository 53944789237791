import {useMainContext} from "../../core/contexts/main";
import ModalLayout from "../layouts/main/ModalLayout";
import {DText} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import {InfoIcon} from "../../styles/components/dashboard";


const NeedToBuyPackage = () => {

    const { needPackage, setNeedPackage } = useMainContext()

    return (
        <ModalLayout
            width={'600px'}
            open={needPackage}
            onClose={() => setNeedPackage(false)}
        >
            <div className={'flex flex-col justify-center items-center gap-5'}>
                <div className={'flex justify-center items-center gap-2'}>
                    <InfoIcon size={24} />
                    <DText primary fontSize={'b'}>
                        <Text tid={'need-package'} />
                    </DText>
                </div>
                <div className={'flex items-start w-full'}>
                    <DText main>
                        <Text tid={'need-package-desc'} />
                    </DText>
                </div>
                <div className={'bg-[#3B6978] py-2 px-5 rounded-md flex items-center justify-center cursor-pointer'}>
                    <DText primary>
                        <Text tid={'buy-package'} />
                    </DText>
                </div>
            </div>
        </ModalLayout>
    )
}

export default NeedToBuyPackage
