import CategoryComp from "../../components/main/category/CategoryComp";


const Categories = () => {


    return (
        <>
            <CategoryComp />
        </>
    )

}



export default Categories
