import styled from "styled-components";
import {CloseIcon, DText, Flex} from "../../../styles/components/common/common-styles";
import {useState} from "react";
import {CREATE_C2C} from "../../main/c2c/create/CreateC2c";


const TextAreaInput = (props) => {

    const {
        values,
        onInputValueChange,
        dark = true,
        disabled,
        ...rest
    } = props

    const [ value, setValue ] = useState('')

    const onChange = (e) => setValue(e?.target?.value)

    const onKeyPress = (e) => {
        if (e?.code === 'Enter') {
            onInputValueChange(value, 'add')
            setValue('')
        }
    }


    return (
        <Wrapper {...rest}>
            <Flex fw wrap justify={'flex-start'}>
                {values?.map((item, idx) => (
                    <Tag dark={dark}>
                        <Flex fh>
                            <DText primary cFontSize={'0.775rem'}>
                                {item}
                            </DText>
                            <CloseIcon size={14} onClick={() => !disabled && onInputValueChange(idx, 'delete')}/>
                        </Flex>
                    </Tag>
                ))}
                {!disabled &&
                    <Input
                        value={value}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                    />
                }
            </Flex>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 80px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}20;
  padding: 10px;
`

const Input = styled.input`
  width: 118px;
  height: 28px;
  font-size: 0.775rem;
  padding: 0 8px;
  background-color: ${props => props.theme.mainBg};
  border: 1px solid ${props => props.theme.primary}20;
  outline: none;
  color: ${props => props.theme.primary};
  font-family: Jetbrain;
`

const Tag = styled.div`
  background-color: ${props => props.dark ? props.theme.mainBg : props.theme.primaryBg};
  border-radius: 4px;
  height: 28px;
  padding: 0 12px;
  border: 1px solid ${props => props.theme.primary}20;
  margin: 4px 8px;
`

export default TextAreaInput
