import {C2cDashboardGrid, DashboardBox} from "../../styles/components/dashboard";
import CCounterChart from "../../components/chart/CCounterChart";
import Subscriber from "../../components/chart/Subscriber";
import C2cMessagesShow from "../../components/main/c2c-dashboard/C2cMessagesShow";
import C2cStats from "../../components/main/c2c-dashboard/C2cStats";


const C2cDashboard = () => {

    return (
        <C2cDashboardGrid>
            <DashboardBox area={'a'}>
                <C2cStats />
            </DashboardBox>
            <C2cMessagesShow area={'b'}/>
            <DashboardBox area={'c'}>
                <Subscriber />
            </DashboardBox>
            <DashboardBox area={'d'}>
                <CCounterChart />
            </DashboardBox>
        </C2cDashboardGrid>
    )
}

export default C2cDashboard
