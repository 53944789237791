import {forwardRef, useState, useImperativeHandle} from "react";
import Text from "../../../../core/utils/Text";
import {useQueryContext} from "../../../../core/contexts/query";
import {useFilterContext} from "../../../../core/contexts/filter";
import {Background, CFlex, CMargin, DText, Padding} from "../../../../styles/components/common/common-styles";
import Select from "../../../common/select/Select";


const SelectFilter = forwardRef((props, ref) => {

    const { setToast } = useQueryContext()
    const { filter, options, onClose } = props
    const { name, search } = filter
    const { filters: _filters, setFilters, cache } = useFilterContext()
    const filters = _filters[cache]


    const [ value, setValue ] = useState(filters[search] ? filters[search] : '')

    useImperativeHandle(ref, () => ({
        submitFilters: () => {
            if (value) {
                const newState = {...filters}
                newState[search] = value
                setFilters(newState)
                onClose()
            }else{
                setToast({
                    show: true, isError: true,
                    message: 'fill-inputs-error'
                })
            }
        }
    }))

    return (
        <CFlex style={{ padding: '8px 0' }}>
            <Background bg={'primaryBg'}>
                <DText main>
                    <Text tid={`filter-${name}`} />
                </DText>
                <CMargin margin={'8px'} />
                <Padding padding={'10px'}>
                    <Select
                        options={options}
                        value={value}
                        onValueChange={idx => setValue(options[idx])}
                        placeHolder={`select`}
                        width={'180px'}
                        height={'42px'}
                        size={'small'}
                        fontSize={'s'}
                    />
                </Padding>
            </Background>
        </CFlex>
    )
})


export default SelectFilter
