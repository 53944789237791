import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {MultiSelectOptionsWrapper, ChatOption} from "../../../styles/components/common/select-styles";
import ChatPhoto from "../utils/ChatPhoto";


const ChatSelectItems = ({
    options,
    selected,
    onOptionClicked
}) => {


    return (

        <MultiSelectOptionsWrapper>
            {options.map((chat, idx) => (
                <ChatOption
                    active={selected?.includes(chat.address)}
                    onClick={() => onOptionClicked(chat)}
                    type={'chat'}
                >
                    <ChatPhoto
                        chat={chat}
                        idx={idx}
                    />
                    <CFlex fh align={'flex-start'} style={{ backgroundColor: 'transparent' }}>
                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'title'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {chat.title}
                            </DText>
                        </Flex>

                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'address'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {chat.address}
                            </DText>
                        </Flex>
                    </CFlex>

                    <CFlex fh align={'flex-end'} style={{ backgroundColor: 'transparent' }}>
                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'operations'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {chat.operations?.length}
                            </DText>
                        </Flex>

                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'normalizers'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {chat.normalizers?.length}
                            </DText>
                        </Flex>
                    </CFlex>
                </ChatOption>
            ))}
        </MultiSelectOptionsWrapper>
    )

}


export default ChatSelectItems
