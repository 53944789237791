import { CFlex, DText, Flex, Padding } from "../../../styles/components/common/common-styles";
import DetailMiniView from "./DetailMiniView";
import { ReactComponent as NoDetailData } from "../../../assets/illustrations/no-detail-data.svg";
import Text from "../../../core/utils/Text";
import { ScaleLoader } from "react-spinners";
import { useEffect, useState } from "react";


const DetailContent = ({
    data,
    photo,
    title,
    type,
    noLink,
    padding = '10px 0 0 0'
}) => {

    const [detailList, setDetailList] = useState([])
    useEffect(() => {
        if (data) {
            const newList = []
            for (let i = 0; i < data.length; i++) {
                const item = data[i]
                const _id = item._id
                const index = newList.findIndex(x => x._id === _id)
                if (index > -1) {
                    newList[index] = { ...newList[index], count: newList[index].count + 1 }
                } else {
                    newList.push({
                        _id,
                        item,
                        count: 1
                    })
                }
            }
            setDetailList(newList)
        }
    }, [data])

    return (
        <Flex fw wrap justify={'flex-start'}>
            <Padding padding={padding}>
                {detailList?.map((detail, idx) => {
                    const item = detail.item
                    return (
                        <DetailMiniView
                            count={detail.count}
                            chat={{
                                address: item[photo],
                                title: item[title],
                                hasPhoto: item.hasPhoto
                            }}
                            link={type}
                            noLink={noLink}
                            idx={idx}
                            _id={item._id}
                        />
                    )
                })}
                {data?.length === 0 &&
                    <CFlex fw height={'200px'}>
                        <NoDetailData
                            width={'140px'}
                            height={'140px'}
                        />
                        <DText main margin={'10px 0'}>
                            <Text tid={`no-${type}`} />
                        </DText>
                    </CFlex>
                }
                {!data &&
                    <Flex fw height={'200px'}>
                        <ScaleLoader
                            color={'#3B6978'}
                            height={24}
                            width={3}
                        />
                    </Flex>
                }


            </Padding>
        </Flex>
    )
}

export default DetailContent
