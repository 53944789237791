import {useState} from "react";
import {
    AddIcon,
    CFlex, DeleteIcon,
    Flex,
    IconWrapper,
    Padding,
    UpdateIcon
} from "../../../../styles/components/common/common-styles";
import {AddButtonWrapper, PermTagWrapper} from "../../../../styles/components/setting";
import Text from "../../../../core/utils/Text";
import FilterLayout from "../../../layouts/filter/FilterLayout";
import {useDeleteRole, useIndexRoles} from "../../../../core/services/react-query/setting";
import {CacheKeys, roleFilterOptions} from "../../../../core/constants/filter";
import TableLayout from "../../../layouts/main/TableLayout";
import {Column, Row} from "../../../../styles/layouts/table-styles";
import ModalLayout from "../../../layouts/main/ModalLayout";
import CreateRoleModal from "../../../modals/setting/CreateRoleModal";
import {getRandomColor} from "../../../../core/utils/theme";
import DeleteModal from "../../../modals/DeleteModal";


const Roles = () => {

    const { mutate: deleteRole } = useDeleteRole()

    const initial = { item: null, open: false }
    const [createModal, setCreateModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(initial)
    const [deleteModal, setDeleteModal] = useState(initial)

    const openCreateModal = () => setCreateModal(true)
    const openUpdateModal = (item) => setUpdateModal({item, open: true})

    const closeModal = () => {
        if (createModal) setCreateModal(false)
        if (updateModal.open) setUpdateModal(initial)
        if (deleteModal.open) setDeleteModal(initial)
    }

    const [roles, setRoles] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setRoles(res)

    const onUpdateClicked = (item) => openUpdateModal(item)

    const onDeleteClicked = (item) => setDeleteModal({ open: true, item: item })

    const onDeleteRole = () => {
        deleteRole(deleteModal.item?._id)
        closeModal()
    }

    return (
        <>
            <CFlex fw>
                <Padding padding={'20px'}>
                    <Flex fw justify={'flex-end'}>
                        <AddButtonWrapper onClick={openCreateModal}>
                            <Flex fw fh>
                                <AddIcon size={18} />
                                <Text tid={'create-role'} />
                            </Flex>
                        </AddButtonWrapper>
                    </Flex>


                    <FilterLayout
                        query={useIndexRoles}
                        cache={CacheKeys.ROLES}
                        onQuerySuccess={onQuerySuccess}
                        options={roleFilterOptions}
                    >
                        <TableLayout
                            headers={headers}
                            cs={cs}
                            data={roles}
                            minWidth={1}
                        >
                            {roles?.data?.data?.map((item, idx) => (
                                <Row cs={cs} index={idx}>
                                    <Column>
                                        {idx + 1}
                                    </Column>
                                    <Column>
                                        {item.name}
                                    </Column>
                                    <Column>
                                        {item.workspace}
                                    </Column>
                                    <Column>
                                        <Flex fw wrap>
                                            {Object.keys(item.permissions).map((key, index) => {
                                                return (
                                                    <PermTagWrapper active={item.permissions[key]} color={getRandomColor(index)}>
                                                        <Flex fw fh>
                                                            <Text tid={key} />
                                                        </Flex>
                                                    </PermTagWrapper>
                                                )
                                            })}
                                        </Flex>
                                    </Column>
                                    <Column more>
                                        <IconWrapper
                                            onClick={() => onUpdateClicked(item)}
                                        >
                                            <UpdateIcon size={22} />
                                        </IconWrapper>
                                        <IconWrapper
                                            onClick={() => onDeleteClicked(item)}
                                        >
                                            <DeleteIcon size={22} />
                                        </IconWrapper>
                                    </Column>
                                </Row>
                            ))}
                        </TableLayout>
                    </FilterLayout>
                </Padding>
            </CFlex>

            {(createModal || updateModal.open) ?
                <ModalLayout
                    width={'520px'}
                    open={createModal || updateModal.open}
                    onClose={closeModal}
                >
                    <CreateRoleModal
                        onClose={closeModal}
                        details={updateModal}
                    />
                </ModalLayout>
                :
                null
            }
            {deleteModal.open ?
                <ModalLayout
                    width={'480px'}
                    open={deleteModal.open}
                    onClose={closeModal}
                >
                    <DeleteModal
                        type={'role'}
                        onCancel={closeModal}
                        onSubmit={onDeleteRole}
                    />
                </ModalLayout>
                :
                null
            }
        </>
    )
}

const cs = '5% 10% 10% 65% 10%'
const headers = [
    'name', 'workspace', 'permissions'
]

export default Roles
