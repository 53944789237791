import { COBody, COWrapper, OperationSVG } from "../../../styles/components/operation/operation-styles";
import { fadeVariants } from "../../../core/utils/theme";
import { AnimationWrapper, Background, CFlex, CMargin, DText, Flex, Padding } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Input from "../../common/input/Input";
import Select from "../../common/select/Select";
import { AnimatePresence } from "framer-motion";
import MultiSelect from "../../common/select/MultiSelect";
import ActionButton from "../../common/buttons/ActionButton";
import ButtonLoader from "../../common/loading/ButtonLoader";
import { useQueryContext } from "../../../core/contexts/query";
import { useSimsQuery } from "../../../core/services/react-query/sim";
import { useEffect, useState } from "react";
import useOperationalSims from "../../../core/hooks/main/sim/useOperationalSims";
import { useSimGroupsQuery } from "../../../core/services/react-query/group";
import { useCreateNormalizer } from "../../../core/services/react-query/normalizer";
import { CREATE_NORMALIZER_TYPE } from "../../../core/constants/constants";
import NormalizerMessages from "./NormalizerMessages";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../core/constants/routes";
import { useAclContext } from "../../../core/contexts/acl";
import { useMainContext } from "../../../core/contexts/main";
import CheckBox from "../../common/input/CheckBox";


const CreateNormalizer = () => {

    const { permissions } = useAclContext()
    const { setNeedPackage } = useMainContext()
    const sender = (useLocation())?.state?.sender

    const { setToast } = useQueryContext()
    const navigate = useNavigate()

    const { mutate: createNormalizer, isLoading: creatingNormalizer, isSuccess: createSuccess } = useCreateNormalizer()

    const [simOptions, setSimOptions] = useState([])
    const { simsList } = useOperationalSims()
    useEffect(() => {
        if (simsList) setSimOptions(simsList.map(item => item.username))
    }, [simsList])

    const [selectedSim, setSelectedSim] = useState(null)

    const initialState = {
        name: '',
        sim_id: '',
        groups: [],
        messages: [],
        delay: 0
    }
    const [inputData, setInputData] = useState(initialState)
    const [valid, setValid] = useState(false)

    /**
     * sim groups
     */
    const { data: groups, refetch: getSimGroups } = useSimGroupsQuery(selectedSim?._id)

    /**
     * validate all inputs for api call
     */
    useEffect(() => {
        const valid =
            inputData.name &&
            inputData.sim_id &&
            inputData.groups?.length &&
            inputData.messages?.length

        setValid(valid)
    }, [inputData])

    /**
     * on sim select
     */
    useEffect(() => {
        if (selectedSim) {
            getSimGroups()
        }
    }, [getSimGroups, selectedSim])

    const onInputValueChange = (value, type) => {
        switch (type) {
            case CREATE_NORMALIZER_TYPE.NAME:
                setInputData(state => ({ ...state, name: value }))
                break
            case CREATE_NORMALIZER_TYPE.SIM_ID:
                const simName = simOptions[value]
                setInputData(state => ({ ...state, sim_id: simName }))
                setSelectedSim(simsList?.find(sim => sim.username === simName))
                break
            case CREATE_NORMALIZER_TYPE.DELAY:
                setInputData(state => ({ ...state, delay: value }))
                break
            default:
                break
        }
    }

    const onGroupsChange = (selectedOptions) => {
        setInputData(state => ({ ...state, groups: selectedOptions }))
    }

    const onMessagesChange = (messages) => {
        setInputData(state => ({ ...state, messages }))
    }

    const onSubmitClicked = () => {
        if (valid) {
            if ((sender && permissions?.sender) || permissions?.normalizer) {
                const payload = { ...inputData }
                payload.sim_id = selectedSim._id
                payload.groups = inputData.groups.map(x => x._id)
                createNormalizer(payload)
            } else {
                setNeedPackage(true)
            }
        }
        else setToast({
            isError: true,
            show: true, message: 'fill-inputs-error'
        })
    }

    useEffect(() => {
        if (createSuccess) {
            if (sender) navigate(routes.sender)
            else navigate(routes.normalizer)
        }
    }, [createSuccess, navigate, sender])

    // send post as message
    const [isPost, setIsPost] = useState(false)

    return (
        <>
            <COWrapper>
                <COBody variants={fadeVariants} animate='in' exit='out' initial='out'>
                    <CFlex fw>
                        <Flex fw justify='flex-start'>
                            <DText fontSize='sb' primary>
                                <Text tid={`add-${sender ? 'sender' : 'normalizer'}`} />
                            </DText>
                        </Flex>

                        <CMargin margin='20px' />
                        <Input
                            label={CREATE_NORMALIZER_TYPE.NAME}
                            value={inputData.name}
                            onInputChange={(e) => onInputValueChange(e, CREATE_NORMALIZER_TYPE.NAME)}
                        />

                        <Padding padding='4px'>
                            <Select
                                width={'100%'}
                                value={inputData.sim_id}
                                placeHolder={'choose-sim'}
                                options={simOptions}
                                onValueChange={(idx) => onInputValueChange(idx, CREATE_NORMALIZER_TYPE.SIM_ID)}
                            />
                        </Padding>

                        <AnimatePresence exitBeforeEnter>
                            {selectedSim &&
                                <AnimationWrapper
                                    initial={'out'}
                                    animate={'in'}
                                    exit={'out'}
                                    variants={fadeVariants}
                                >
                                    <CMargin margin={'20px'} />
                                    <Padding padding='4px'>
                                        <MultiSelect
                                            values={inputData.groups}
                                            onValuesChange={onGroupsChange}
                                            placeHolder='select-groups'
                                            options={groups || []}
                                            type='chat'
                                            param='address'
                                        />
                                    </Padding>

                                    {sender ?
                                        <>
                                            <CMargin margin={'20px'} />
                                            <Background bg={'primaryBg'}>
                                                <Input
                                                    label={`${CREATE_NORMALIZER_TYPE.DELAY}-seconds`}
                                                    value={inputData.delay}
                                                    onInputChange={(e) => onInputValueChange(e, CREATE_NORMALIZER_TYPE.DELAY)}
                                                    code
                                                />
                                            </Background>
                                        </>
                                        : null}

                                    {sender ?
                                        <Flex justify={'flex-start'} style={{ gap: 8 }}>
                                            <CheckBox active={isPost} onClick={() => setIsPost(state => !state)} />
                                            <DText main>
                                                <Text tid={"post-as-message"} />
                                            </DText>
                                        </Flex>
                                        : null}

                                    <CMargin margin={'20px'} />
                                    <Padding padding={'4px'}>
                                        <NormalizerMessages
                                            messages={inputData.messages}
                                            onMessagesChange={onMessagesChange}
                                            sender={sender}
                                            isPost={isPost}
                                        />
                                    </Padding>
                                    <CMargin margin={'20px'} />


                                    <ActionButton
                                        active={valid}
                                        onClick={onSubmitClicked}
                                    >
                                        <ButtonLoader
                                            tid='submit'
                                            loading={creatingNormalizer}
                                        />
                                    </ActionButton>
                                </AnimationWrapper>
                            }
                        </AnimatePresence>

                    </CFlex>
                </COBody>
                <OperationSVG />
            </COWrapper>
        </>
    )
}


export default CreateNormalizer
