import {QueryClientProvider} from 'react-query'
import {useQueryContext} from "./core/contexts/query";
import Index from "./pages";
import {BrowserRouter as Router} from 'react-router-dom';
import './i18n'
import { Suspense } from "react";
import GlobalLoading from "./components/common/loading/GlobalLoading";


function App() {

    const {queryClient} = useQueryContext()

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Suspense fallback={<GlobalLoading />}>
                    <Index />
                </Suspense>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
