import styled from 'styled-components'
import {motion} from "framer-motion";
import { MdCheck, MdAddToQueue } from 'react-icons/md'
import {Flex} from "../common/common-styles";


const CategoryWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 100px;
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
`

const CategoryCard = styled(motion.div)`
  border-radius: 12px;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
  background-color: ${props => props.theme.mainBg};
  min-height: 320px;
  width: 100%;
  margin-top: 4px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.color}20;
  padding: 25px;
`

const CategoryChip = styled.div`
  border-radius: 12px;
  border: 1px solid ${props => props.theme.color}20;
  background-color: ${props => props.theme.primaryBg};
  box-shadow: 0 0 1px ${props => props.theme.shadow};
  padding: 8px 8px 8px 16px;
  margin: 8px;
  display: flex;
  box-shadow: 2px 2px 4px ${props => props.theme.primary}20;
`

const ChipInput = styled.input`
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${props => props.theme.primary};
  min-width: 90px;
  font-size: 0.8rem;
  
  &::placeholder{
    color: ${props => props.theme.primary};
  }
`


const CheckIcon = styled(MdCheck)`
  color: ${props => props.theme.mainGreen};
  cursor: pointer;
  margin: 0 5px;
`

const CategoryHeader = styled(Flex)`
  width: 100%;
  height: 52px;
  border-radius: 6px;
  justify-content: space-between;
  transition: all 0.3s;
  background-color: ${props => props.active ? `${props.theme.active}50` : props.theme.mainBg};
  border: 1px solid ${props => props.theme.color}20;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
  padding: 4px 20px;
`



const CategoryAdd = styled(MdAddToQueue)`
  color: ${props => props.theme.primary};
  cursor: pointer;
  margin: 0 10px;
`




export {
    CategoryWrapper,
    CategoryCard,
    CategoryChip,
    ChipInput,
    CheckIcon,
    CategoryHeader,
    CategoryAdd
}
