
export const initialAcl = {
    sim: false,
    group: false,
    channel: false,
    c2c: false,
    operation: false,
    normalizer: false,
    post: false,
    panel: false,
    c2c_panel: false,
    setting: false,
    user: false,
    category: false,
    moderator: false,
    sender: false,
    manager: false,
    members: false
}

export const superAdmin = {
    sim: true,
    group: true,
    channel: true,
    c2c: true,
    c2c_panel: true,
    operation: true,
    normalizer: true,
    post: true,
    panel: true,
    setting: true,
    user: true,
    category: true,
    moderator: true,
    sender: true,
    workspace: true,
    manager: true,
    logs: true,
    jobs: true,
    messages: true,
    members: true
}
