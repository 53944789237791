import {DelayTag, DelayWrapper} from "../../../styles/components/operation/operation-styles";
import {CMargin, Decoration, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {useState} from "react";
import ModalLayout from "../../layouts/main/ModalLayout";
import UpdateDelayModal from "../../modals/UpdateDelayModal";


const OperationDelay = ({
    data,
    onSubmitChanges,
    edit
}) => {

    const { delay } = data
    const [editModal, setEditModal] = useState(false)
    const openEditModal = () => setEditModal(true)
    const exitEditModal = () => setEditModal(false)

    return (
        <>
            {edit ?
                <Flex fw justify={'flex-start'} style={{ marginBottom: '8px' }}>
                    <DText main>
                        <Text tid={'delay'} /> :
                    </DText>
                </Flex>
                :
                <Flex style={{ marginBottom: '8px' }}>
                    <Decoration />
                    <DText main>
                        <Text tid={'delay-note'} />
                    </DText>
                </Flex>
            }

            <DelayWrapper onClick={openEditModal}>
                <Flex fw fh justify={'flex-start'} wrap>
                    <DelayTag>
                        <Flex>
                            <DText main fontSize={'s'}>
                                <Text tid={'delay-type'} /> :
                            </DText>
                            <DText primary margin={'0 4px'} fontSize={'s'}>
                                {delay.mode}
                            </DText>
                        </Flex>
                    </DelayTag>
                    <DelayTag>
                        <Flex>
                            <DText main fontSize={'s'}>
                                <Text tid={'delay-time'} /> :
                            </DText>
                            <DText primary margin={'0 4px'} fontSize={'s'} style={{ direction: 'ltr' }}>
                                {delay.mode === 'period' ?
                                    `${delay.begin}-${delay.end} mins`
                                    :
                                    `${delay.begin}-${delay.end} + ${delay.delay} mins`
                                }
                            </DText>
                        </Flex>
                    </DelayTag>

                    {delay.mode === 'slow-mode' &&
                        <DelayTag>
                            <Flex>
                                <DText main fontSize={'s'}>
                                    <Text tid={'delay-day-limit'} /> :
                                </DText>
                                <DText primary margin={'0 4px'} fontSize={'s'} style={{ direction: 'ltr' }}>
                                    {`${delay.min}-${delay.max}`}
                                </DText>
                            </Flex>
                        </DelayTag>
                    }
                </Flex>
            </DelayWrapper>

            <ModalLayout
                open={editModal}
                onClose={exitEditModal}
                width={'580px'}
            >
                <UpdateDelayModal
                    onClose={exitEditModal}
                    onSubmit={onSubmitChanges}
                    data={delay}
                />
            </ModalLayout>
        </>
    )
}

export default OperationDelay
