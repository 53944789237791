import {useMainContext} from "../../../core/contexts/main";
import {useNavigate} from "react-router-dom";
import {DText, Flex, Padding} from "../../../styles/components/common/common-styles";
import NavigatorItem from "./NavigatorItem";
import {DetailsLtr, DetailsRtl} from "../../../styles/layouts/table-styles";
import styled, {css} from "styled-components";
import {useCrumbs} from "../../../core/hooks/layout/useCrumbs";


const Navigator = () => {

    const {crumbs, getCrumbStatus, crumbIcons} = useCrumbs()
    const { main: { lang, sidebar } } = useMainContext()
    const navigate = useNavigate()

    const NO_CRUMB = crumbs[0] === 'Register-signin' || crumbs[0] === 'Rayna' || crumbs[0] === 'Register'
    if (crumbs.length && NO_CRUMB) return <></>

    const LAST_ITEM = crumbs.length - 1

    const CrumbIcon = () => {
        const pathIdx = crumbs.length > 1 ? 1 : 0
        const { path } = getCrumbStatus(pathIdx)
        const Icon = crumbIcons[path]
        return (
            <>
                {Icon && <Icon style={{ margin: '5px 8px 0 8px' }} size={20}/>}
            </>
        )
    }

    const onCrumbClicked = (idx) => {
        const { path, valid } = getCrumbStatus(idx)
        valid && navigate(path)
    }

    return (
        <NavWrapper open={sidebar}>
            <DText main>
                <CrumbIcon />
            </DText>
            {crumbs.map((crumb, idx) => (
                    <Flex
                        key={crumb}
                    >
                        <NavigatorItem
                            idx={idx}
                            crumb={crumb}
                            onCrumbClicked={onCrumbClicked}
                        />
                        {idx !== LAST_ITEM &&
                            <Padding padding={'0 6px'}>
                                {lang === 'en' ?
                                    <DetailsLtr size={16}/>
                                    :
                                    <DetailsRtl size={16}/>
                                }
                            </Padding>
                        }
                    </Flex>
            ))}
        </NavWrapper>
    )
}


export const NavWrapper = styled.div`
  position: absolute;
  top: 30px;
  transition: all 0.3s;
  ${props => props.theme.english && css`
    left: ${props => props.open ? '230px' : '100px'};
  `};
  ${props => !props.theme.english && css`
    right: ${props => props.open ? '230px' : '100px'};
  `};
  display: flex;
  direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
  flex-wrap: wrap;
  
  @media screen and (max-width: 768px) {
    left: 5px;
    right: 5px;
  };
`

export default Navigator
