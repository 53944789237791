import {Background, CFlex, CMargin, Flex} from "../../styles/components/common/common-styles";
import Input from "../../components/common/input/Input";
import {useMemo, useState} from "react";
import ActionButton from "../../components/common/buttons/ActionButton";
import Text from "../../core/utils/Text";
import {validEmailRegex} from "../../core/utils/validation";
import {useLotpRegisterUser} from "../../core/services/react-query/auth";
import ButtonLoader from "../../components/common/loading/ButtonLoader";
import {useQueryContext} from "../../core/contexts/query";


const Register = () => {

    const initial = {
        workspace: '',
        username: '',
        email: '',
        password: '',
        confirm: ''
    }
    const [data, setData] = useState(initial)
    const onDataChange = (v, k) => {
        setData(state => ({
            ...state,
            [k]: v
        }))
    }

    const valid = useMemo(() => {
        return !!data.workspace && !!data.username && !!data.email && !!data.password
    }, [data])

    const { mutate: register, isLoading } = useLotpRegisterUser()
    const { setToast } = useQueryContext()
    const onSubmit = () => {
        if (isLoading) return
        if (!valid) setToast({ message: 'fill-inputs-error', show: true, isError: true })
        else {
            register(data)
        }
    }

    return (
        <Flex fw height={'calc(100vh - 80px)'}>
            <CFlex width={'420px'}>
                <Background bg={'mainBg'} style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 0 4px #00000020' }}>
                    <Input
                        label={'workspace'}
                        value={data.workspace}
                        onInputChange={(v) => onDataChange(v, 'workspace')}
                        valid={data.workspace.length >= 5}
                        error={'workspace-error'}
                    />
                    <Input
                        label={'username'}
                        value={data.username}
                        onInputChange={(v) => onDataChange(v, 'username')}
                        valid={data.username.length >= 3}
                        error={'username-error'}
                    />
                    <Input
                        label={'email'}
                        value={data.email}
                        onInputChange={(v) => onDataChange(v, 'email')}
                        valid={validEmailRegex.test(data.email)}
                        error={'email-error'}
                    />
                    <Input
                        label={'password'}
                        value={data.password}
                        onInputChange={(v) => onDataChange(v, 'password')}
                        valid={data.password?.length >= 8}
                        error={'password-error'}
                        password
                    />
                    <Input
                        label={'confirm-password'}
                        value={data.confirm}
                        onInputChange={(v) => onDataChange(v, 'confirm')}
                        valid={data.password === data.confirm}
                        error={'confirm-password-error'}
                        password
                    />
                    <CMargin margin={'10px'} />
                    <ActionButton active={valid} onClick={onSubmit}>
                        <ButtonLoader
                            tid={'register'}
                            loading={isLoading}
                        />
                    </ActionButton>
                </Background>
            </CFlex>
        </Flex>
    )
}

export default Register
