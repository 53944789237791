export const routes = {
    home: '/',
    landing: '/rayna',
    profile: '/profile',
    dashboard: '/dashboard',
    c2cDashboard: '/c2c-dashboard',
    categories: '/categories',
    sims: '/sims',
    createSim: '/sims/create-sim',
    simDetails: '/sims/:id',
    groups: '/groups',
    groupDetails: '/groups/:id',
    channel: '/channels',
    channelDetails: '/channels/:id',
    operations: '/operations',
    operationDetails: '/operations/:id',
    createOperation: '/operations/create-operation',
    normalizer: '/normalizers',
    normalizerDetails: '/normalizers/:id',
    createNormalizer: '/normalizers/create-normalizer',
    sender: '/sender',
    senderDetails: '/sender/:id',
    createSender: '/sender/create-sender',
    post: '/post-forwarder',
    createPost: '/post-forwarder/create-post',
    postDetails: '/post-forwarder/:id',
    c2c: '/c2c',
    createC2c: '/c2c/create-c2c',
    c2cDetails: '/c2c/:id',
    moderator: '/moderator',
    logs: '/logs',
    jobs: '/jobs',
    manager: '/manager',
    setting: '/setting',
    createWorkspace: '/setting/create-workspace',
    createPackage: '/setting/create-package',
    login: '/login',
    register: '/register',
    messages: '/messages',
    members: '/members'
}
