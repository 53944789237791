import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { useGetNormalizers } from "../../core/services/react-query/normalizer";
import { CacheKeys, normalizerFilterOptions } from "../../core/constants/filter";
import NormalizerComp from "../../components/main/normalizer/NormalizerComp";
import CreateButton from "../../components/common/buttons/CreateButton";
import { routes } from "../../core/constants/routes";
import { Flex, IconWrapper } from "../../styles/components/common/common-styles";
import Tooltip from "../../components/common/utils/Tooltip";
import PostStatusMap from "../../components/main/post/PostStatusMap";
import { InfoIcon } from "../../styles/components/dashboard";
import { useMainContext } from "../../core/contexts/main";
import { statusMap } from "../../components/main/normalizer/NormalizerList";

const Sender = () => {

    const { main: { lang } } = useMainContext()
    const navigate = useNavigate()
    const navigateToCreateSender = () => {
        navigate(routes.createSender, { state: { sender: true } })
    }

    const [senders, setSenders] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setSenders(res)

    return (
        <>
            <CardLayout>
                <Flex fw justify={'flex-end'} style={{ padding: '0 20px' }}>
                    <Tooltip
                        placement={lang === 'en' ? 'left' : 'right'}
                        content={<PostStatusMap statusMap={statusMap} />}
                    >
                        <IconWrapper>
                            <InfoIcon size={18} />
                        </IconWrapper>
                    </Tooltip>
                </Flex>
                <FilterLayout
                    query={useGetNormalizers}
                    cache={CacheKeys.SENDER}
                    options={normalizerFilterOptions}
                    onQuerySuccess={onQuerySuccess}
                    extra={{ static: true }}
                >
                    <NormalizerComp data={senders} sender />
                </FilterLayout>
            </CardLayout>
            <CreateButton
                onClick={navigateToCreateSender}
                tooltip='create-sender'
            />
        </>
    )
}

export default Sender
