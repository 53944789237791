import styled, {css} from "styled-components";
import {useMemo} from "react";


const CreditBar = ({ limit= 0, count= 0 }) => {

    const noLimit = limit === 'noLimit'
    const width = useMemo(() => {
        if (noLimit) return 0
        return ((count / limit) * 100) || 0
    }, [limit, count])

    return (
        <Wrapper>
            <Body width={width} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
  border-radius: 8px;
  background-color: ${props => props.theme.mainBg};
  width: 220px;
  height: 16px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
  position: relative;
`

const Body = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${props => props.width}%;
  height: 100%;
  background-color: ${props => props.theme.mainGreen};
  ${props => (props.width > 50 && props.width < 90) && css`
    background-image: linear-gradient(to right, ${props => props.theme.mainGreen}, ${props => props.theme.mainOrange});
  `};

  ${props => (props.width >= 90) && css`
    background-image: linear-gradient(to right, ${props => props.theme.mainOrange}, #f60244);
  `};
  
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  ${props => props.width === 100 && css`
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #f60244;
    background-image: unset;
  `};
`

export default CreditBar
