import {STATIC_URL} from "../../../core/constants/constants";
import styled from "styled-components";
import {DText} from "../../../styles/components/common/common-styles";
import {getRandomColor} from "../../../core/utils/theme";


const ChatPhoto = ({
    chat,
    idx = 0,
}) => {

    return (
        <PhotoWrapper
            color={getRandomColor(idx)}
        >
            {(chat?.address && chat?.hasPhoto) ?
                <Img
                    src={STATIC_URL + `assets/${chat.address}.png?${new Date()}`}
                    alt=' '
                    crossOrigin="anonymous"
                />
                :
                <DText color={'#1e2026'} fontSize={'b'}>
                    {chat?.title?.substring(0, 1)?.toUpperCase()}
                </DText>
            }

        </PhotoWrapper>
    )
}


const PhotoWrapper = styled.div`
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color};
  width: 42px;
  height: 42px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
`

const Img = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
`


export default ChatPhoto
