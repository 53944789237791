import { useTranslation } from 'react-i18next'

const Text = ({ tid, className = '', ...rest }) => {
	const { t } = useTranslation()
	let result = tid
	if (typeof tid === 'string') result = t(tid?.toLowerCase())
	return (
		<span className={`inline ${className}`} {...rest}>
			{result ? result : tid}
		</span>
	)
}

export default Text
