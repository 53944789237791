import {useLocation} from "react-router-dom";
import {
    MdSpaceDashboard, MdSimCard, MdDashboard
} from "react-icons/md";
import {HiUserGroup} from "react-icons/hi";
import {RiSendPlane2Fill} from "react-icons/ri";
import {FaGlobe, FaLayerGroup, FaTerminal, FaTypo3, FaUikit} from "react-icons/fa";
import {SiGoogletagmanager} from "react-icons/si";
import {GiBoomerangSun, GiTrade, GiZigArrow} from "react-icons/gi";
import {IoMdSettings} from "react-icons/io"
import {CgProfile} from "react-icons/cg";



export const useCrumbs = () => {

    const location = useLocation()
    const path = location?.pathname

    const paths = path.split('/').slice(1, path.length - 1)

    const crumbs = paths.map(path => path[0]?.toUpperCase() + path.slice(1, path.length))

    const specialCrumbCases = [
        'Operations', 'Groups', 'Channels',
        'Normalizers', 'Sims', 'Post-forwarder',
        'Sender', 'C2c'
    ]

    const getCrumbStatus = (idx) => {
        let temp
        if (specialCrumbCases.includes(crumbs[0])) {
            temp = crumbs.slice(0, 1)
        }else {
            temp = crumbs.slice(0, idx + 1)
        }
        const _path = `/${temp.join('/')?.toLowerCase()}`
        return {
            path: _path,
            valid: idx !== crumbs.length - 1
        }
    }

    return {
        getCrumbStatus,
        crumbs,
        crumbIcons
    }
}



const crumbIcons = {
    '/profile': CgProfile,
    '/moderator': GiBoomerangSun,
    '/dashboard': MdSpaceDashboard,
    '/c2c-dashboard': MdDashboard,
    '/categories': FaTerminal,
    '/sims': MdSimCard,
    '/users': HiUserGroup,
    '/channels': FaLayerGroup,
    '/groups': FaUikit,
    '/operations': FaTypo3,
    '/normalizers': FaGlobe,
    '/sender': RiSendPlane2Fill,
    '/post-forwarder': GiZigArrow,
    '/c2c': GiTrade,
    '/manager': SiGoogletagmanager,
    '/setting': IoMdSettings
}
