import styled, {css} from "styled-components";


const SettingCheck = ({ checked }) => {

    return (
        <Wrapper checked={checked}>
            {checked &&
                <CircleBox />
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.mainBg};
  ${props => props.checked && css`
    border: 1px solid ${props => props.theme.mainOrange};
  `};
  border-radius: 50%;
`

const CircleBox = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${props => props.theme.mainOrange};
  border-radius: 50%;
`

export default SettingCheck
