import {DText, Flex} from "../../../styles/components/common/common-styles";
import {useEffect, useState} from "react";
import styled from "styled-components";
import Text from "../../../core/utils/Text";
import {deepCopy} from "../../../core/utils/common";


const TimePicker = ({
    time,
    onChange
}) => {

    const [data, setData] = useState({ hour: '', min: '' })
    useEffect(() => {
        if (time) {
            if (typeof time === 'string') {
                const sep = time.split(':')
                if (sep?.length > 1) {
                    setData({
                        hour: sep[0],
                        min: sep[1]
                    })
                }
            }
        }
    }, [time])

    const onTimeChange = (v, type) => {
        const newData = deepCopy(data)
        if (type === 'hour') {
            if (parseInt(v) < 24 || !v) {
                newData[type] = v
            }
        }else {
            if (parseInt(v) < 60 || !v) {
                newData[type] = v
            }
        }
        setData(newData)
    }

    useEffect(() => {
        if (data.hour && data.min) {
            onChange(`${data.hour}:${data.min}`)
        }else {
            onChange('')
        }
    }, [data])

    return (
        <Flex>
            <Flex>
                <DText main fontSize={'s'}>
                    <Text tid={'hour'} /> :
                </DText>
                <TimeInput
                    value={data.hour}
                    onChange={(e) => onTimeChange(e?.target?.value, 'hour')}
                    type={'number'}
                />
            </Flex>
            <Flex>
                <DText main fontSize={'s'}>
                    <Text tid={'minute'} /> :
                </DText>
                <TimeInput
                    value={data.min}
                    onChange={(e) => onTimeChange(e?.target?.value, 'min')}
                    type={'number'}
                />
            </Flex>
        </Flex>
    )
}

const TimeInput = styled.input`
  width: 32px;
  height: 28px;
  font-size: 0.7rem;
  color: ${props => props.theme.color};
  background-color: transparent;
  border: 1px solid ${props => props.theme.color}20;
  border-radius: 4px;
  margin: 0 5px;
  padding: 4px;

  &::-webkit-outer-spin-button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  };

  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  };
  
  -moz-appearance: textfield;
`


export default TimePicker
