import { useMainContext } from "../../../core/contexts/main";
import { Column, DateBadge, DetailsLtr, DetailsRtl, Row } from "../../../styles/layouts/table-styles";
import ChatPhoto from "../../common/utils/ChatPhoto";
import { CFlex, DeleteIcon, DText, IconWrapper, UpdateIcon } from "../../../styles/components/common/common-styles";
import { formatDate } from "../../../core/utils/common";
import useChatListOperations from "../../../core/hooks/components/useChatListOperations";
import ModalLayout from "../../layouts/main/ModalLayout";
import UpdateChatModal from "../chat/UpdateChatModal";
import DeleteChatModal from "../../modals/DeleteChatModal";
import { BsEyeFill } from 'react-icons/bs'
import { useState } from "react";
import ChannelAdminModal from "./ChannelAdminModal";
import Text from "../../../core/utils/Text";
import { Link } from "react-router-dom";
import Tooltip from "../../common/utils/Tooltip";
import { RiLoader3Line } from "react-icons/ri";
import { useUpdateAdminChannel } from "../../../core/services/react-query/channel";
import { ClipLoader } from "react-spinners";

let updatingChannelAdminIdx = -1
const ChannelList = ({
    channels,
    cs,
}) => {

    const { main: { lang } } = useMainContext()
    const initialAdminModal = { show: false, channel: null }
    const [adminModal, setAdminModal] = useState(initialAdminModal)

    const {
        onUpdateClicked,
        onDeleteClicked,
        updateModalOpen,
        deleteModalOpen,
        closeUpdateModal,
        selectedChat: selectedChannel,
        onUpdateSubmitted,
        closeDeleteModal,
        onDeleteSubmitted,
        chatIsUpdating,
        chatIsDeleting,
    } = useChatListOperations('channel')

    const { mutateAsync: updateAdminChannel } = useUpdateAdminChannel()
    const onUpdateAdminChannel = async (channel, idx) => {
        updatingChannelAdminIdx = idx
        try {
            await updateAdminChannel(channel?._id)
        } catch (_) { }
        updatingChannelAdminIdx = -1
    }

    return (
        <>
            {channels?.data?.map((channel, idx) => {

                let hasAdminSim = 'no-sim-set'
                if (channel.isAdmin) {
                    hasAdminSim = Object.values(channel.isAdmin).every(item => item)
                }
                const SHOW_MODAL = adminModal.show && adminModal.channel._id === channel._id

                return (
                    <Row key={channel.createdAt} cs={cs} index={idx}>
                        <Column style={{ alignItems: 'center' }}>
                            <div>
                                {idx + 1}
                            </div>
                            <ChatPhoto
                                chat={channel}
                                idx={idx}
                            />
                        </Column>
                        <Column>
                            <DText>
                                {channel.title}
                            </DText>
                        </Column>
                        <Column>
                            <DText>
                                {channel.address}
                            </DText>
                        </Column>
                        <Column>
                            <DText>
                                {channel.subscriber}
                            </DText>
                        </Column>
                        <Column>
                            <DText>
                                {channel.type}
                            </DText>
                        </Column>

                        <Column>
                            <DateBadge>
                                <CFlex>
                                    <DText primary>
                                        {formatDate(channel.updatedAt, 'date', lang)}
                                    </DText>
                                    <DText primary>
                                        {formatDate(channel.updatedAt, 'time', lang)}
                                    </DText>
                                </CFlex>
                            </DateBadge>
                        </Column>

                        <Column center>
                            <div
                                style={{ padding: '10px', cursor: 'pointer' }}
                                onMouseEnter={() => setAdminModal({ show: true, channel })}
                                onMouseLeave={() => setAdminModal(initialAdminModal)}
                            >
                                {hasAdminSim !== 'no-sim-set'
                                    ? (
                                        SHOW_MODAL ?
                                            <ChannelAdminModal channel={adminModal.channel} />
                                            :
                                            <BsEyeFill
                                                size={16}
                                                color={hasAdminSim ? '#1ce087' : '#e9106c'}
                                            />
                                    ) :
                                    <DText main>
                                        <Text tid={hasAdminSim} />
                                    </DText>
                                }
                            </div>

                        </Column>

                        <Column more>

                            {updatingChannelAdminIdx === idx ?
                                <IconWrapper>
                                    <ClipLoader size={14} color={'#ffc800'} />
                                </IconWrapper>
                                :
                                <Tooltip
                                    content={'update-sim-channel'}
                                >
                                    <IconWrapper onClick={() => onUpdateAdminChannel(channel, idx)}>
                                        <RiLoader3Line color={'#ffc800'} size={20} />
                                    </IconWrapper>
                                </Tooltip>
                            }

                            <IconWrapper
                                onClick={() => onUpdateClicked(channel)}
                            >
                                <UpdateIcon size={22} />
                            </IconWrapper>

                            <IconWrapper
                                onClick={() => onDeleteClicked(channel)}
                            >
                                <DeleteIcon size={22} />
                            </IconWrapper>

                            <Link to={`/channels/${channel._id}?type=channel`}>
                                <IconWrapper>
                                    {lang === 'en' ?
                                        <DetailsLtr size={24} />
                                        :
                                        <DetailsRtl size={24} />
                                    }
                                </IconWrapper>
                            </Link>

                        </Column>
                    </Row>
                )
            })}


            {/* update modal */}
            <ModalLayout
                open={updateModalOpen}
                onClose={closeUpdateModal}
                width='600px'
            >
                <UpdateChatModal
                    chat={selectedChannel}
                    type={'channels'}
                    onSubmit={onUpdateSubmitted}
                    loading={chatIsUpdating}
                />
            </ModalLayout>

            {/* delete modal */}
            <ModalLayout
                open={deleteModalOpen}
                onClose={closeDeleteModal}
                width='600px'
            >
                <DeleteChatModal
                    chat={selectedChannel}
                    onCancel={closeDeleteModal}
                    onSubmit={onDeleteSubmitted}
                    type='channel'
                />
            </ModalLayout>
        </>
    )
}

export default ChannelList
