import { useEffect, useState } from "react";
import useOperationalSims from "../../../../core/hooks/main/sim/useOperationalSims";
import { useSimGroupsQuery } from "../../../../core/services/react-query/group";
import DetailBox from "../../../common/utils/DetailBox";
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../../../styles/components/common/common-styles";
import Text from "../../../../core/utils/Text";
import Toggle from "../../../common/buttons/Toggle";
import Select from "../../../common/select/Select";
import Input from "../../../common/input/Input";
import MultiSelect from "../../../common/select/MultiSelect";
import NormalizerMessages from "../NormalizerMessages";
import { useGetCategoryType } from "../../../../core/services/react-query/category";


const NormalizerDetailEdit = ({
    sim,
    normalizer,
    onNormalizerDetailChange
}) => {

    const [simOptions, setSimOptions] = useState([])
    const { simsList } = useOperationalSims()
    useEffect(() => {
        if (simsList) setSimOptions(simsList.map(item => item.username))
    }, [simsList])

    const { data: categories } = useGetCategoryType('normalizer')

    const { data: groups, refetch: getSimGroups } = useSimGroupsQuery(sim?._id)

    useEffect(() => {
        if (sim) {
            getSimGroups()
        }
    }, [sim])

    const onGroupsChange = (groups) => {
        onNormalizerDetailChange(groups, 'groups')
    }

    const onMessagesChange = (messages) => {
        onNormalizerDetailChange(messages, 'messages')
    }

    return (
        <>
            <DetailBox>
                <Flex fw justify={'flex-end'}>
                    <DText main>
                        <Text tid={'status'} /> :
                    </DText>
                    <Toggle
                        style={{ margin: '0 5px' }}
                        active={normalizer?.active}
                        onChange={() => onNormalizerDetailChange(!normalizer?.active, 'active')}
                    />
                </Flex>
                <CFlex align={'flex-start'}>
                    <Flex>
                        <Decoration />
                        <DText main style={{ margin: '0 5px' }}>
                            <Text tid={'normalizer-sim-change-note'} />
                        </DText>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <DText main>
                        <Text tid={'sim'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <Select
                        options={simOptions}
                        value={sim?.username}
                        onValueChange={(idx) => onNormalizerDetailChange(simsList[idx], 'sim_id')}
                        width={'480px'}
                    />

                    <CMargin margin={'10px'} />
                    <Background bg={'mainBg'}>
                        <DText main>
                            <Text tid={'operation-name'} /> :
                        </DText>
                        <CMargin margin={'4px'} />
                        <Input
                            value={normalizer?.name}
                            onInputChange={(v) => onNormalizerDetailChange(v, 'name')}
                            width={'480px'}
                            padding={'0'}
                        />
                    </Background>

                    <DText main>
                        <Text tid={'groups'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <MultiSelect
                        values={normalizer?.groups}
                        onValuesChange={onGroupsChange}
                        placeHolder='select-groups'
                        options={groups || []}
                        width={'480px'}
                        type='chat'
                        param='address'
                    />

                    <CMargin margin={'10px'} />
                    <DText main>
                        <Text tid={'categories'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <MultiSelect
                        values={normalizer.categories}
                        options={categories || []}
                        type={'category'}
                        param={'name'}
                        width={'480px'}
                        placeHolder={`choose-categories`}
                        onValuesChange={(selectedOptions) => onNormalizerDetailChange(selectedOptions, 'categories')}
                    />
                    <CMargin margin={'10px'} />
                    <DText main>
                        <Text tid={'messages'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <NormalizerMessages
                        messages={normalizer?.messages}
                        onMessagesChange={onMessagesChange}
                    />
                </CFlex>
            </DetailBox>
        </>
    )
}

export default NormalizerDetailEdit
