import {useLocation, useParams} from "react-router-dom";
import {useGetAChannel} from "../../../core/services/react-query/channel";
import DetailBox from "../../common/utils/DetailBox";
import {DetailLayoutWrapper} from "../../../styles/layouts/main-styles";
import {CFlex, CMargin, DText, Flex} from "../../../styles/components/common/common-styles";
import ChatPhoto from "../../common/utils/ChatPhoto";
import Text from "../../../core/utils/Text";
import {formatNumber} from "../../../core/utils/common";
import DetailContent from "./DetailContent";
import {useGetAGroup} from "../../../core/services/react-query/group";
import {useEffect} from "react";


const ChatDetails = () => {

    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const type = search.get('type')

    const query = type === 'channel' ? useGetAChannel : useGetAGroup

    const params = useParams()
    const { data: chat, refetch: getChat } = query(params.id)

    useEffect(() => {
        if (type === 'group') getChat()
    }, [])


    return (
        <DetailLayoutWrapper>
            <DetailBox>
                <Flex fw justify={'space-between'} wrap>
                    <Flex>
                        <ChatPhoto
                            chat={chat}
                        />
                        <DText main style={{ margin: '0 5px' }}>
                            {chat?.title}
                        </DText>
                    </Flex>

                    <CFlex width={'200px'}>
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'s'}>
                                <Text tid={'subscribers'} /> :
                            </DText>
                            <DText primary margin={'0 5px'} fontSize={'s'}>
                                {formatNumber(chat?.subscriber, true)}
                            </DText>
                        </Flex>
                        <CMargin margin={'3px'} />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'s'}>
                                <Text tid={`${type}-type`} /> :
                            </DText>
                            <DText primary margin={'0 5px'} fontSize={'s'}>
                                {chat?.type}
                            </DText>
                        </Flex>
                    </CFlex>

                    <a href={`https://t.me/${chat?.address}`} target={'_blank'}>
                        <DText color={'#3B6978'}>
                            {`https://t.me/${chat?.address}`}
                        </DText>
                    </a>
                </Flex>
            </DetailBox>

            <Flex fw justify={'space-between'} wrap align={'stretch'}>
                <DetailBox
                    width={'49%'}
                    title={'sims'}
                >
                    <DetailContent
                        data={chat?.sims}
                        title={'username'}
                        type={'sims'}
                    />
                </DetailBox>
                <DetailBox
                    width={'49%'}
                    title={'categories'}
                >

                    <DetailContent
                        data={chat?.categories}
                        title={'name'}
                        type={'categories'}
                        noLink
                    />
                </DetailBox>
            </Flex>

            <Flex fw justify={'space-between'} wrap align={'stretch'}>
                <DetailBox
                    width={'49%'}
                    title={'operations'}
                >
                    <DetailContent
                        data={chat?.operations}
                        title={'name'}
                        type={'operations'}
                    />
                </DetailBox>
                <DetailBox
                    width={'49%'}
                    title={type === 'channel' ? 'groups' : 'channels'}
                >
                    <DetailContent
                        data={type === 'channel' ? chat?.groups : chat?.channels}
                        photo={'address'}
                        title={'title'}
                        type={type === 'channel' ? 'groups' : 'channels'}
                    />
                </DetailBox>
            </Flex>
            {type === 'group' &&
                <DetailBox
                    width={'49%'}
                    title={'normalizers'}
                >
                    <DetailContent
                        data={chat?.normalizers}
                        title={'name'}
                        type={'normalizers'}
                    />
                </DetailBox>
            }

        </DetailLayoutWrapper>
    )

}

export default ChatDetails
