import {
    AddIcon,
    CFlex,
    DeleteIcon,
    Flex,
    IconWrapper,
    Padding, UpdateIcon
} from "../../../../styles/components/common/common-styles";
import {AddButtonWrapper} from "../../../../styles/components/setting";
import Text from "../../../../core/utils/Text";
import FilterLayout from "../../../layouts/filter/FilterLayout";
import {useDeleteUser, useGetUsers} from "../../../../core/services/react-query/setting";
import {CacheKeys, userFilterOptions} from "../../../../core/constants/filter";
import TableLayout from "../../../layouts/main/TableLayout";
import {Column, Row} from "../../../../styles/layouts/table-styles";
import {formatDate} from "../../../../core/utils/common";
import ModalLayout from "../../../layouts/main/ModalLayout";
import {useState} from "react";
import {useMainContext} from "../../../../core/contexts/main";
import CreateUserModal from "../../../modals/setting/CreateUserModal";
import DeleteModal from "../../../modals/DeleteModal";


const Users = () => {

    const { main: {lang} } = useMainContext()

    const initialModal = { show: false, user: null, type: '' }
    const [modal, setModal] = useState(initialModal)
    const closeModal = () => setModal(initialModal)
    const openModal = (id, type) => setModal({ show: true, user: id, type })

    const [users, setUsers] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setUsers(res)


    const { mutate: deleteUser } = useDeleteUser()
    const onDeleteSubmitted = () => {
        deleteUser(modal.user)
        closeModal()
    }


    return (
        <>
            <CFlex fw>
                <Padding padding={'20px'}>
                    <Flex fw justify={'flex-end'}>
                        <AddButtonWrapper onClick={() => openModal(null, 'create')}>
                            <Flex fw fh>
                                <AddIcon size={18} />
                                <Text tid={'register-user'} />
                            </Flex>
                        </AddButtonWrapper>
                    </Flex>


                    <FilterLayout
                        query={useGetUsers}
                        cache={CacheKeys.USER}
                        onQuerySuccess={onQuerySuccess}
                        options={userFilterOptions}
                    >
                        <TableLayout
                            headers={headers}
                            cs={cs}
                            data={users}
                            minWidth={1}
                        >
                            {users?.data?.data?.map((item, idx) => (
                                <Row cs={cs} index={idx}>
                                    <Column>
                                        {idx + 1}
                                    </Column>
                                    <Column>
                                        {item.username}
                                    </Column>
                                    <Column>
                                        {item.email}
                                    </Column>
                                    <Column>
                                        {item.workspace}
                                    </Column>
                                    <Column>
                                        {/* TODO: */}
                                        {item.role}
                                    </Column>
                                    <Column>
                                        {`${formatDate(item.createdAt, 'date', lang)}-${formatDate(item.createdAt, 'time', lang)}`}
                                    </Column>
                                    <Column more>
                                        {item.username !== 'superAdmin' &&
                                            <IconWrapper onClick={() => openModal(item, 'edit')}>
                                                <UpdateIcon size={18} />
                                            </IconWrapper>
                                        }
                                        <IconWrapper onClick={() => openModal(item._id, 'delete')}>
                                            <DeleteIcon size={18} />
                                        </IconWrapper>
                                    </Column>
                                </Row>
                            ))}
                        </TableLayout>
                    </FilterLayout>
                </Padding>
            </CFlex>

            <ModalLayout
                width={'480px'}
                open={modal.show && modal.type !== 'delete'}
                onClose={closeModal}
                portal={false}
            >
                <CreateUserModal
                    user={modal.user}
                    type={modal.type}
                    onClose={closeModal}
                />
            </ModalLayout>

            <ModalLayout
                open={modal.show && modal.type === 'delete'}
                onClose={closeModal}
                width={'580px'}
            >
                <DeleteModal
                    type={'user'}
                    onSubmit={onDeleteSubmitted}
                    onCancel={closeModal}
                />
            </ModalLayout>

        </>
    )
}

const cs = '5% 20% 20% 15% 15% 15% 10%'
const headers = [
    'username', 'email', 'workspace', 'role', 'date'
]


export default Users
