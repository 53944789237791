import jalaali from "jalaali-js";

export const formatDate = (date, type, lang) => {
    if (type === "time") {
        return new Date(date).toLocaleTimeString(lang);
    }
    return new Date(date).toLocaleDateString(lang);
};


export const formatNumber = (num, direct) => {
    if (!num) {
        return 0
    }

    const float = parseFloat(num)
    if (direct) return Math.round(float).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    if (float < 0.01) return parseFloat(float.toFixed(6))
    if (float < 1) return parseFloat(float.toFixed(2))
    if (float < 1000) return float.toFixed(1)
    return Math.round(float).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatSize = (size) => {
    if (size < 1000) return `${size}B`
    if (size < 1000000) return `${(size / 1000).toFixed(2)}KB`
    else return `${(size / 1000000).toFixed(2)}MB`
}

export const stringToNumber = (str) => {
    try {
        let numParts = str.split('.')
        numParts[0] = numParts[0].replaceAll(",", "")
        return numParts.join(".")
    } catch (err) { return null }
}

export const p2e = (str) => {
    return str.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
}

export const deepCopy = (obj) => {
    if (!obj) return null
    return JSON.parse(JSON.stringify(obj))
}

export function convertToFaDate(date) {
    let newDate = new Date(date)
    newDate = new Date(p2e(newDate.toLocaleDateString('fa-IR')))
    newDate.setUTCHours(24, 0, 0, 0)
    return newDate
}

export function convertToEnDate(date) {
    let newDate = new Date(date.getTime())
    const jr = jalaali.toGregorian(newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate())
    newDate = new Date(`${jr.gy}-${jr.gm}-${jr.gd}`)
    newDate.setUTCHours(24, 0, 0, 0)
    return newDate
}

export const fileDownloader = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}
