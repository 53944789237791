import styled, {css} from "styled-components";
import {CFlex, Flex} from "../common/common-styles";


export const NormalizerMessageWrapper = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}60;
`

export const PickerContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}20;
  padding: 10px;
`

export const Tag = styled.div`
  padding: 4px 8px;
  font-size: 0.7rem;
  border-radius: 4px;
  background-color: ${props => props.active ? '#818F9F' : props.theme.primaryBg};
  
  ${props => props.dark && css`
    background-color: ${props => props.theme.mainBg};
    padding: 6px 12px;
    font-size: 0.75rem;
  `};
  color: ${props => props.active ? '#000' : props.theme.color};
  margin: 5px;
  cursor: pointer;
`


export const MessageRow = styled(Flex)`
  justify-content: space-between;
  padding: 2px 0;
  margin: 2px 0;
  background-color: ${props => props.theme.secondaryBg};
  border-radius: 2px;
`


export const MessageContentRow = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}20;
  box-shadow: 0 0 2px rgb(0, 0, 0, 0.05);
  padding: 10px 16px;
  margin: 10px 0;
  background-color: ${props => props.theme.primaryBg};
`

export const MessageItem = styled(CFlex)`
  width: 100%;
  align-items: flex-start;
  background-color: ${props => props.theme.mainBg};
  padding: 10px;
`
