import {Absolute, ChatMiniWrapper, DText, Flex} from "../../../styles/components/common/common-styles";
import ChatPhoto from "../../common/utils/ChatPhoto";
import {Link} from "react-router-dom";



const DetailMiniView = (props) => {

    const {
        count,
        chat,
        link,
        idx,
        _id,
        noLink
    } = props

    const Component = () => {
        return (
            <ChatMiniWrapper {...props}>
                <Flex fw fh justify={'flex-start'}>
                    <ChatPhoto
                        chat={chat}
                        idx={idx}
                    />
                    <DText main style={{ margin: '0 5px' }}>
                        {chat?.title}
                    </DText>
                </Flex>
                {count > 1 &&
                    <Absolute top={'3px'} left={'3px'}>
                        <DText main fontSize={'s'}>
                            {count}
                        </DText>
                    </Absolute>
                }

            </ChatMiniWrapper>
        )
    }

    let _link = `/${link}/${_id}`
    if (link === 'channels') {
        _link += '?type=channel'
    }
    if (link === 'groups') {
        _link += '?type=group'
    }

    return (
        <>
            {noLink ?
                <Component />
                :
                <Link to={_link}>
                    <Component />
                </Link>
            }
        </>
    )
}


export default DetailMiniView
