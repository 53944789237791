import {useState} from "react";
import styled from "styled-components";
import {CFlex, CMargin, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import ModalLayout from "../../layouts/main/ModalLayout";
import Input from "../input/Input";
import ActionButton from "../buttons/ActionButton";


const EitaaSelect = ({ value, onChange }) => {

    const [eitaa, setEitaa] = useState(value)
    const [open, setOpen] = useState(false)
    const closeModal = () => setOpen(false)
    const openModal = () => setOpen(true)

    const onSubmit = () => {
        onChange(eitaa)
        closeModal()
    }

    return (
        <>
            <PlaceHolder onClick={openModal}>
                <Flex fw fh>
                    <DText main>
                        {value ?
                            value.substring(0, 15)
                            :
                            <Text tid={'filter-by-eitaa'} />
                        }
                    </DText>
                </Flex>
            </PlaceHolder>
            <ModalLayout
                width={'420px'}
                open={open}
                onClose={closeModal}
                portal={false}
            >
                <CFlex fw>
                    <DText primary>
                        <Text tid={'filter-by-eitaa'} />
                    </DText>
                    <CMargin margin={'8px'} />
                    <Input
                        width={'100%'}
                        value={eitaa}
                        onInputChange={(v) => setEitaa(v)}
                        label={'eitaa-channel'}
                    />
                    <ActionButton height={'42px'} onClick={onSubmit} active>
                        <Text tid={'submit'} />
                    </ActionButton>
                </CFlex>
            </ModalLayout>
        </>
    )
}

const PlaceHolder = styled.div`
  border: 1px solid ${props => props.theme.primary}60;
  border-radius: 4px;
  padding: 0 6px;
  height: 32px;
  cursor: pointer;
`

export default EitaaSelect
