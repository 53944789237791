import { createContext, useContext, useEffect, useState } from "react";
import { useSocket } from "./socket";



const SocketContentContext = createContext()

export const useSocketContent = () => {
    return useContext(SocketContentContext)
}

export const SocketContentProvider = ({ children }) => {

    const socket = useSocket()

    const [globalStats, setGlobalStats] = useState()
    const [forwardStats, setForwardStats] = useState()
    const [forwardMessages, setForwardMessages] = useState()
    const [c2cMessages, setC2cMessages] = useState()
    const [c2cStats, setC2cStats] = useState()
    const [countUpdates, setCountUpdates] = useState()

    /* globalStats */
    useEffect(() => {
        if (socket) {
            socket.emit("global-stats")
            socket.on("global-stats", (data) => {
                setGlobalStats(data)
            })
        }

        return () => {
            if (socket) {
                socket.off("groups")
            }
        }
    }, [socket])

    /**
     * forward stats
     */
    useEffect(() => {
        if (socket) {
            socket.emit("forward-stats")
            socket.on("forward-stats", (data) => {
                setForwardStats(data)
            })
        }

        return () => {
            if (socket) {
                socket.off("forward-stats")
            }
        }
    }, [socket])

    /**
     * forward messages
     */
    useEffect(() => {
        if (socket) {
            socket.emit("forward-messages")
            socket.on("forward-messages", (data) => {
                setForwardMessages(data)
            })
        }

        return () => {
            if (socket) {
                socket.off("forward-messages")
            }
        }
    }, [socket])

    /**
     * c2c messages
     */
    useEffect(() => {
        if (socket) {
            socket.emit("c2c-messages")
            socket.on("c2c-messages", (data) => {
                setC2cMessages(data)
            })
        }

        return () => {
            if (socket) {
                socket.off("c2c-messages")
            }
        }
    }, [socket])

    /**
     * c2c stats
     */
    useEffect(() => {
        if (socket) {
            socket.emit("c2c-stats")
            socket.on("c2c-stats", (data) => {
                setC2cStats(data)
            })
        }

        return () => {
            if (socket) {
                socket.off("c2c-stats")
            }
        }
    }, [socket])

    useEffect(() => {
        if (socket) {
            socket.emit("count-update")
            socket.on("count-update", (data) => {
                console.log({ data }, 'in socket')
                setCountUpdates(data)
            })
        }
        return () => {
            if (socket) {
                socket.off("count-update")
            }
        }
    }, [socket])

    return (
        <SocketContentContext.Provider value={{ globalStats, forwardStats, forwardMessages, c2cStats, c2cMessages, countUpdates }}>
            {children}
        </SocketContentContext.Provider>
    )
}
