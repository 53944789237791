import {DetailLayoutWrapper} from "../../../styles/layouts/main-styles";
import {CFlex, CMargin, DText, Flex, IconWrapper, UpdateIcon} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import SubmitSettingModal from "../../modals/SubmitSettingModal";


const DetailLayout = ({
    children,
    mode,
    enterEditMode,
    exitEditMode,
    loading,
    onSubmitChanges
}) => {


    return (
        <DetailLayoutWrapper>
            <CFlex fw>
                <Flex fw justify={'flex-end'}>
                    <DText main>
                        <Text tid={'mode'} /> :
                    </DText>
                    <DText main style={{ margin: '0 5px' }}>
                        <Text tid={mode} />
                    </DText>
                    {mode === 'show' &&
                        <IconWrapper
                            onClick={enterEditMode}
                        >
                            <UpdateIcon size={20} />
                        </IconWrapper>
                    }
                </Flex>
                <CMargin margin={'4px'} />
                {children}
                <SubmitSettingModal
                    onSubmit={onSubmitChanges}
                    onCancel={exitEditMode}
                    loading={loading}
                    editMode={mode === 'edit'}
                />
            </CFlex>

        </DetailLayoutWrapper>
    )
}


export default DetailLayout
