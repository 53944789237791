import {DText, Flex} from "../../../../styles/components/common/common-styles";
import {WizardLine, WizardOpenCloseIcon, WizardStatus} from "../../../../styles/components/c2c";
import Text from "../../../../core/utils/Text";
import {useCallback, useRef, useState} from "react";


const Wizard = (props) => {

    const {
        active,
        title,
        index,
        completed,
        ...rest
    } = props

    const [secondWidth, setSecondWidth] = useState(0)

    const IS_ACTIVE = active === index

    const handleTitleRef = useCallback((node) => {
        if (!node) return
        setSecondWidth(500 - node.offsetWidth - 140)
    }, [])


    return (
        <Flex align={'flex-end'} style={{ margin: '10px 0', cursor: 'pointer' }} {...rest}>
            <DText primary={!IS_ACTIVE} color={IS_ACTIVE && '#ffc800'}>
                {index+1} _
            </DText>
            <WizardLine active={IS_ACTIVE} />
            <Flex justify={'flex-start'} ref={handleTitleRef}>
                <DText primary={!IS_ACTIVE} color={IS_ACTIVE && '#ffc800'} cFontSize={'1rem'}>
                    <Text tid={title} />
                </DText>
            </Flex>
            <WizardLine width={secondWidth} active={IS_ACTIVE} />
            <WizardOpenCloseIcon size={24} active={IS_ACTIVE}/>
            <WizardStatus completed={completed} />
        </Flex>
    )
}

export default Wizard
