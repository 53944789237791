import {SKeletonText} from "../../core/packages/skeleton/elements";
import Shimmer from "../../core/packages/skeleton/Shimmer";
import {Column, Row} from "../../styles/layouts/table-styles";


const ListSkeleton = ({ params }) => {

    const { cs, headers } = params

    return (
        <Row cs={cs} noHover index={1}>
            <Column />
            {headers?.map(header => {

                let width = Math.random() * 100
                if (width < 40) {
                    width = 40
                }
                if (width > 80) {
                    width = 80
                }

                return (
                    <Column key={header}>
                        <SKeletonText width={`${width}%`} >
                            <Shimmer />
                        </SKeletonText>
                    </Column>
                )

            })}
            <Column />
        </Row>
    )
}

export default ListSkeleton
