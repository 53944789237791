import {CustomInputBorder, Flex} from "../../../styles/components/common/common-styles";
import Select from "../select/Select";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import "react-multi-date-picker/styles/colors/green.css"
import {useMainContext} from "../../../core/contexts/main";
import {p2e} from "../../../core/utils/common";


const modes = ['monthly', 'yearly']
const calendars = ['shamsi', 'georgian']

const DateFilter = (props) => {

    const {
        mode, date, calendarType, onParamsChange
    } = props
    const { main: { theme } } = useMainContext()

    return (
        <Flex fw wrap justify={'space-around'}>
            <Select
                options={modes}
                value={mode}
                onValueChange={(idx) => onParamsChange(modes[idx], 'mode')}
                size={'small'}
                height={'32px'}
                width={'92px'}
            />
            <Select
                options={calendars}
                value={calendarType}
                onValueChange={(idx) => onParamsChange(calendars[idx], 'calendarType')}
                size={'small'}
                height={'32px'}
                width={'92px'}
            />
            <Flex>
                <DatePicker
                    single
                    onlyMonthPicker={mode === 'monthly'}
                    onlyYearPicker={mode === 'yearly'}
                    value={date}
                    onChange={(v) => onParamsChange(v, 'date')}
                    calendar={calendarType === 'shamsi' && persian}
                    locale={calendarType === 'shamsi' && persian_fa}
                    className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal`}
                    render={
                        <CustomInput />
                    }
                />
            </Flex>

        </Flex>
    )
}

const CustomInput = ({value, openCalendar, handleValueChange, label}) => {
    return (
        <div onClick={openCalendar}>
            <CustomInputBorder>
                {p2e(value.toString())}
            </CustomInputBorder>
        </div>
    )
}


export default DateFilter
