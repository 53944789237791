import {DText, Flex} from "../../../styles/components/common/common-styles";
import {useMemo, useState} from "react";
import styled from "styled-components";
import ModalLayout from "../../layouts/main/ModalLayout";
import Modification from "./create/Modification";


const ModificationDetails = ({c2c}) => {

    const [modifyModal, setModifyModal] = useState({ open: false, item: null })
    const onOpenModifyModel = (idx, d) => setModifyModal({open: true, item: idx, type: d})
    const onCloseModifyModel = () => setModifyModal({ open: false, item: null })

    const possibleModifications = useMemo(() => {
        let temp = []
        if (!!c2c?.destination) temp.push('telegram')
        if (c2c?.hasEitaa) temp.push('eitaa')
        return temp
    }, [c2c])

    return (
        <>
            {c2c?.sources?.map((item, idx) => {

                return (
                    <Wrapper>
                        <Flex justify={'flex-start'}>
                            <DText primary>
                                {idx + 1}-
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {item.title}
                            </DText>
                        </Flex>
                        <Flex>
                            {possibleModifications.map(d => (
                                <img
                                    src={require(`../../../assets/images/${d}.png`)}
                                    width={24}
                                    height={24}
                                    alt={d}
                                    style={{ margin: '0 5px', cursor: 'pointer' }}
                                    onClick={() => onOpenModifyModel(idx, d)}
                                />
                            ))}
                        </Flex>
                    </Wrapper>
                )
            })}

            <ModalLayout
                open={modifyModal.open}
                onClose={onCloseModifyModel}
                width={'100%'}
            >
                <Modification
                    modification={modifyModal.type ? c2c?.modifications[modifyModal.type][modifyModal.item] : []}
                    onSave={() => {}}
                    onClose={onCloseModifyModel}
                    disable
                />
            </ModalLayout>
        </>
    )
}

const Wrapper = styled.div`
  width: 320px;
  height: 52px;
  border-radius: 8px;
  background-color: ${props => props.theme.secondaryBg};
  display: flex;
  margin: 8px 0;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.1);
  justify-content: space-between;
  padding: 0 12px;
`

export default ModificationDetails
