import styled, {css} from "styled-components";
import Text from "../../../core/utils/Text";
import {useEffect} from "react";
import {CFlex} from "../../../styles/components/common/common-styles";


const HorizontalTabbar = ({
    tabs,
    activeTab,
    onTabChange,
    controlRef,
}) => {

    useEffect(() => {
        const activeTabRef = tabs[activeTab].ref
        if (activeTabRef.current) {

            const { offsetHeight, offsetTop } = activeTabRef.current
            const { style } = controlRef.current

            style.setProperty("--highlight-height", `${offsetHeight}px`);
            style.setProperty("--highlight-y-pos", `${offsetTop}px`);
        }


    }, [activeTab, tabs, controlRef, onTabChange])


    return (
        <Wrapper ref={controlRef}>
            <Controls fw fh align={'flex-start'} justify={'flex-start'}>
                {tabs.map((tab, idx) => {
                    if (!tab.perm) {
                        return <></>
                    }
                    return (
                        <TabWrapper key={tab.name} ref={tab.ref}>
                            <Input
                                type="radio"
                                value={tab.name}
                                id={tab.name}
                                name={'segment'}
                                onChange={() => onTabChange(idx)}
                                checked={idx === activeTab}
                            />
                            <Text tid={tab.name} />
                        </TabWrapper>
                    )
                })}
            </Controls>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  min-width: 180px;
  min-height: 80vh;
  background-color: ${props => props.theme.primaryBg};
  padding: 0 10px;
  --highlight-width: auto;
  --highlight-y-pos: 0;
  
  ${props => !props.theme.english && css`
    border-left: 1px solid ${props => props.theme.color}20;
  `};
  
  @media screen and (max-width: 768px) {
    min-width: 120px;
  };
`

const TabWrapper = styled.div`
  width: 100%;
  margin: 8px 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  color: ${props => props.theme.color};
  font-size: 0.8rem;
  transition: all 0.3s;
`


const Input = styled.input`
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
`


const Controls = styled(CFlex)`
  
    &::before{
      content: "";
      background-color: ${props => props.theme.mainBg};
      border: 1px solid ${props => props.theme.color}20;
      box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
      border-radius: 4px;
      width: 100%;
      height: var(--highlight-height);
      transform: translateY(var(--highlight-y-pos));
      position: absolute;

      left: 0;
      z-index: 0;
      transition: transform 0.3s ease, width 0.3s ease;
    };
`

export default HorizontalTabbar
