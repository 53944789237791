import { useState } from "react"
import { useUpdateSimName } from "../../core/services/react-query/sim"
import { Background, DText } from "../../styles/components/common/common-styles"
import Input from "../common/input/Input"
import Text from "../../core/utils/Text"
import ActionButton from "../common/buttons/ActionButton"
import ButtonLoader from "../common/loading/ButtonLoader"



const UpdateSimNameModal = ({ sim, onClose }) => {

    const { mutate, isLoading } = useUpdateSimName(onClose)
    const [name, setName] = useState('')

    const onSubmit = () => {
        mutate({
            simId: sim._id,
            newName: name
        })
    }

    return (
        <div className={'flex flex-col gap-2'}>
            <DText primary>
                <Text tid={'provide-new-name'} />
            </DText>
            <Background bg={'mainBg'}>
                <Input
                    label={'name'}
                    value={name}
                    onInputChange={setName}

                />
            </Background>
            <ActionButton active={!!name} onClick={onSubmit}>
                <ButtonLoader
                    tid={'submit'}
                    loading={isLoading}
                />
            </ActionButton>
        </div>
    )
}

export default UpdateSimNameModal