import {useLoginWithDemo} from "../../services/react-query/landing";
import {useMainContext} from "../../contexts/main";
import {useEffect} from "react";


export const useLoginDemo = () => {


    const { mutate: loginViaDemo, data } = useLoginWithDemo()

    const { profile: { lotp, setToken, setLOTP } } = useMainContext()
    useEffect(() => {
        if (!!data) {
            setToken(data?.data?.data?.token)
            setLOTP('')
        }
    }, [data])

    const demoLogin = () => {
        loginViaDemo( { lotp })
    }

    return {
        demoLogin
    }

}
