import styled, {css} from 'styled-components'
import {BsCheck} from 'react-icons/bs'
import {Flex} from "../../../styles/components/common/common-styles";


const CheckBox = (props) => {


    return (
        <Wrapper {...props}>
            <Flex fw fh>
                {props.active &&
                    <CheckIcon dark={props.dark} size={24} />
                }
            </Flex>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid ${props => props.active ? props.theme.mainGreen : props.theme.color}80;
    ${props => props.dark && css`
      border: 1px solid ${props => '#000200'}80;
    `};
    border-radius: 2px;
    cursor: pointer;
`

const CheckIcon = styled(BsCheck)`
    color: ${props => props.dark ? '#00FF37' : props.theme.mainGreen};
`


export default CheckBox
