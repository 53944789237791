import { useState } from "react";
import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { CacheKeys, extractMemberOperationFilterOptions, memberFilterOptions } from "../../core/constants/filter";
import { useGetAllMembers, useGetExtractOperations } from "../../core/services/react-query/member";
import MembersComp from "../../components/main/member/members/MembersComp";
import Text from "../../core/utils/Text";
import { DText } from "../../styles/components/common/common-styles";
import ExtractMembersComp from "../../components/main/member/extract-operations/ExtractMembersComp";
import ModalLayout from "../../components/layouts/main/ModalLayout";
import ExtractMembersModal from "../../components/main/member/extract-operations/ExtractMembersModal";


const Members = () => {

    const tabs = ['members-list', 'extract-list']
    const [activeTab, setActiveTab] = useState('members-list')

    const [modal, setModal] = useState(null)
    const closeModal = () => setModal(null)

    const [members, setMembers] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setMembers(res)

    const [extractOperations, setExtractOperations] = useState({ data: null, loading: true })
    const onExtractQuerySuccess = res => setExtractOperations(res)

    return (
        <>
            <CardLayout>
                <div className={'mb-4'}>
                    <div className={'flex items-start gap-6'}>
                        {tabs.map(tab => {
                            const active = activeTab === tab
                            return (
                                <div className={'flex flex-col gap-1 cursor-pointer min-w-[110px] items-center'} onClick={() => setActiveTab(tab)} key={tab}>
                                    <DText main>
                                        <Text tid={tab} />
                                    </DText>
                                    {active &&
                                        <div className={'bg-active h-[3px] w-[92px]'} />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className={'w-[40%] h-[1px] dark:bg-gray-300 opacity-30 bg-gray-700'} />
                </div>
                {activeTab === 'members-list' ?
                    <FilterLayout
                        query={useGetAllMembers}
                        cache={CacheKeys.MEMBERS}
                        onQuerySuccess={onQuerySuccess}
                        options={memberFilterOptions}
                    >
                        <MembersComp
                            data={members}
                        />
                    </FilterLayout>
                    : null}

                {activeTab === 'extract-list' ?
                    <FilterLayout
                        query={useGetExtractOperations}
                        cache={CacheKeys.EXTRACT_MEMBER_OPERATIONS}
                        onQuerySuccess={onExtractQuerySuccess}
                        options={extractMemberOperationFilterOptions}
                    >
                        <ExtractMembersComp
                            data={extractOperations}
                        />
                    </FilterLayout>
                    : null}

            </CardLayout>

            <div className={'absolute left-3 top-3 flex items-center justify-center gap-4'}>
                <div className={'bg-active text-white rounded-md py-2 px-5 text-sm cursor-pointer'} onClick={() => setModal('extract-members')}>
                    <Text tid={'extract-members'} />
                </div>
            </div>

            <ModalLayout
                open={modal === 'extract-members'}
                isStatic={true}
                width={'520px'}
            >
                <ExtractMembersModal onClose={closeModal} />
            </ModalLayout>
        </>
    )
}

export default Members