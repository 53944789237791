import TableLayout from "../../../layouts/main/TableLayout"
import MembersList from "./MembersList"



const MembersComp = ({ data }) => {

    const { data: members } = data

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={CS}
        >
            <MembersList
                members={members}
                cs={CS}
            />
        </TableLayout>
    )
}

const CS = '5% 20% 15% 10% 10% 15% 15% 10%'
const headers = [
    'username', 'mobile', 'firstName', 'lastName', 'peer_id', 'access_hash', 'date'
]

export default MembersComp