import { useEffect, useState } from "react";
import { useQueryContext } from "../../../core/contexts/query";
import { useCreateOperation } from "../../../core/services/react-query/operation";
import { COBody, COWrapper, OperationSVG } from "../../../styles/components/operation/operation-styles";
import { fadeVariants } from "../../../core/utils/theme";
import { CFlex, CMargin, DText, Flex, Padding, AnimationWrapper } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Input from "../../common/input/Input";
import { CREATE_OPERATION_TYPE } from "../../../core/constants/constants";
import Select from "../../common/select/Select";
import { useSimGroupsQuery } from "../../../core/services/react-query/group";
import MultiSelect from "../../common/select/MultiSelect";
import { AnimatePresence } from "framer-motion";
import useOperationalSims from "../../../core/hooks/main/sim/useOperationalSims";
import { useSimChannelsQuery } from "../../../core/services/react-query/channel";
import ActionButton from "../../common/buttons/ActionButton";
import ButtonLoader from "../../common/loading/ButtonLoader";
import OperationDelay from "./OperationDelay";
import { deepCopy } from "../../../core/utils/common";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../core/constants/routes";
import { useAclContext } from "../../../core/contexts/acl";
import { useMainContext } from "../../../core/contexts/main";



const CreateOperation = () => {

    const { permissions } = useAclContext()
    const { setNeedPackage } = useMainContext()
    const navigate = useNavigate()
    const { setToast } = useQueryContext()

    const { mutate: createOperation, isLoading: creatingOperation, isSuccess } = useCreateOperation()

    const [simOptions, setSimOptions] = useState([])
    const { simsList } = useOperationalSims()
    useEffect(() => {
        if (simsList) setSimOptions(simsList.map(item => item.username))
    }, [simsList])

    const initialState = {
        name: '',
        sim_id: '',
        channels: [],
        groups: [],
        delay: {
            mode: 'period',
            begin: 2,
            end: 5,
            delay: 60,
            min: '',
            max: '',
        }
    }
    const [inputData, setInputData] = useState(initialState)
    const [valid, setValid] = useState(false)

    const [selectedSim, setSelectedSim] = useState(null)

    /**
     * sim groups
     */
    const { data: groups, refetch: getSimGroups } = useSimGroupsQuery(selectedSim?._id)

    /**
     * sim channels
     */
    const { data: channels, refetch: getSimChannels } = useSimChannelsQuery(selectedSim?._id)


    /**
     * validate all inputs for api call
     */
    useEffect(() => {
        const valid =
            inputData.name &&
            inputData.sim_id &&
            inputData.groups?.length &&
            inputData.channels?.length

        setValid(valid)

    }, [inputData])


    /**
     * on sim select
     */
    useEffect(() => {
        if (selectedSim) {
            getSimGroups()
            getSimChannels()
        }
    }, [selectedSim])



    const onInputValueChange = (value, type) => {
        switch (type) {
            case CREATE_OPERATION_TYPE.NAME:
                setInputData(state => ({ ...state, name: value }))
                break
            case CREATE_OPERATION_TYPE.SIM_ID:
                const simName = simOptions[value]
                setInputData(state => ({ ...state, sim_id: simName }))
                setSelectedSim(simsList?.find(sim => sim.username === simName))
                break
            default:
                break
        }
    }

    const onGroupsChange = (selectedOptions) => {
        setInputData(state => ({ ...state, groups: selectedOptions }))
    }

    const onChannelsChange = (selectedOptions) => {
        setInputData(state => ({ ...state, channels: selectedOptions }))
    }


    const onSubmitClicked = () => {
        if (valid) {
            if (permissions?.operation) {
                const payload = { ...inputData }
                payload.sim_id = selectedSim._id
                payload.groups = inputData.groups.map(x => x._id)
                payload.channels = inputData.channels.map(x => x._id)
                createOperation(payload)
            } else {
                setNeedPackage(true)
            }
        }
        else setToast({
            isError: true,
            show: true, message: 'fill-inputs-error'
        })
    }

    const onSubmitDelay = (delay) => {
        const newData = deepCopy(inputData)
        newData.delay = delay
        setInputData(newData)
    }

    useEffect(() => {
        if (isSuccess) navigate(routes.operations)
    }, [isSuccess])

    return (
        <>
            <COWrapper>
                <COBody variants={fadeVariants} animate='in' exit='out' initial='out'>
                    <CFlex fw>
                        <Flex fw justify='flex-start'>
                            <DText fontSize='sb' primary>
                                <Text tid='add-operation' />
                            </DText>
                        </Flex>

                        <CMargin margin='20px' />
                        <Input
                            label={CREATE_OPERATION_TYPE.NAME}
                            value={inputData.name}
                            onInputChange={(e) => onInputValueChange(e, CREATE_OPERATION_TYPE.NAME)}
                        />

                        <Padding padding='4px'>
                            <Select
                                width={'100%'}
                                value={inputData.sim_id}
                                placeHolder={'choose-sim'}
                                options={simOptions}
                                onValueChange={(idx) => onInputValueChange(idx, CREATE_OPERATION_TYPE.SIM_ID)}
                            />
                        </Padding>

                        {/*
                            groups and channels
                        */}
                        <CMargin margin={'10px'} />
                        <AnimatePresence exitBeforeEnter>
                            {selectedSim &&
                                <AnimationWrapper
                                    initial={'out'}
                                    animate={'in'}
                                    exit={'out'}
                                    variants={fadeVariants}
                                >
                                    <Padding padding={'4px'}>
                                        <MultiSelect
                                            values={inputData.channels}
                                            onValuesChange={onChannelsChange}
                                            placeHolder='select-channels'
                                            options={channels || []}
                                            type='chat'
                                            param='address'
                                        />
                                    </Padding>

                                    <CMargin margin={'20px'} />
                                    <Padding padding='4px'>
                                        <MultiSelect
                                            values={inputData.groups}
                                            onValuesChange={onGroupsChange}
                                            placeHolder='select-groups'
                                            options={groups || []}
                                            type='chat'
                                            param='address'
                                        />
                                    </Padding>

                                    <CMargin margin={'20px'} />
                                    <OperationDelay
                                        data={inputData}
                                        onSubmitChanges={onSubmitDelay}
                                    />

                                    <CMargin margin={'20px'} />
                                    <ActionButton
                                        active={valid}
                                        onClick={onSubmitClicked}
                                    >
                                        <ButtonLoader
                                            tid='submit'
                                            loading={creatingOperation}
                                        />
                                    </ActionButton>
                                </AnimationWrapper>
                            }
                        </AnimatePresence>

                    </CFlex>
                </COBody>
                <OperationSVG />
            </COWrapper>
        </>
    )
}

export default CreateOperation
