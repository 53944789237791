import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {formatNumber} from "../../../core/utils/common";


const GroupStats = ({ stats }) => {

    return (
        <Flex style={{ gap: '20px' }}>
            <Flex style={{ gap: '4px' }}>
                <DText main>
                    <Text tid={'total-subscribers'} /> :
                </DText>
                <DText color={'#818F9F'} fontSize={'b'}>
                    <span>{formatNumber(stats?.members, true)}</span>
                </DText>
            </Flex>
            <DText primary><span>---</span></DText>
            <Flex style={{ gap: '4px' }}>
                <DText main>
                    <Text tid={'total-onlines'} /> :
                </DText>
                <DText color={'#15A865'} fontSize={'b'}>
                    <span>{formatNumber(stats?.onlines, true)}</span>
                </DText>
            </Flex>
        </Flex>
    )
}

export default GroupStats
