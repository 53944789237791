import {useMutation, useQuery} from "react-query";
import {makeQueryFilters} from "../../../utils/query-maker";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {postApi} from "../../fetch-api/post";
import {useQueryContext} from "../../../contexts/query";
import {deleteApi} from "../../fetch-api/delete";
import {putApi} from "../../fetch-api/put";


const NORMALIZER_KEYS = {
    FETCH_NORMALIZERS: 'fetch-normalizers',
    GET_A_NORMALIZER: 'get-a-normalizer',
    CREATE_NORMALIZER: 'create-normalizer',
    RUN_NORMALIZER: 'run-normalizer',
    STOP_NORMALIZER: 'stop-normalizer',
    UPDATE_NORMALIZER: 'update-normalizer',
    DELETE_NORMALIZER: 'delete-normalizer'
}


const useGetNormalizers = (filters, pagination, extra = {}) => {

    const params = makeQueryFilters({...extra, ...filters}, pagination)

    return useQuery(
        NORMALIZER_KEYS.FETCH_NORMALIZERS, () => filterFetch(params, '/normalizer')
    )
}

const useGetANormalizer = (id) => {

    return useQuery(
        NORMALIZER_KEYS.GET_A_NORMALIZER, () => normalFetch(`/normalizer/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}


const useCreateNormalizer = () => {

    const { setToast } = useQueryContext()

    return useMutation(
        NORMALIZER_KEYS.CREATE_NORMALIZER, (data) => postApi(data, '/normalizer'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success',
                    show: true
                })
            }
        }
    )
}

const useRunNormalizer = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        NORMALIZER_KEYS.RUN_NORMALIZER, (data) => postApi(data, '/normalizer/run'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(NORMALIZER_KEYS.FETCH_NORMALIZERS)
                setToast({
                    show: true, message: 'op-success'
                })
            }
        }
    )
}


const useStopNormalizer = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        NORMALIZER_KEYS.STOP_NORMALIZER, (data) => postApi(data, '/normalizer/stop'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(NORMALIZER_KEYS.FETCH_NORMALIZERS)
                setToast({
                    show: true, message: 'op-success'
                })
            }
        }
    )
}

const useUpdateNormalizer = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        NORMALIZER_KEYS.UPDATE_NORMALIZER, (data) => putApi(data, '/normalizer/update'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(NORMALIZER_KEYS.FETCH_NORMALIZERS)
                setToast({
                    show: true, message: 'op-success'
                })
            }
        }
    )
}

const useDeleteNormalizer = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        NORMALIZER_KEYS.DELETE_NORMALIZER, (id) => deleteApi(`/normalizer/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(NORMALIZER_KEYS.FETCH_NORMALIZERS)
                setToast({
                    message: 'op-success',
                    show: true
                })
            }
        }
    )
}


export {
    useGetNormalizers,
    useGetANormalizer,
    useCreateNormalizer,
    useRunNormalizer,
    useStopNormalizer,
    useUpdateNormalizer,
    useDeleteNormalizer
}
