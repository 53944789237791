import { DetailBoxTitle, DetailBoxWrapper, DText, Flex } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";


const DetailBox = (props) => {

    const {
        children,
        title,
        extra = null
    } = props

    return (
        <DetailBoxWrapper {...props}>
            {title &&
                <DetailBoxTitle>
                    <Flex fw justify={'space-between'}>
                        <DText main>
                            <Text tid={title} />
                        </DText>
                        {extra && extra}
                    </Flex>
                </DetailBoxTitle>
            }
            {children}
        </DetailBoxWrapper>
    )
}


export default DetailBox
